import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  cryptoSecretKey,
  fileUrl,
  URL as URLs,
} from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { validateImage } from "../../../../utils/Helper";

class AddPaymentGatewayConfig extends Component {
  state = {
    paymentGateway: "",
    gatewayImage: "",
    gatewayImageFile: "",
    customFields: [
      {
        labelName: "",
        displayName: "",
        placeHolderInfo: "",
        isActive: true,
        isMandatory: true,
      },
    ],
    spinner: false,
    imagePreview: "",
    status: 1,
  };

  addRow = () => {
    this.setState({
      customFields: [
        ...this.state.customFields,
        {
          labelName: "",
          displayName: "",
          placeHolderInfo: "",
          isActive: true,
          isMandatory: true,
        },
      ],
    });
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setUpdateConfig();
    }
  }

  deleteRow = (idx) => {
    let customFields = [...this.state.customFields];
    customFields.splice(idx, 1);
    this.setState({ customFields });
  };

  handleCancel = () => {
    this.props.handleCreateModal();
    this.setState({
      paymentGateway: "",
      customFields: [
        {
          labelName: "",
          displayName: "",
          placeHolderInfo: "",
          isActive: true,
          isMandatory: true,
        },
      ],
    });
    this.removeImage();
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCustomFieldChange = (e, idx) => {
    let { name } = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let customFields = [...this.state.customFields];
    customFields[idx][name] = value;
    this.setState({ customFields });
  };

  handleSave = () => {
    this.setState({ spinner: true }, function () {
      let data = {
        paymentGateway: this.state.paymentGateway,
        status: this.state.status,
        customFields: this.state.customFields,
      };
      let formData = new FormData();
      let chkErr = 0;

      this.state.customFields.forEach((cf) => {
        if (
          cf.labelName === "" ||
          cf.displayName === "" ||
          cf.placeHolderInfo === ""
        ) {
          chkErr++;
        }
      });
      formData.append("param", JSON.stringify(data));
      formData.append("file", this.state.gatewayImageFile);

      if (
        this.state.paymentGateway === "" ||
        this.state.gatewayImageFile === "" ||
        chkErr > 0
      ) {
        swal("Warning", "Fill all the fields.", "warning");
        this.setState({ spinner: false });

        return;
      } else {
        axiosPost(
          URLs.postGlobalPaymentConfig,
          formData,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.props.getPaymentGateways();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      }
    });
  };

  handleUpdate = () => {
    this.setState({ spinner: true }, function () {
      let data = {
        id: this.state.selectedDataId,
        paymentGateway: this.state.paymentGateway,
        userId: this.props.selectedData.user_id,
        status: this.state.status,
        customFields: this.state.customFields,
      };
      let formData = new FormData();
      formData.append("param", JSON.stringify(data));
      if (this.state.gatewayImageFile !== "") {
        formData.append("file", this.state.gatewayImageFile);
      }
      axiosPost(
        URLs.updateGlobalPaymentGateway,
        formData,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.props.getPaymentGateways();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  setUpdateConfig = () => {
    let paymentData = this.props.selectedData;

    if (paymentData) {
      this.setState({
        selectedDataId: paymentData.id,
        paymentGateway: paymentData.payment_gateway,
        status: paymentData.is_active,
        imagePreview:
          paymentData.media.length > 0
            ? fileUrl +
            "/" +
            paymentData.media[0].id +
            "/" +
            paymentData.media[0].file_name
            : null,
      });

      let newCustomFields = [...paymentData.customFields];

      if (newCustomFields.length > 0) {
        const newData = newCustomFields.map((cf) => {
          return {
            id: cf.id,
            labelName: cf.field_name,
            displayName: cf.display_name,
            placeHolderInfo: cf.place_holder_info,
            isActive: cf.is_active,
            isMandatory: cf.is_mandatory,
            paymentKeyGlobalConfigurationId:
              cf.payment_key_global_configuration_id,
          };
        });
        this.setState({
          customFields: newData,
        });
      }
    }
  };

  removeImage = () => {
    this.setState({
      gatewayImage: "",
      imagePreview: "",
      gatewayImageFile: "",
    });
  };

  handleImageChange = (e) => {
    if (validateImage(e.target.files[0])) {
      this.setState(
        {
          gatewayImage: e.target.value,
          gatewayImageFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.gatewayImageFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };
  render() {
    return (
      <>
        {this.state.spinner ? (
          <CustomFullSpinner text="Submitting your keys data....." />
        ) : (
          ""
        )}
        <div className="row mb-3">
          <div className="col-md-4 mt-1">
            <strong>Payment Gateway
              <span className="text-danger"> *</span>
            </strong>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="paymentGateway"
              className="form-control"
              value={this.state.paymentGateway}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4 mt-1">
            <strong>Status</strong>
          </div>
          <div className="col-md-8">
            <input
              type="radio"
              className="m-2"
              name="status"
              value="1"
              onChange={this.handleChange}
              checked={this.state.status == "1"}
            />
            Active
            <input
              type="radio"
              className="m-2"
              name="status"
              value="0"
              onChange={this.handleChange}
              checked={this.state.status == "0"}
            />
            Inactive
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4 mt-1">
            <strong>Gateway Image
              <span className="text-danger"> *</span>
            </strong>
          </div>
          <div className="col">
            {this.props.edit &&
              this.state.imagePreview &&
              this.state.gatewayImage === "" ? (
              <input
                className="form-control"
                type="text"
                value={this.props.selectedData.media[0].name}
                disabled
              />
            ) : (
              <input
                accept="image/*"
                className="form-control"
                onChange={this.handleImageChange}
                value={this.state.gatewayImage}
                type="file"
                name="gatewayImage"
              />
            )}
          </div>
          {this.state.imagePreview !== "" ? (
            <div className="col-md-auto">
              <button
                className="btn btn-danger ml-3"
                onClick={this.removeImage}
              >
                Remove
              </button>
            </div>
          ) : null}
        </div>
        {this.state.imagePreview !== "" ? (
          <div className="row mt-2 mb-2">
            <div className="col text-center">
              <div className="imageHolder" style={{ border: "1px solid #ccc" }}>
                <img
                  src={this.state.imagePreview}
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
          <div className="paymentHeader">
            <strong>Custom Fields [Keys]</strong>
          </div>
          <div className="row">
            {/* <div className="col-md-1"></div> */}
            <div className="col-md-3 text-center">
              <strong>Label Name
                <span className="text-danger"> *</span>
              </strong>
            </div>
            <div className="col-md-3 text-center">
              <strong>Display Name
                <span className="text-danger"> *</span>
              </strong>
            </div>
            <div className="col-md-1 text-center">
              <strong>Active</strong>
            </div>
            <div className="col-md-1 text-center">
              <strong>Mandatory</strong>
            </div>
            <div className="col-md-3 text-center">
              <strong>Placeholder Info
                <span className="text-danger"> *</span>
              </strong>
            </div>
            {/* <div className="col-md-5 text-center">
      <strong>Value</strong>
    </div> */}
          </div>
          {this.state.customFields.map((field, idx) => {
            return (
              <div className="row mt-2" key={idx}>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    name="labelName"
                    value={field.labelName}
                    onChange={(e) => this.handleCustomFieldChange(e, idx)}
                  />
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    name="displayName"
                    value={field.displayName}
                    onChange={(e) => this.handleCustomFieldChange(e, idx)}
                  />
                </div>

                <div className="col-md-1 text-center">
                  <input
                    type="checkbox"
                    name="isActive"
                    checked={field.isActive}
                    onChange={(e) => this.handleCustomFieldChange(e, idx)}
                  />
                </div>
                <div className="col-md-1 text-center">
                  <input
                    type="checkbox"
                    name="isMandatory"
                    checked={field.isMandatory}
                    onChange={(e) => this.handleCustomFieldChange(e, idx)}
                  />
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    name="placeHolderInfo"
                    value={field.placeHolderInfo}
                    onChange={(e) => this.handleCustomFieldChange(e, idx)}
                  />
                </div>
                {/* {this.props.edit ? null : ( */}
                <div className="col-md-1 p-2">
                  {idx === this.state.customFields.length - 1 ? (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      onClick={this.addRow}
                      style={{ marginRight: "5px", color: "#0b5ed7" }}
                    />
                  ) : null}
                  {idx == 0 ? null : (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      style={{ color: "#bb2d3b" }}
                      onClick={() => this.deleteRow(idx)}
                    />
                  )}
                </div>
                {/* )} */}
              </div>
            );
          })}
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default AddPaymentGatewayConfig;
