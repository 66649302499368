import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { Component } from "react";
import CKeditorComponent from "../../../../utils/CKeditorComponent";
// import CKeditorComponent from "../../../../utils/CKeditorComponent";

// import CKeditorComponent from "../../../../utils/CKeditorComponent";
import JoditEditor from "jodit-react";
import { axiosGet, axiosGetWeb, axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import swal from "sweetalert";
import { DISPLAYMESSAGE } from "../../../../utils/DisplayMessage";
import { Link } from "react-router-dom";

const config = {
  zIndex: 0,
  readonly: false,
  activeButtonsInReadOnly: ["source", "fullsize", "print", "about"],
  toolbarButtonSize: "middle",
  theme: "default",
  enableDragAndDropFileToEditor: true,
  saveModeInCookie: false,
  spellcheck: true,
  editorCssClass: false,
  triggerChangeEvent: true,
  height: 220,
  direction: "ltr",
  language: "en",
  debugLanguage: false,
  i18n: "en",
  tabIndex: -1,
  toolbar: true,
  enter: "P",
  useSplitMode: false,
  colorPickerDefaultTab: "background",
  // imageDefaultWidth: 100,
  removeButtons: [
    "source",
    "fullsize",
    "about",
    "video",
    "print",
    "table",
    "superscript",
    "subscript",
    "file",
    "cut",
    "selectall",
    "find",
  ],
  disablePlugins: ["paste", "stat"],
  events: {},
  textIcons: false,
  uploader: {
    insertImageAsBase64URI: true,
  },
  placeholder: "",
  showXPathInStatusbar: false,
};

export class AboutCompany extends Component {
  state = {
    contents: "",
    editMode: false,
  };

  handleContentChange = (data) => {
    this.setState({
      contents: data,
    });
  };

  handleSave = () => {
    let saveData = {
      pageContent: this.state.contents,
    };

    let formData = new FormData();

    formData.append("aboutUs", JSON.stringify(saveData));

    axiosPost(URL.updateAboutUs, formData, (response) => {
      if (response.status === 200) {
        swal("Success", DISPLAYMESSAGE.createSuccess, "success");
        this.getAboutUsData();
      }
    });
  };

  getAboutUsData = () => {
    axiosGet(URL.getAuthAboutUs, (response) => {
      if (response.status === 200) {
        if (response.data?.data?.item?.id) {
          this.setState({
            editMode: true,
            contents: response.data?.data?.item.page_content,
          });
        }
      }
    });
  };

  componentDidMount = () => {
    this.getAboutUsData();
  };

  render() {
    return (
      <div>
        <div className="dashboardHeader">
          <strong>ABOUT US CONTENTS</strong>
        </div>

        {this.state.editMode ? (
          <div className="container p-4">
            {/* <CKeditorComponent
            value={this.state.contents}
            onChange={this.handleContentChange}
          /> */}
            <JoditEditor
              // ref={editor}
              // fullsize={true}
              value={this.state.contents}
              config={config}
              tabIndex={1} // tabIndex of textarea
              // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => this.handleContentChange(newContent)}
            />

            <button className="btn btn-primary mt-3" onClick={this.handleSave}>
              Save
            </button>
          </div>
        ) : (
          <div className="container p-4">
            <p>
              First configure the banner section to add the about page contents.
            </p>
            <Link to="/admin/dashboard/about-banner">
              Configure About us Banner
            </Link>
          </div>
        )}

        <div className="editor"></div>
      </div>
    );
  }
}

export default AboutCompany;
