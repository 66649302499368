import React, { useState } from "react";
import logo from "../../../assets/images/logo.jpg";
import missionImg from "../../../assets/images/bus5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usericon from "../../../assets/images/addicon.png";

function SignupUI(props) {
  return (
    <div>
      <div className="signup-ui">
        <div className="row">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="fname"
              value={props.fname}
              onChange={props.handleFNameChange}
              placeholder="First name"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="mname"
              value={props.mname}
              onChange={props.handleMNameChange}
              placeholder="Middle name"
            />
          </div>{" "}
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="lname"
              value={props.lname}
              onChange={props.handleLNameChange}
              placeholder="Last name"
            />
          </div>
        </div>

        <div className="signup-extra">
          <input
            type="text"
            className="form-control"
            name="phone"
            value={props.phone}
            onChange={props.handlePhoneChange}
            placeholder="Phone number"
          />

          <input
            type="text"
            className="form-control"
            name="address"
            value={props.address}
            onChange={props.handleAddressChange}
            placeholder="Address"
          />
          <input
            type="email"
            className="form-control"
            name="email"
            value={props.email}
            onChange={props.handleEmailChange}
            placeholder="Email"
          />
        </div>

        <div className="m-4">
          <button className="btn btn-primary" onClick={props.handleRegister}>
            Register
          </button>
          <button
            className="btn btn-danger"
            onClick={props.handleCancel}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </button>
        </div>
        <div className="m-4">
          <a href="" onClick={props.goToHomePage}>
            Return to Home Page
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignupUI;
