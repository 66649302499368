import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { fileUrl } from "../../../utils/Constants";
import sampleImg from "../../../assets/images/logo.jpg";
import { validateImage } from "../../../utils/Helper";

class CreateAmenity extends Component {
  state = {
    amenities: [
      {
        id: "",
        title: "",
        description: "",
        status: "",
        image: "",
        imageFile: "",
        imageUpdated: "false",
      },
    ],
    oldImg: "",
    spinner: false,
  };

  addRow = () => {
    this.setState({
      amenities: [
        ...this.state.amenities,
        {
          title: "",
          description: "",
          image: "",
          imageFile: "",
          status: "",
        },
      ],
    });
  };

  createAmenities = () => {
    let ammenitiesChk = [...this.state.amenities];
    let newAmenities = ammenitiesChk.map((item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      status: item.status,
    }));

    let files = [];
    let index = [];

    ammenitiesChk.forEach((item, idx) => {
      if (item.image !== "") {
        index.push({
          idx,
        });
        let imagePush = item.image;
        files.push(imagePush);
      }
    });

    let formData = new FormData();

    files.forEach((image) => formData.append("files[]", image));
    formData.append("index", JSON.stringify(index));
    formData.append("param", JSON.stringify(newAmenities));

    let err = 0;

    ammenitiesChk.forEach((chk) => {
      if (
        chk.title === "" ||
        chk.description === "" ||
        chk.status === "" ||
        chk.imageFile === ""
      ) {
        err = err + 1;
      }
    });

    if (err > 0) {
      swal("Error", "Fill all the fields.", "error");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.createAmenitiesList,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.setState({ spinner: false });
              this.props.handleCreateModal();
              this.props.getAmenitiesList();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false });
          }
        );
      });
    }
  };


  handleImage = (e, idx) => {
    let { name } = e.target;
    let routes = [...this.state.amenities];

    if (validateImage(e.target.files[0])) {
      routes[idx][name] = e.target.files[0];
      routes[idx].imageFile = e.target.value;

      this.setState({ routes, imageUpdated: true });
    }
  };

  removeImage = (e, idx) => {
    let routes = [...this.state.amenities];

    routes[idx].image = "";
    routes[idx].imageFile = "";
    this.setState({
      routes,
    });
  };

  updateAmenities = () => {
    let routesChk = [...this.state.amenities];

    let files = [];

    routesChk.forEach((item, idx) => {
      if (item.image !== "") {
        let imagePush = item.image;
        files.push(imagePush);
      }
    });

    let formData = new FormData();

    files.forEach((image) => formData.append("files", image));
    let param = {
      id: this.props.amenity.filter((item) => item.id === this.props.updateReqData.id)[0].id,
      title: this.state.amenities[0].title,
      description: this.state.amenities[0].description,
      status: this.state.amenities[0].status,
    };

    // formData.append(
    //   "id", "1"
    // );

    formData.append("param", JSON.stringify(param));
    if (!this.state.imageUpdated) {
      formData.append("oldImg", this.state.oldImg);
    }

    let err = 0;

    routesChk.forEach((chk) => {
      if (
        chk.title === "" ||
        chk.description === "" ||
        chk.status === ""
      ) {
        err = err + 1;
      }
    });

    if (err > 0) {
      swal("Error", "Fill all the fields.", "error");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.updateAmenitiesList,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.setState({ spinner: false });
              this.props.handleCreateModal();
              this.props.getAmenitiesList();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false, imageUpdated: false });
          }
        );
      });
    }
  };

  deleteRow = (idx) => {
    let routes = [...this.state.amenities];
    routes.splice(idx, 1);
    this.setState({ routes });
  };

  handleCancel = () => {
    this.setState({
      routes: [
        {
          origin: "",
          destination: "",
          image: "",
          imageFile: "",
          is_legal_document_required: "",
        },
      ],
    });

    this.props.handleCreateModal();
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;
    let amenities = [...this.state.amenities];
    amenities[idx][name] = value;
    this.setState({ amenities });
  };


  componentDidMount() {
    if (this.props.updateReqData !== "") {
      this.setState({
        amenities: [
          {
            id: this.props.updateReqData ? this.props.updateReqData.id : "",
            title: this.props.updateReqData
              ? this.props.updateReqData.title
              : "",
            description: this.props.updateReqData
              ? this.props.updateReqData.description
              : "",
            image: "",
            imageFile: "",
            imageUpdated: "false",
            status: this.props.updateReqData
              ? this.props.updateReqData.status == 0
                ? 0
                : 1
              : "",
          },
        ],
        oldImg: this.props.updateReqData
          ? this.props.updateReqData.media[0]
          : "",
        spinner: false,
      });
    }
  }

  render() {
    let filteredMedia = this.props.amenity?.filter((item) => item?.id === this.props.updateReqData?.id)[0];

    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            {this.state.amenities.map((amenity, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-12">

                    {/* image upload */}
                    <div className="row my-2">
                      <div className="col-md-3 mt-1">
                        <strong>Image
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      {this.props.edit && !this.state.imageUpdated ? (
                        <div className="col-md-9">
                          <input
                            id="image"
                            className="form-control"
                            name="image"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => this.handleImage(e, idx)}
                            value={amenity.imageFile}
                          />
                          <div className="busImageHolder">
                            <div>
                              <img
                                alt="No preview available"
                                src={
                                  filteredMedia && filteredMedia !== ""
                                    ? fileUrl +
                                    "/" +
                                    filteredMedia.media[0].id +
                                    "/" +
                                    filteredMedia.media[0].file_name
                                    : sampleImg
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-9">
                          <input
                            id="image"
                            className="form-control"
                            name="image"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => this.handleImage(e, idx)}
                            value={amenity.imageFile}
                          />

                          {amenity.image !== "" ? (
                            <div className="busImageHolder">
                              <div>
                                <img
                                  alt="No preview available"
                                  src={window.URL.createObjectURL(amenity.image)}
                                />
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => this.removeImage(e, idx)}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                    {/* title */}
                    <div className="row">
                      <div className="col-md-3 my-2">
                        <strong>Title
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <input class="form-control" name="title" type="text" value={amenity.title} onChange={(e) => this.handleChange(e, idx)} />
                      </div>
                    </div>
                    {/* Description */}
                    <div className="row my-2">
                      <div className="col-md-3 mt-1">
                        <strong>Description
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <input class="form-control" name="description" type="text" value={amenity.description} onChange={(e) => this.handleChange(e, idx)} />
                      </div>
                    </div>
                    {/* status */}
                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Status
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <select
                          name="status"
                          className="form-select"
                          value={amenity.status}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose status
                          </option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* {this.props.edit ? null : (
                    <div className="col-md-1 pe-0">
                      {idx === this.state.amenities.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={this.addRow}
                          style={{ marginRight: "5px", color: "#0b5ed7" }}
                        />
                      ) : null}
                      {idx == 0 ? null : (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          style={{ color: "#bb2d3b" }}
                          onClick={() => this.deleteRow(idx)}
                        />
                      )}
                    </div>
                  )} */}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.updateAmenities}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.createAmenities}>
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAmenity;
