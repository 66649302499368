import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import aboutBannerImg from "../../../assets/images/bus4.png";
import missionImg from "../../../assets/images/bus5.png";
import busImg from "../../../assets/images/bus2.jpg";
import {
  faBusAlt,
  faPlug,
  faClock,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { axiosGet, axiosGetWeb } from "../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../utils/Constants";
class AboutUs extends Component {
  state = {
    aboutData: {},
  };
  getAboutUsData = () => {
    axiosGetWeb(URL.getAboutUs, (response) => {
      if (response.status === 200) {
        this.setState({
          aboutData: response.data?.data?.item,
        });
      }
    });
  };

  componentDidMount = () => {
    this.getAboutUsData();
  };
  state = {};
  render() {
    return (
      <div className="about-us-container">
        <div className="about-banner-img ">
          <div className="banner-text">
            <h1>{this.state.aboutData?.title || "Website Title"}</h1>{" "}
            <p>
              {this.state.aboutData?.description || "Your Website Description "}
            </p>
          </div>
          <img
            className="about-us-img"
            src={
              this.state.aboutData?.media[0].id
                ? fileUrl +
                  "/" +
                  this.state.aboutData?.media[0].id +
                  "/" +
                  this.state.aboutData?.media[0].file_name
                : aboutBannerImg
            }
            alt="No preview"
          />
        </div>
        {this.state.aboutData?.page_content && (
          <div className="about-contents container py-4">
            <div
              dangerouslySetInnerHTML={{
                __html: `${this.state.aboutData?.page_content}`,
              }}
            ></div>
          </div>
        )}

        {/* <div className="about-company">
          <div className="about-company-box">
            <h1>About Our Company</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book.
              <br />
              five centuries, but also the leap into electronic. Lorem Ipsum has
              been the industry's standard dummy text ever since the 1500s, when
              an unknown printer took a galley of type and scrambled it to make
              a type specimen book.
              <br />
              five centuries, but also the leap into electronic. Lorem Ipsum has
              been the industry's standard dummy text ever since the 1500s, when
              an unknown printer took a galley of type and scrambled it to make
              a type specimen book.
            </p>
            <div className="about-btn-container">
              <button className="about-btn">Learn More</button>
              <button className="about-btn">Contact Us</button>
            </div>
          </div>
        </div>

        <div className="image-box image-middle">
          <img src={busImg} alt="No preview" />
        </div>

        <div className="about-us-section2">
          <div className="row">
            <div className="col-md-6">
              <div className="box">
                <hr className="box-hr" />
                <h1>Our Mission</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box">
                <img src={missionImg} alt="No preview" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="box">
                <h1>Travel to any places of the country in best fares</h1>
              </div>
            </div>
            <div className="col-md-8">
              <div className="box">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="image-box">
          <img src={busImg} alt="No preview" />
        </div>

        <div className="what-we-offer">
          <div className="what-we-offer-head">
            <p>Experience our first class executive service</p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="what-we-offer-box">
                <FontAwesomeIcon icon={faBusAlt} size="2x" className="mb-2" />
                <h1>Professional Bus Drivers</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took psum has been the industry's standard dummy text
                  ever since the 1500s, when an unknown printer took a galley of
                  type and scrambled it to make a type specimen book.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="what-we-offer-box">
                <FontAwesomeIcon icon={faPlug} size="2x" className="mb-2" />

                <h1>Electrical Outlets</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standar unknown printer took a galley of type and scrambled it
                  to make a type specimen book.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="what-we-offer-box">
                <FontAwesomeIcon icon={faClock} size="2x" className="mb-2" />

                <h1>More Departure Times</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the l
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="what-we-offer-box">
                <FontAwesomeIcon icon={faWifi} size="2x" className="mb-2" />

                <h1>Free Wireless</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text electronic. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make
                  a type specimen book.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default AboutUs;
