import React, { useEffect, useState } from "react";
import "./tandc.scss";
import { axiosGet, axiosGetWeb } from "../../../utils/AxiosApi";
import { keyHeaders, URL } from "../../../utils/Constants";
import parse from "html-react-parser";
import { Spinner } from "reactstrap";
import axios from "axios";

const TermsAndConditions = () => {
  const [tAndc, setTAndc] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);

    axiosGetWeb(URL.getGuestTermsAndCondition, (response) => {
      if (response.status === 200) {
        setLoader(false);
        setTAndc(response.data?.data?.item);
      }
    });
    // axios
    //   .get(URL.getGuestTermsAndCondition, { headers: keyHeaders })
    //   .then((response) => {
    //     if (response.status === 200) {
    //       setLoader(false);
    //       setTAndc(response.data?.data?.item);
    //     }
    //   });
  }, []);

  return (
    <div>
      <div className="my-tickets-title-box text-center">
        <div className="banner-text">
          <h1>Terms and Conditions</h1>
        </div>
      </div>

      <div class="t-and-c-container">
        <div class="t-and-c-content">
          {loader ? (
            <div className="text-center">
              <Spinner />
              <br></br>Loading data...
            </div>
          ) : (
            <>
              {tAndc ? (
                <div>
                  {" "}
                  <h1>{tAndc.act}</h1>
                  <span>{parse(`${tAndc.term_and_conditions}`)}</span>
                </div>
              ) : (
                <h1 className="text-center">
                  {" "}
                  No terms and conditions available
                </h1>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
