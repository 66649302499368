import swal from "sweetalert";
import { cryptoSecretKey, URL } from "./Constants";
var CryptoJS = require("crypto-js");

export const getTravelAgencyData = () => {
  let encryptedData = localStorage.getItem("travelAgencyData");
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
};

export const checkRolePermissions = (action, moduleName) => {
  let encryptedData = localStorage.getItem("permissions");
  let checkData = `${action}|${moduleName}`;
  let checkIndex = -1;
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log(permissions);
    if (permissions.length > 0) {
      checkIndex = permissions.findIndex(
        (permission) => permission.name === checkData
      );
      if (checkIndex !== -1) return true;
      else return false;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getRolesFromStorage = () => {
  let encryptedData = localStorage.getItem("roles");
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
    return null;
  }
};

export const validateImage = (file) => {
  if (file.type.includes("image")) {
    return true;
  } else {
    swal("Error", "File not valid");
    return false;
  }
};

export function validateGoogleMapsEmbedURL(url) {
  const regex = /^https:\/\/www\.google\.com\/maps\/embed\?pb=/;
  return (
    regex.test(url) &&
    url.includes("!1m") &&
    url.includes("!2d") &&
    url.includes("!3d") &&
    url.includes("!5m")
  );
}
