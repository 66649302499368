import React, { Component } from "react";
import { fileUrl, moduleName, URL } from "../../../../utils/Constants";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { checkRolePermissions } from "../../../../utils/Helper";
import CreateCmsBlog from "./CreateCmsBlog";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import parse from "html-react-parser";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

class CmsBlog extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    edit: false,
    blogs: [],
    selectedData: {},
    spinner: false,
    updatePermission: false,
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  closeEditModal = () => {
    this.setState({ selectedData: {}, createModal: !this.state.createModal });
  };

  componentDidMount() {
    this.permissions();
  }

  deleteData = (params) => {
    axiosGet(
      URL.deleteBlog + "/" + params.id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getBlogs();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getBlogs = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
    }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.blogs + "/" + "get-blogs", param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            this.setState({
              blogs: response.data.data.items,
              current_page: pageNumber,
              totalItemsCount: response.data.data.totalData,
            });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
    });
  };

  handleDelete = (id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.deleteData);
  };

  handleEdit = (data) => {
    this.setState({
      selectedData: data,
      edit: true,
      createModal: !this.state.createModal,
    });
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions("delete", moduleName.blog),
        updatePermission: checkRolePermissions("update", moduleName.blog),
      },
      function () {
        this.getBlogs();
      }
    );
  };


  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getBlogs(1, valuePage);
    });

  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <>
        <div className="dashboardHeader">
          <strong>BLOG</strong>
        </div>
        <div className="container h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.blog) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Create Blog
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Title</th>
                      <th>Subtitle</th>
                      <th width="200px">Link</th>
                      <th>Description</th>
                      <th>Featured Image</th>
                      {this.state.deletePermission ||
                        this.state.updatePermission ? (
                        <th>Actions</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.blogs?.length > 0 ? (
                      this.state.blogs?.map((blog, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{blog.title}</td>
                            <td>{blog.subtitle}</td>
                            <td style={{ lineBreak: "anywhere" }}>
                              {blog.link === "" ? (
                                "-"
                              ) : (
                                <a
                                  href={blog.link}
                                  target="_blank"
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                  }}
                                >
                                  {blog.link}
                                </a>
                              )}
                            </td>
                            <td>
                              {blog.description === ""
                                ? "-"
                                : parse(`${blog.description}`)}
                            </td>
                            <td>
                              <div className="blogThumbnail">
                                <img
                                  src={
                                    fileUrl +
                                    "/" +
                                    blog.media[0].id +
                                    "/" +
                                    blog.media[0].file_name
                                  }
                                />
                              </div>
                            </td>
                            {this.state.deletePermission ||
                              this.state.updatePermission ? (
                              <td>
                                {this.state.updatePermission ? (
                                  <button
                                    className="btn btn-primary m-1"
                                    onClick={() => this.handleEdit(blog)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger m-1"
                                    onClick={() => this.handleDelete(blog.id)}
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="text-center p-3"
                          colSpan={
                            this.state.updatePermission ||
                              this.state.deletePermission
                              ? 7
                              : 6
                          }
                        >
                          {this.state.spinner ? (
                            <>
                              <Spinner></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Blogs created yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.blogs.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getBlogs(pageNumber, paginated_value)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateCmsBlog
              edit={this.state.edit}
              getBlogs={this.getBlogs}
              handleCreateModal={this.handleCreateModal}
              selectedData={this.state.selectedData}
            />
          }
          modalHeader={this.state.edit ? "Update Blog" : "Create Blog"}
          size="lg"
          toggleModal={this.handleCreateModal}
        ></CustomModal>
      </>
    );
  }
}

export default CmsBlog;
