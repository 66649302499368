import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet } from "../../../../utils/AxiosApi";
import { fileUrl, moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { checkRolePermissions } from "../../../../utils/Helper";
import CreateSliderImages from "./CreateSliderImages";

class CmsSlider extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    sliderImages: [],
    spinner: false,
  };

  componentDidMount = () => {
    this.permissions();
  };

  deleteImage = (params) => {
    axiosGet(
      URL.deleteSliderImage + "/" + params.id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getSliderImages();
        }
      },
      (er) => {
        displayErrorAlert(er);
      }
    );
  };

  displayRemoveButton = (id) => {
    if (this.state.deletePermission) {
      let holder = document.getElementById("holder" + id);
      let overlayDiv = document.getElementById("overlay" + id);
      let btn = document.getElementById(id);
      holder.classList.add("blurFilter");
      overlayDiv.classList.add("boxOverlay");
      btn.classList.add("showRemoveBtn");
    }
  };

  getSliderImages = () => {
    this.setState({ spinner: true }, function () {
      axiosGet(
        URL.getSliderImages,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            this.setState({ sliderImages: response.data?.data?.items });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
    });
  };

  handleDelete = (image) => {
    let params = { id: image.id };
    displayConfirmDeleteAlert(params, this.deleteImage);
  };

  hideRemoveBtn = (id) => {
    if (this.state.deletePermission) {
      let holder = document.getElementById("holder" + id);
      let overlayDiv = document.getElementById("overlay" + id);
      let btn = document.getElementById(id);
      holder.classList.remove("blurFilter");
      overlayDiv.classList.remove("boxOverlay");
      btn.classList.remove("showRemoveBtn");
    }
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions("delete", moduleName.slider),
      },
      function () {
        this.getSliderImages();
      }
    );
  };

  render() {
    return (
      <>
        <div className="dashboardHeader">
          <strong>SLIDER IMAGES</strong>
        </div>
        <div className="container">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.slider) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Images
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="cmsSliderImageDiv">
                {this.state.sliderImages?.length > 0 &&
                this.state.sliderImages[0].media.length > 0 ? (
                  this.state.sliderImages[0].media.map((image, idx) => {
                    return (
                      <div
                        key={idx}
                        className="cmsSliderImageHolder"
                        onMouseEnter={() => this.displayRemoveButton(image.id)}
                        onMouseLeave={() => this.hideRemoveBtn(image.id)}
                      >
                        <div
                          className="imageOverlay"
                          id={"overlay" + image.id}
                        ></div>
                        <div
                          className="insideImageHolder"
                          id={"holder" + image.id}
                        >
                          <img
                            src={
                              fileUrl + "/" + image.id + "/" + image.file_name
                            }
                          />
                        </div>
                        {this.state.deletePermission ? (
                          <button
                            className="btn btn-danger sliderRemoveBtn"
                            id={image.id}
                            onClick={() => this.handleDelete(image)}
                          >
                            Remove
                          </button>
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center p-3">
                    {this.state.spinner ? (
                      <>
                        <Spinner></Spinner>
                        <br />
                        Loading Images...
                      </>
                    ) : (
                      "No Slider Images added"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateSliderImages
              getSliderImages={this.getSliderImages}
              handleCreateModal={this.handleCreateModal}
            ></CreateSliderImages>
          }
          modalHeader="Add Slider Images"
          size="xl"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default CmsSlider;
