import React, { Component } from "react";
import "../../assets/scss/app.scss";
import Clock from "react-digital-clock";
import { getRolesFromStorage } from "../../utils/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBus,
  faFireAlt,
  faPaperPlane,
  faReceipt,
  faRoute,
  faTicketAlt,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import TicketBookingChart from "../../components/DashboardComponents/TicketBookingChart";
import ClockUI from "../../components/DashboardComponents/ClockUI";
import { axiosGetWeb, axiosPost, axiosPostWeb } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class Dashboard extends Component {
  state = {
    roles: [],
    dashboardCounts: null,
    topRoutes: [],
  };

  componentDidMount() {
    this.getRoles();
  }

  getTopRoutes = () => {
    axiosGetWeb(
      URL.busTopRoutes,
      (response) => {
        if (response.status === 200) {
          this.setState({
            topRoutes: response.data.data.items[0],
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getRoles = () => {
    let roles = getRolesFromStorage();
    this.setState({ roles }, () => {
      if (roles[0] !== "Developer Admin") {
        this.getDashboardCounts();
        this.getTicketSales();
        this.getTopRoutes();
      }
    });
  };

  getDashboardCounts = () => {
    axiosGetWeb(URL.getAllCountForNetworkAgency, (response) => {
      this.setState({
        dashboardCounts: response.data.data,
      });
    });
  };

  getTicketSales = () => {
    axiosPostWeb(URL.getAllTicketSales, {}, (response) => {
      this.setState({
        ticketSales: response.data.data,
      });
    });
  };

  render() {
    return (
      <>
        <div className="dashboardHeader">
          <strong>WELCOME TO DASHBOARD</strong>
        </div>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              You are Logged in as{" "}
              {this.state.roles && this.state.roles.length > 0
                ? this.state.roles.map((role, idx) => {
                    return role;
                  })
                : null}
            </div>
            <div
              className="col-md-2 text-end"
              style={{ backgroundColor: "#6c63ff", padding: "5px" }}
            >
              <Clock />
            </div>
          </div>
        </div>
        {this.state.roles[0] !== "Developer Admin" && (
          <div className="container">
            <div className="dashboard-reports mt-4">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    {/* <div className="col-md-6">
                    <div className="dashboard-box">
                      <div className="dashboard-box-head">
                        <h3>Total Tickets Sold</h3>
                        <FontAwesomeIcon icon={faTicketAlt} size="2x" />
                      </div>
                      <p>100</p>
                    </div>
                  </div> */}

                    <div className="col-md-6">
                      <div className="dashboard-box">
                        <div className="dashboard-box-head">
                          <h3>No. of Customers</h3>
                          <FontAwesomeIcon icon={faUsers} size="2x" />
                        </div>
                        <p>{this.state.dashboardCounts?.customersCount}</p>
                      </div>
                    </div>
                    {this.state.roles[0] === "Network Admin" ? (
                      <div className="col-md-6">
                        <div className="dashboard-box">
                          <div className="dashboard-box-head">
                            <h3>No. of Travel Agencies</h3>
                            <FontAwesomeIcon icon={faBus} size="2x" />
                          </div>
                          <p>{this.state.dashboardCounts?.travelAgencyCount}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-6">
                      <div className="dashboard-box">
                        <div className="dashboard-box-head">
                          <h3>No. of Operators</h3>
                          <FontAwesomeIcon icon={faReceipt} size="2x" />
                        </div>
                        <p>{this.state.dashboardCounts?.operatorsCount}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="dashboard-box">
                        <div className="dashboard-box-head">
                          <h3>Available Routes</h3>
                          <FontAwesomeIcon icon={faRoute} size="2x" />
                        </div>
                        <p>{this.state.topRoutes?.length}</p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <TicketBookingChart />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <ClockUI />
                    </div>
                    <div className="col-md-12">
                      <div className="dashboard-box dashboard-routes">
                        <div className="dashboard-box-head">
                          <h3>Top Routes</h3>
                          <FontAwesomeIcon icon={faFireAlt} size="2x" />
                        </div>
                        {this.state.topRoutes &&
                          this.state.topRoutes.length > 0 &&
                          this.state.topRoutes?.map((tr) => (
                            <p>{`${tr.origin.name} - ${tr.destination.name}`}</p>
                          ))}
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                    <div className="dashboard-box">
                      <div className="dashboard-box-head">
                        <h3>Available Routes</h3>
                        <FontAwesomeIcon icon={faRoute} size="2x" />
                      </div>
                      <p>{this.state.dashboardCounts?.availableRouteCounts}</p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Dashboard;
