import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./blog.scss";
import parse from "html-react-parser";
import { fileUrl, URL } from "../../../utils/Constants";
import moment from "moment";
import { axiosGet } from "../../../utils/AxiosApi";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import { Link } from "react-router-dom";

const SingleBlog = () => {
  const location = useLocation();
  const [blog, setBlog] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // axiosGet(
    //   URL.getBlogs,
    //   (response) => {
    //     if (response.status === 200) {
    //       setAllBlogs(response.data.data.items);
    //     }
    //   },
    //   (err) => {
    //     displayErrorAlert(err);
    //   }
    // );
    let recentBlogs = location?.state?.recentBlogs?.data;

    if (recentBlogs) {
      setAllBlogs(recentBlogs);
    }
    let blogData = location?.state?.blogData;

    if (blogData) {
      setBlog(blogData);
    }
  }, [location]);

  return (
    <div>
      <section className="post-content-section">
        <div className="container">
          <div className="row g-3">
            <div className="col-12">
              <div className="post-title-block assetimg">
                {blog && blog?.media && blog?.media?.length > 0 && (
                  <img
                    src={
                      fileUrl +
                      "/" +
                      blog?.media[0].id +
                      "/" +
                      blog?.media[0].file_name
                    }
                    className="img-fluid"
                    alt="No preview"
                  />
                )}
                <div className="post-title-texts">
                  <h1>{blog.title}</h1>
                  <span>{moment(blog.created_at).format("LL")}</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <h4 className="side-t">
                <span>Recent</span> Posts{" "}
              </h4>
              <div className="list-group recent-p">
                {allBlogs &&
                  allBlogs?.map((data) =>
                    data.description ? (
                      <Link
                        to="/single-blog"
                        state={{ blogData: data }}
                        className="list-group-item "
                      >
                        <h5 className="recent-title">{data.title}</h5>
                        <small>{moment(data.created_at).format("LL")}</small>
                      </Link>
                    ) : (
                      <a
                        href={data.link}
                        className="list-group-item"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h5 className="recent-title">{data.title}</h5>
                        <small>{moment(data.created_at).format("LL")}</small>
                      </a>
                    )
                  )}
              </div>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="post-content-block">
                <p>{parse(`${blog.description}`)}</p>

                <div className="image-block">
                  {blog && blog.media && blog.media.length > 0 && (
                    <img
                      src={
                        fileUrl +
                        "/" +
                        blog?.media[0].id +
                        "/" +
                        blog?.media[0].file_name
                      }
                      className="img-fluid"
                      alt="No preview"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleBlog;
