import React, { useState } from "react";
import image from "../../../assets/images/thankyouImg.svg";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import TicketReceipt from "../MyTickets/TicketReceipt";

const Finished = (props) => {
  const [receiptModal, setReceiptModal] = useState(false);
  const [receiptPdf, setReceiptPdf] = useState("");

  const toggleModal = () => {
    setReceiptModal(!receiptModal);
  };

  const handleViewReceipt = () => {
    setReceiptPdf(props.bookingDetailsPdf);

    setReceiptModal(true);
  };

  return (
    <>
      <div
        className="container justify-content-center text-center"
        style={{ minheight: "40vh" }}
      >
        <div className="row mt-4">
          <div className="col">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <img
                  className="center-block p-4"
                  style={{ width: "50%" }}
                  src={image}
                  alt="No preview"
                />
              </div>
            </div>

            <h4>Thank you for choosing us...</h4>
            <p>
              {" "}
              Your booking has be confirmed. Please contact us at 01-5151555 for
              any quesries and suggestions.
            </p>
          </div>
        </div>
        <button className="btn btn-info" onClick={handleViewReceipt}>
          View reciept
        </button>
      </div>

      <CustomModal
        modal={receiptModal}
        modalHeader={"Receipt"}
        modalBody={<TicketReceipt receiptPdf={receiptPdf} />}
        size="lg"
        toggleModal={toggleModal}
      ></CustomModal>
    </>
  );
};

export default Finished;
