import React, { Component } from "react";
import swal from "sweetalert";

import { URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { axiosPost } from "../../../../utils/AxiosApi";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import "../../../../assets/scss/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

class CreateBusTypes extends Component {
  state = {
    busTypes: [{ type: "", feature: "" }],
    loader: false,
  };

  addRow = () => {
    this.setState({
      busTypes: [
        ...this.state.busTypes,
        {
          type: "",
          feature: "",
        },
      ],
    });
  };

  componentDidMount() {
    if (this.props.editMode) {
      this.editConfig();
    }
  }

  deleteRow = (idx) => {
    let busTypes = [...this.state.busTypes];
    busTypes.splice(idx, 1);
    this.setState({ busTypes });
  };

  editConfig = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      busTypes: [
        {
          id: selectedData.id,
          type: selectedData.type,
          feature: selectedData.feature,
        },
      ],
    });
  };

  handleCancel = () => {
    this.setState({
      busTypes: [
        {
          type: "",
          feature: "",
        },
      ],
    });

    this.props.handleCreateModal();
  };

  handleChange = (e, idx) => {
    let { name } = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let busTypes = [...this.state.busTypes];
    busTypes[idx][name] = value;
    this.setState({ busTypes });
  };

  handleSave = () => {
    let err = 0;
    this.state.busTypes.forEach((chk) => {
      if (chk.feature === "" || chk.type === "") {
        err = err + 1;
      }
    });
    if (err > 0) {
      swal("Error", "Bus type and feature cannot be empty.", "error");
    } else {
      this.setState({ loader: true }, function () {
        axiosPost(
          URL.busTypes,
          this.state.busTypes,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.setState({ loader: false });
              this.props.getBusTypes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false, loader: false });
          }
        );
      });
    }
  };

  handleUpdate = () => {
    if (
      this.state.busTypes[0].type === "" ||
      this.state.busTypes[0].feature === ""
    ) {
      swal("Error", "Bus type and feature cannot be empty.", "error");
    } else {
      let param = {
        id: this.state.busTypes[0].id,
        type: this.state.busTypes[0].type,
        feature: this.state.busTypes[0].feature,
      };

      this.setState({ loader: true }, function () {
        axiosPost(
          URL.updateBusTypes,
          param,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.setState({ loader: false });
              this.props.getBusTypes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);

            this.setState({ spinner: false, loader: false });
          }
        );
      });
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            {this.state.busTypes.map((rp, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-2 mt-1">
                        <strong>Type
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          name="type"
                          value={rp.type}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-2 mt-1">
                        <strong>Feature
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-10">
                        <textarea
                          name="feature"
                          className="form-control"
                          value={rp.feature}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                  </div>
                  {this.props.editMode ? null : (
                    <div className="col-md-1 pe-0">
                      {idx === this.state.busTypes.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={this.addRow}
                          style={{ marginRight: "5px", color: "#0b5ed7" }}
                        />
                      ) : null}
                      {idx == 0 ? null : (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          onClick={() => this.deleteRow(idx)}
                          style={{ color: "#bb2d3b" }}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.editMode ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBusTypes;
