import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { validateImage } from "../../../utils/Helper";

const animatedComponents = makeAnimated();
class CreateTravelAgencyRoutes extends Component {
  state = {
    defaultRoutes: [],
    travelAgencyOptions: [],
    isLoading: false,
    routes: [
      {
        origin: "",
        destination: "",
        travel_agency_id: '',
        image: "",
        imageFile: "",
        is_legal_document_required: "",
      },
    ],
    selectedDefaultRoutes: [],
    selectedDefaultAgency: [],
    spinner: false,
  };

  addRow = () => {
    const lastSelectedAgencyId = this.state.selectedDefaultAgency?.data?.id || '';
    this.setState({
      routes: [
        ...this.state.routes,
        {
          origin: "",
          destination: "",
          travel_agency_id: lastSelectedAgencyId,
          image: "",
          imageFile: "",
          is_legal_document_required: "",
        },
      ],
    });
  };

  componentDidMount() {
    this.getDefaultRoutes();
    if (this.props.roles === "Network Admin") this.getTravelAgencies();
  }

  createRoutes = () => {
    let imageChk = [...this.state.routes];
    let index = [];
    let files = [];

    imageChk.forEach((item, idx) => {
      if (item.image !== "") {
        index.push({
          idx,
        });
        let imagePush = item.image;
        files.push(imagePush);
      }
    });

    let formData = new FormData();

    files.forEach((image) => formData.append("files[]", image));
    formData.append("index", JSON.stringify(index));

    let param = [...this.state.routes];
    let error = 0;
    param.forEach((el) => {
      if (el.origin === "" || el.destination === "") {
        error++;
      }
    });

    if (error > 0 && this.state.selectedDefaultRoutes?.length == 0) {
      swal("Error", "Origins and Destination cannot be empty", "error");
    } else {
      if (
        param?.length == 1 &&
        (param[0].origin === "" || param[0].destination === "")
      ) {
        param = [];
      }
      if (this.state.selectedDefaultRoutes?.length > 0) {
        let selectedDefaultRoutes = [...this.state.selectedDefaultRoutes];
        let selectedDefaultAgency = this.state.selectedDefaultAgency;

        selectedDefaultRoutes.forEach((el) => {
          param.push({
            origin: el.data.origin.toString(),
            destination: el.data.destination.toString(),
            travel_agency_id: selectedDefaultAgency?.data?.id,
          });
        });
      }

      formData.append("param", JSON.stringify(param));

      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.routes,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.setState({ spinner: false });
              this.props.getRoutes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false });
          }
        );
      });
    }
  };

  deleteRow = (idx) => {
    let routes = [...this.state.routes];

    routes.splice(idx, 1);
    this.setState({ routes });
  };

  getDefaultRoutes = () => {
    let param = {};
    this.setState({ isLoading: true }, function () {
      axiosPost(
        URL.routes + "/get-routes", param,
        (response) => {
          if (response.status === 200) {
            let data = response.data.data.items;
            let newData = [];
            if (data?.length > 0) {
              data.forEach((el) => {
                newData.push({
                  label: `${el.origin_city.name} - ${el.destination_city.name}`,
                  value: el.id,
                  data: el,
                });
              });
            }
            this.setState({
              defaultRoutes: newData,
              isLoading: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ isLoading: false });
        }
      );
    });
  };

  handleCancel = () => {
    this.setState({
      routes: [
        {
          origin: "",
          destination: "",
          travel_agency_id: '',
          imageFile: "",
          image: "",
          is_legal_document_required: "",
        },
      ],
    });
    this.props.handleCreateModal();
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;
    let routes = [...this.state.routes];
    routes[idx][name] = value;
    this.setState({ routes });
  };

  handleImage = (e, idx) => {
    let { name } = e.target;
    let routes = [...this.state.routes];

    // if (e.target.files[0].type !== "image/png") {
    // }

    if (validateImage(e.target.files[0])) {
      routes[idx][name] = e.target.files[0];
      routes[idx].imageFile = e.target.value;

      this.setState({ routes });
    }
  };

  removeImage = (e, idx) => {
    let routes = [...this.state.routes];

    routes[idx].image = "";
    routes[idx].imageFile = "";
    this.setState({
      routes,
    });
  };

  handleDefaultRouteChange = (item) => {
    this.setState({ selectedDefaultRoutes: item });
  };

  handleTravalAgencies = (item) => {
    this.setState({ selectedDefaultAgency: item }, () => {
      this.setState((prevState) => ({
        routes: prevState.routes.map((route) => ({
          ...route,
          travel_agency_id: this.state.selectedDefaultAgency?.data?.id || '',
        })),
      }));
    });
  };


  getTravelAgencies = () => {
    let param = {};
    this.setState({ isLoading: true }, function () {
      axiosPost(
        URL.travelAgency + "/get-travel-agencies", param,
        (response) => {
          if (response.status === 200) {
            let data = response.data.data.items;
            let newData = [];
            if (data?.length > 0) {
              data.forEach((el) => {
                newData.push({
                  label: `${el.name}`,
                  value: el.id,
                  data: el,
                });
              });
            }
            this.setState({
              travelAgencyOptions: newData,
              isLoading: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ isLoading: false });
        }
      );
    });
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col">
            <Select
              isMulti
              isClearable
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={this.state.defaultRoutes}
              name="defaultRoutes"
              value={this.state.selectedDefaultRoutes}
              onChange={(item) => this.handleDefaultRouteChange(item)}
              placeholder="Choose default Route"
              isLoading={this.state.isLoading}
            ></Select>
          </div>
        </div>
        {/* added for select options to choose list of travel agencies */}
        {
          this.props.roles === "Network Admin" ?
            (
              <>
                <div className="row my-2">
                  <div className="col">
                    <Select
                      // isMulti
                      isClearable
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.travelAgencyOptions}
                      name="defaultAgencies"
                      value={this.state.selectedDefaultAgency}
                      onChange={(item) => this.handleTravalAgencies(item)}
                      placeholder="Choose travel agency"
                      isLoading={this.state.isLoading}
                    ></Select>
                  </div>
                </div>
              </>)
            : (
              <>
              </>)
        }
        <div className="row">
          <div className="col-sm-12 ">
            <p className="border-bottom m-2"><strong>Create a new Route:</strong></p>
          </div>
        </div>

        <div className="routeMainHolder mt-2" style={{ maxHeight: "61.5vh" }}>
          <div className="container-fluid">
            {this.state.routes.map((route, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-3 mt-1">
                        <strong>Origin
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <select
                          disabled={this.state.routes.some(route => route.travel_agency_id === '') && this.props.roles == " Admin"}
                          name="origin"
                          className="form-select"
                          value={route.origin}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose Origin
                          </option>
                          {this.props.cities?.length > 0
                            ? this.props.cities.map((city, idx) => {
                              return (
                                <option value={city.id} key={idx}>
                                  {city.name}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Destination
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <select
                          disabled={this.state.routes.some(route => route.travel_agency_id === '') && this.props.roles == "Network Admin"}
                          name="destination"
                          className="form-select"
                          value={route.destination}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose Destination
                          </option>
                          {this.props.cities?.length > 0
                            ? this.props.cities.map((city, idx) => {
                              return (
                                <option value={city.id} key={idx}>
                                  {city.name}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Image</strong>
                      </div>
                      <div className="col-md-9">
                        <input
                          disabled={this.state.routes.some(route => route.travel_agency_id === '') && this.props.roles == "Network Admin"}
                          id="image"
                          className="form-control"
                          name="image"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => this.handleImage(e, idx)}
                          value={route.imageFile}
                        />

                        {route.image !== "" ? (
                          <div className="busImageHolder">
                            <div>
                              <img
                                alt="No preview available"
                                src={window.URL.createObjectURL(route.image)}
                              />
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{
                                  position: "absolute",
                                  right: "5px",
                                  top: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => this.removeImage(e, idx)}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Is Legal Documents Required?</strong>
                      </div>
                      <div className="col-md-9">

                        <select
                          disabled={this.state.routes.some(route => route.travel_agency_id === '') && this.props.roles == "Network Admin"}
                          name="is_legal_document_required"
                          className="form-select"
                          value={route.is_legal_document_required}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose status
                          </option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    className=
                    // "col-md-1 pe-0"
                    {this.state.routes.some(route => route.travel_agency_id === '') ? `col-md-1 pe-0 d-none` : `col-md-1 pe-0 d-block`}
                  >
                    {idx === this.state.routes?.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        onClick={this.addRow}
                        style={{ marginRight: "5px", color: "#0b5ed7" }}
                      />
                    ) : null}
                    {idx == 0 ? null : (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        style={{ color: "#bb2d3b" }}
                        onClick={() => this.deleteRow(idx)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button className="btn btn-primary" onClick={this.createRoutes}>
              Create
            </button>
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTravelAgencyRoutes;
