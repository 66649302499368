import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { fileUrl } from "../../../utils/Constants";
import sampleImg from "../../../assets/images/logo.jpg";
import { validateImage } from "../../../utils/Helper";

class CreateRoute extends Component {
  state = {
    routes: [
      {
        id: "",
        origin: "",
        destination: "",
        image: "",
        imageFile: "",
        imageUpdated: "false",
        is_legal_document_required: "",
      },
    ],
    oldImg: "",
    spinner: false,
  };

  addRow = () => {
    this.setState({
      routes: [
        ...this.state.routes,
        {
          origin: "",
          destination: "",
          image: "",
          imageFile: "",
          is_legal_document_required: "",
        },
      ],
    });
  };

  createRoutes = () => {
    let routesChk = [...this.state.routes];
    let index = [];
    let files = [];

    routesChk.forEach((item, idx) => {
      if (item.image !== "") {
        index.push({
          idx,
        });
        let imagePush = item.image;
        files.push(imagePush);
      }
    });

    let formData = new FormData();

    files.forEach((image) => formData.append("files[]", image));
    formData.append("index", JSON.stringify(index));
    let param = [...this.state.routes];
    formData.append("param", JSON.stringify(param));

    let err = 0;

    routesChk.forEach((chk) => {
      if (
        chk.origin === "" ||
        chk.destination === "" ||
        chk.is_legal_document_required === ""
      ) {
        err = err + 1;
      }
    });

    if (err > 0) {
      swal("Error", "Fill all the fields.", "error");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.routes,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.setState({ spinner: false });
              this.props.getRoutes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false });
          }
        );
      });
    }
  };

  handleImage = (e, idx) => {
    let { name } = e.target;
    let routes = [...this.state.routes];

    if (validateImage(e.target.files[0])) {
      routes[idx][name] = e.target.files[0];
      routes[idx].imageFile = e.target.value;

      this.setState({ routes, imageUpdated: true });
    }
  };

  removeImage = (e, idx) => {
    let routes = [...this.state.routes];

    routes[idx].image = "";
    routes[idx].imageFile = "";
    this.setState({
      routes,
    });
  };

  updateRoutes = () => {
    let routesChk = [...this.state.routes];

    let files = [];

    routesChk.forEach((item, idx) => {
      if (item.image !== "") {
        let imagePush = item.image;
        files.push(imagePush);
      }
    });

    let formData = new FormData();

    files.forEach((image) => formData.append("files", image));

    let param = {
      origin: this.state.routes[0].origin,
      destination: this.state.routes[0].destination,
      is_legal_document_required:
        this.state.routes[0].is_legal_document_required,
    };

    formData.append(
      "id",

      this.state.routes[0].id
    );
    formData.append("param", JSON.stringify(param));
    if (!this.state.imageUpdated) {
      formData.append("oldImg", this.state.oldImg);
    }

    let err = 0;

    routesChk.forEach((chk) => {
      if (
        chk.origin === "" ||
        chk.destination === "" ||
        chk.is_legal_document_required === ""
      ) {
        err = err + 1;
      }
    });

    if (err > 0) {
      swal("Error", "Fill all the fields.", "error");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.updateRoute,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
              this.setState({ spinner: false });
              this.props.getRoutes();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ spinner: false, imageUpdated: false });
          }
        );
      });
    }
  };

  deleteRow = (idx) => {
    let routes = [...this.state.routes];
    routes.splice(idx, 1);
    this.setState({ routes });
  };

  handleCancel = () => {
    this.setState({
      routes: [
        {
          origin: "",
          destination: "",
          image: "",
          imageFile: "",
          is_legal_document_required: "",
        },
      ],
    });

    this.props.handleCreateModal();
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;
    let routes = [...this.state.routes];
    routes[idx][name] = value;
    this.setState({ routes });
  };

  componentDidMount() {
    if (this.props.updateReqData !== "") {
      this.setState({
        routes: [
          {
            id: this.props.updateReqData ? this.props.updateReqData.id : "",
            origin: this.props.updateReqData
              ? this.props.updateReqData.origin
              : "",
            destination: this.props.updateReqData
              ? this.props.updateReqData.destination
              : "",
            image: "",
            imageFile: "",
            imageUpdated: "false",
            is_legal_document_required: this.props.updateReqData
              ? this.props.updateReqData.is_legal_document_required == 0
                ? 0
                : 1
              : "",
          },
        ],
        oldImg: this.props.updateReqData
          ? this.props.updateReqData.media[0]
          : "",
        spinner: false,
      });
    }
  }

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="routeMainHolder">
          <div className="container-fluid">
            {this.state.routes.map((route, idx) => {
              return (
                <div className="row createRouteHolder" key={idx}>
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-3 mt-1">
                        <strong>Origin
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <select
                          name="origin"
                          className="form-select"
                          // value={this.props.updateReqData.originName}
                          value={route.origin}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose Origin
                          </option>
                          {this.props.cities.length > 0
                            ? this.props.cities.map((city, idx) => {
                              return (
                                // {this.props.updateReqData.origin === city.id }
                                <option
                                  value={city.id}
                                  key={idx}
                                  selected={
                                    this.props.updateReqData.origin ===
                                      city.id
                                      ? true
                                      : false
                                  }
                                >
                                  {city.name}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Destination
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <select
                          name="destination"
                          className="form-select"
                          value={route.destination}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose Destination
                          </option>
                          {this.props.cities.length > 0
                            ? this.props.cities.map((city, idx) => {
                              return (
                                <option
                                  value={city.id}
                                  key={idx}
                                  selected={
                                    this.props.updateReqData.destination ===
                                      city.id
                                      ? true
                                      : false
                                  }
                                >
                                  {city.name}
                                </option>
                              );
                            })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Image</strong>
                      </div>
                      {this.props.edit && !this.state.imageUpdated ? (
                        <div className="col-md-9">
                          <input
                            id="image"
                            className="form-control"
                            name="image"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => this.handleImage(e, idx)}
                            value={route.imageFile}
                          />
                          <div className="busImageHolder">
                            <div>
                              <img
                                alt="No preview available"
                                src={
                                  this.state.oldImg && this.state.oldImg !== ""
                                    ? fileUrl +
                                    "/" +
                                    this.state.oldImg.id +
                                    "/" +
                                    this.state.oldImg.file_name
                                    : sampleImg
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-9">
                          <input
                            id="image"
                            className="form-control"
                            name="image"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => this.handleImage(e, idx)}
                            value={route.imageFile}
                          />

                          {route.image !== "" ? (
                            <div className="busImageHolder">
                              <div>
                                <img
                                  alt="No preview available"
                                  src={window.URL.createObjectURL(route.image)}
                                />
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => this.removeImage(e, idx)}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3 mt-1">
                        <strong>Is Legal Documents Required?
                          <span className="text-danger"> *</span>
                        </strong>
                      </div>
                      <div className="col-md-9">
                        <select
                          name="is_legal_document_required"
                          className="form-select"
                          value={route.is_legal_document_required}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose status
                          </option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.props.edit ? null : (
                    <div className="col-md-1 pe-0">
                      {idx === this.state.routes.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={this.addRow}
                          style={{ marginRight: "5px", color: "#0b5ed7" }}
                        />
                      ) : null}
                      {idx == 0 ? null : (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          style={{ color: "#bb2d3b" }}
                          onClick={() => this.deleteRow(idx)}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.updateRoutes}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.createRoutes}>
                Create
              </button>
            )}
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateRoute;
