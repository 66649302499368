import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert";
import BusSeatUI from "../../../../components/DashboardComponents/BusSeatUI";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import { seatConfig, URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import BusConfigurationUI from "../../../../components/DashboardComponents/BusConfigurationUI";

class BusTypeSeatConfiguration extends Component {
  state = {
    busTypeId: "",
    loader: false,
    seats: [],
    totalSeatsInRow: 0,
  };

  addBusRow = () => {
    let seats = [...this.state.seats];
    let columns = [];
    for (let i = 0; i < this.state.totalSeatsInRow; i++) {
      columns.push({
        columnName: i + 1,
        is_driver: false,
        is_open_for_booking: true,
        is_empty: false,
      });
    }
    seats.push({
      row: "",
      columns: columns,
      isOpen: false,
    });
    this.setState({ seats }, function () {
      let seatsNew = [...this.state.seats];
      seatsNew.forEach((el, idx) => {
        if (idx === seatsNew.length - 1) {
          el.isOpen = true;
        } else {
          el.isOpen = false;
        }
      });
      this.setState({ seats: seatsNew });
    });
  };

  updateConfig = () => {
    let newSeats = [...this.state.seats];
    const updateSeats = newSeats.concat(this.props.seatConfigData);
    this.setState({
      seats: updateSeats,
      totalSeatsInRow: updateSeats[0].columns.length,
    });
  };

  componentDidMount() {
    this.config();

    if (this.props.editMode) {
      this.updateConfig();
    }
  }

  config = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      busTypeId: selectedData.id,
    });
  };

  deleteBusRow = (idx) => {
    let seats = [...this.state.seats];
    seats.splice(idx, 1);
    this.setState({ seats });
  };

  handleCancel = () => {
    this.setState({
      seats: [],
      totalSeatsInRow: 0,
    });
    this.props.configureSeat("");
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "totalSeatsInRow") {
      if (this.state.totalSeatsInRow > 0 && this.state.seats.length > 0) {
        swal({
          title: "Are you sure?",
          text: "Changing total seats will reset all data",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.setState({
              seats: [],
              [name]: value,
            });
          } else {
          }
        });
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleColumnDataChange = (e, rowIdx, columnIdx) => {
    let seats = [...this.state.seats],
      selectedColumn = seats[rowIdx].columns,
      name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    selectedColumn[columnIdx][name] = value;
    if (name === seatConfig.empty || name === seatConfig.driver) {
      selectedColumn[columnIdx].columnName = "";
      selectedColumn[columnIdx].is_open_for_booking = false;
      let count = 0;
      selectedColumn.forEach((el) => {
        let check = 0;
        if (el.is_driver || el.is_empty) {
          check++;
        }
        if (check == 0) {
          // el.columnName = count + 1;
          // el.columnName = columnIdx
          // count++;
        }
      });
    }
    this.setState({ seats });
  };

  handleConfigureRowChange = (e, idx) => {
    let seats = [...this.state.seats];
    let { name, value } = e.target;
    seats[idx][name] = value;
    this.setState({ seats });
  };
   removeDuplicates=(arr) =>{ 
    return arr.filter((item, 
        index) => arr.indexOf(item) === index); 
} 
  handleSave = () => {
    let seats = [...this.state.seats];
    let allColumnNames=[];
    let error = 0;
    let param = {
      bus_type_id: this.state.busTypeId,
      seats: [],
    };
    if (seats.length > 0) {
      seats.forEach((el) => {
        if (el.row !== "" || el.row=="") {
          let columns = [];
          el.columns.forEach((cc) => {
            let column = {};
            if (
              cc.is_driver === "" &&
              cc.is_empty === "" &&
              cc.is_open_for_booking === ""
            ) {
              error++;
            } else {
              if (!cc.is_empty) {
                column.is_open_for_booking = cc.is_open_for_booking;
                column.is_driver = cc.is_driver;
                column.name = cc.columnName;
                 if(cc.columnName!="" && cc.columnName!=null && cc.columnName!=undefined){
                  allColumnNames.push(cc.columnName);
                  }
              }
              columns.push(column);
            }
          });
          param.seats.push({
            row: el.row,
            columns: columns,
          });
         
          
        } else {
          error++;
        }
      });
      let uniqueAllColumnNames=this.removeDuplicates(allColumnNames);
      if(allColumnNames.length!=uniqueAllColumnNames.length){
        error++;
      }
      if (error > 0) {
        swal("Error", "Column Data might be missing and column name must be unique", "error");
      } else {
        this.setState({ loader: true }, function () {
          axiosPost(
            URL.busTypeSeatConfiguration,
            param,
            (response) => {
              if (response.data.success) {
                swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
                this.props.getBusTypes();
                this.props.configureSeat("");
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({ loader: false });
            }
          );
        });
      }
    } else {
      swal("Error", "No seats are configured", "error");
    }
  };
 
  handleUpdate = () => {
    let seats = [...this.state.seats];
    let allColumnNames=[];

    let error = 0;
    let param = {
      bus_type_id: this.state.busTypeId,
      seats: [],
    };
    if (seats.length > 0) {
      seats.forEach((el) => {
        if (el.row !== "" || el.row=="") {
          let columns = [];
          el.columns.forEach((cc) => {
            let column = {};
            if (
              cc.is_driver === "" &&
              cc.is_empty === "" &&
              cc.is_open_for_booking === ""
            ) {
              error++;
            } else {
              if (!cc.is_empty) {
                column.is_open_for_booking = cc.is_open_for_booking;
                column.is_driver = cc.is_driver;
                column.name = cc.columnName;
                if(cc.columnName!="" && cc.columnName!=null && cc.columnName!=undefined){
                  allColumnNames.push(cc.columnName);
                  }
              }
              columns.push(column);
            }
          });
          param.seats.push({
            row: el.row,
            columns: columns,
          });
        } else {
          error++;
        }
      });
      let uniqueAllColumnNames=this.removeDuplicates(allColumnNames);
      if(allColumnNames.length!=uniqueAllColumnNames.length){
        error++;
      }
      if (error > 0) {
        swal("Error", "Column Data might be missing and column name must be unique", "error");
      } else {
        this.setState({ loader: true }, function () {
          axiosPost(
            URL.updateBusTypeSeatConfiguration,
            param,
            (response) => {
              if (response.data.success) {
                swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
                this.props.getBusTypes();
                this.props.configureSeat("");
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({ loader: false });
            }
          );
        });
      }
    } else {
      swal("Error", "No seats are configured", "error");
    }
  };

  toggleCollpasible = (idx) => {
    let seats = [...this.state.seats];
    if (seats[idx].isOpen) {
      seats.forEach((el) => {
        el.isOpen = false;
      });
    } else {
      seats.forEach((el) => {
        el.isOpen = false;
      });
      seats[idx].isOpen = true;
    }
    this.setState({ seats });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.loader ? (
          <CustomFullSpinner text="Saving ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col-md-7">
            <BusConfigurationUI
              addBusRow={this.addBusRow}
              deleteBusRow={this.deleteBusRow}
              handleChange={this.handleChange}
              handleColumnDataChange={this.handleColumnDataChange}
              handleConfigureRowChange={this.handleConfigureRowChange}
              seats={this.state.seats}
              toggleCollpasible={this.toggleCollpasible}
              totalSeatsInRow={this.state.totalSeatsInRow}
            />
          </div>
          <div
            className="col-md-5"
            style={{
              borderLeft: "1px solid #ccc",
            }}
          >
            <div className="busConfigurationUIHolder">
              <div style={{ zoom: "64%" }}>
                <BusSeatUI seats={this.state.seats} config={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <hr></hr>
          <div className="col text-end">
            {this.props.editMode ? (
              <button
                className="btn btn-primary me-2"
                onClick={this.handleUpdate}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary me-2"
                onClick={this.handleSave}
              >
                Save
              </button>
            )}
            <button className="btn btn-danger" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default BusTypeSeatConfiguration;
