import React, { Component } from "react";
import HeaderUI from "./HeaderUI";

class Header extends Component {
  state = {
    collapsed: false,
  };

  handleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <HeaderUI
        collapsed={this.state.collapsed}
        handleCollapse={this.handleCollapse}
      ></HeaderUI>
    );
  }
}

export default Header;
