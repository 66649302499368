import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class CustomModal extends Component {
  state = {
    modalWidth: null,
  };

  componentDidMount() {
    this.setDefaultSize();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modalWidth !== window.innerWidth - 10 + "px") {
      this.setDefaultSize();
    }
  }

  setDefaultSize = () => {
    let viewWidth = window.innerWidth - 10;
    this.setState({
      modalWidth: viewWidth + "px",
    });
  };

  getWidth = () => ({
    maxWidth: this.state.modalWidth,
    marginLeft: 80 + "px",
    marginRight: 80 + "px",
  });

  render() {
    return (
      <React.Fragment>
        <Modal
          // shouldCloseOnOverlayClick={false}
          backdrop={!this.props.backdrop}
          style={this.props.fullWidth ? this.getWidth() : null}
          isOpen={this.props.modal}
          toggle={this.props.toggleModal}
          aria-labelledby="contained-modal-title-vcenter"
          className={this.props.modalClass ? this.props.modalClass : ""}
          size={this.props.size ? this.props.size : ""}
          centered
          id={this.props.id ? this.props.id : "myModal"}
        >
          {this.props.noModelHeader ? null : (
            <ModalHeader toggle={this.props.toggleModal}>
              <div>{this.props.modalHeader}</div>
            </ModalHeader>
          )}
          <ModalBody>{this.props.modalBody}</ModalBody>
          {this.props.modalFooter ? (
            <ModalFooter>{this.props.modalFooter}</ModalFooter>
          ) : (
            ""
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default CustomModal;
