import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from "reactstrap";
import "../../../assets/scss/main.scss";
import logo from "../../../assets/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";

import userIcon from "../../../assets/images/user-256.ico";
// import userIcon from "../../../assets/images/usericon.png";
import { cryptoSecretKey, fileUrl } from "../../../utils/Constants";
import CustomFullSpinner from "../CustomFullSpinner";

var CryptoJS = require("crypto-js");

function HeaderUI(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const getData = () => {
    const value = localStorage.getItem("token");
    if (value === "" || value === undefined || value === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
    if (value !== "" && value !== undefined && value !== null) {
      let profileImg = localStorage?.getItem("profileImg");
      let loggedInUser = localStorage?.getItem("loggedInUser");

      if (profileImg) {
        var bytes = CryptoJS.AES.decrypt(profileImg, cryptoSecretKey);
        var newProfileImg = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        setProfileImg(newProfileImg);
      }

      if (loggedInUser) {
        var nBytes = CryptoJS.AES.decrypt(loggedInUser, cryptoSecretKey);
        var newLoggedInUser = JSON.parse(nBytes.toString(CryptoJS.enc.Utf8));

        setLoggedInUser(newLoggedInUser);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const togglePopover = () => {
    setPopOverOpen(!popOverOpen);
  };

  const handleLogout = () => {
    setLoader(true);

    setTimeout(() => {
      localStorage.clear();
      setLoggedIn(false);
      navigate("/");
      setLoggedInUser("");
      setProfileImg("");
      getData();
      setLoader(false);
    }, 2000);
  };

  return (
    <div>
      {loader ? (
        <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
      ) : null}
      <Navbar className="mainNav" container="xl " expand="md" dark>
        <NavbarBrand href="/">
          <img src={logo} alt="" />
        </NavbarBrand>
        <NavbarToggler onClick={props.handleCollapse} />
        <Collapse navbar isOpen={props.collapsed}>
          <Nav className="ms-auto nav-det" navbar>
            <NavItem>
              <NavLink>
                <Link to="/" style={{ textDecorationLine: "none" }}>
                  Home
                </Link>
              </NavLink>
            </NavItem>
            {loggedIn ? (
              <NavItem>
                <NavLink>
                  <Link to="/my-tickets" style={{ textDecorationLine: "none" }}>
                    My Tickets
                  </Link>
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem>
              <NavLink>
                <Link to="/about" style={{ textDecorationLine: "none" }}>
                  About Us
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link to="/blogs" style={{ textDecorationLine: "none" }}>
                  Blogs
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link to="/faq" style={{ textDecorationLine: "none" }}>
                  FAQ's
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link to="/contact-us" style={{ textDecorationLine: "none" }}>
                  Contact Us{" "}
                </Link>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink>
                <Link to="/help" style={{ textDecorationLine: "none" }}>
                  Help
                </Link>
              </NavLink>
            </NavItem> */}
          </Nav>

          {loggedIn ? (
            <Nav className="ms-auto nav-user" navbar>
              <div className="profile-display d-flex align-items-center">
                <div className="profile-icon m-3">
                  <img
                    id="mypopover"
                    src={
                      profileImg
                        ? fileUrl +
                          "/" +
                          profileImg.id +
                          "/" +
                          profileImg.file_name
                        : userIcon
                    }
                    alt="preview not available"
                    style={{ width: "25 px", padding: "5" }}
                  />
                </div>
                <div style={{ color: "white" }}>{loggedInUser}</div>
              </div>

              {/* {profileImg ? ( */}

              {loggedIn ? (
                <Popover
                  className="profile-popover"
                  placement="bottom"
                  isOpen={popOverOpen}
                  target="mypopover"
                  toggle={togglePopover}
                >
                  <PopoverBody>
                    <button className="btn btn-info" onClick={handleLogout}>
                      Logout
                    </button>
                  </PopoverBody>
                </Popover>
              ) : null}
            </Nav>
          ) : (
            <Nav className="ms-auto nav-user" navbar>
              <NavItem>
                <NavLink>
                  {" "}
                  <Link to="/login" style={{ textDecorationLine: "none" }}>
                    Login
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    to={`/login`}
                    style={{ textDecorationLine: "none" }}
                    state={{ register: true }}
                  >
                    Register
                  </Link>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
}

export default HeaderUI;
