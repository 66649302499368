import React, { Component } from "react";
import { Spinner } from "reactstrap";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import CreateFAQ from "./CreateFAQ";
import swal from "sweetalert";
import { checkRolePermissions } from "../../../../utils/Helper";
import Pagination from "react-js-pagination";

class Faq extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    edit: false,
    faqs: [],
    selectedData: {},
    spinner: false,
    updatePermission: false,
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  closeEditModal = () => {
    this.setState({ selectedData: {}, createModal: !this.state.createModal });
  };

  componentDidMount() {
    this.permissions();
    // this.getFAQs();
  }

  deleteData = (params) => {
    axiosGet(
      URL.deleteFAQs + "/" + params.id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getFAQs();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getFAQs = (pageNumber, paginated_value) => {
    let param = {
      pageNumber: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10
    }
    this.setState({ spinner: true, faqs: [] }, function () {
      axiosPost(
        URL.getFAQs, param,
        (response) => {
          if (response.status === 200) {
            this.setState({ current_page: pageNumber, faqs: response.data.data.items, spinner: false, totalItemsCount: response.data.data.totalData });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
    });
  };

  handleDelete = (id) => {
    let params = { id: id };
    displayConfirmDeleteAlert(params, this.deleteData);
  };

  handleEdit = (data) => {
    this.setState({
      selectedData: data,
      edit: true,
      createModal: !this.state.createModal,
    });
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions("delete", moduleName.faq),
        updatePermission: checkRolePermissions("update", moduleName.faq),
      },
      function () {
        this.getFAQs();
      }
    );
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getFAQs(1, valuePage);
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <>
        <div className="dashboardHeader">
          <strong>FREQUENTLY ASKED QUESTIONS</strong>
        </div>
        <div className="container h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.faq) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add FAQ
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center">S.N.</th>
                      <th>Questions</th>
                      <th>Answers</th>
                      {this.state.deletePermission ||
                        this.state.updatePermission ? (
                        <th>Actions</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.faqs?.length > 0 ? (
                      this.state.faqs?.map((faq, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center">{idx + 1}</td>
                            <td>{faq.questions}</td>
                            <td>{faq.answer}</td>
                            {this.state.deletePermission ||
                              this.state.updatePermission ? (
                              <td>
                                {this.state.updatePermission ? (
                                  <button
                                    className="btn btn-primary me-2"
                                    onClick={() => this.handleEdit(faq)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => this.handleDelete(faq.id)}
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="text-center p-3"
                          colSpan={
                            this.state.updatePermission ||
                              this.state.deletePermission
                              ? 4
                              : 3
                          }
                        >
                          {this.state.spinner ? (
                            <>
                              <Spinner></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "There are no FAQs available at the moment."
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.totalItemsCount <= 0 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getFAQs(pageNumber, paginated_value)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateFAQ
              edit={this.state.edit}
              getFAQs={this.getFAQs}
              handleCreateModal={this.handleCreateModal}
              selectedData={this.state.selectedData}
            />
          }
          modalHeader={this.state.edit ? "Update FAQ" : "Add FAQ"}
          size="lg"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default Faq;
