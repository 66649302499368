import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Container,
  UncontrolledCollapse,
  Modal,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faTimes } from "@fortawesome/free-solid-svg-icons";

class BookingDetails extends Component {
  render() {
    return (
      <div>
        <div className="gradient-block"></div>
        <Container fluid="lg" className="busTicketForm-vertical">
          <div className="busTicketForm-div">
            <Form className="busTicketForm row g-1">
              <div className="col col-12 col-md-6 col-lg-3 d-flex align-items-center justify-content-between">
                <FormGroup>
                  <Label for="origin">From</Label>
                  <Select
                    // options={this.state.cities}
                    name="origin"
                    // value={this.state.origin}
                    // onChange={(e) => this.handleOriginChange(e)}
                    placeholder="Select Origin City"
                    isClearable
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary25: "#FF9190",
                        primary: "#6C63FF",
                      },
                    })}
                    // isLoading={this.state.isLoading}
                    classNamePrefix="city"
                  />
                </FormGroup>
                <FontAwesomeIcon
                  icon={faSync}
                  id="exchangeIcon"
                  //   onClick={this.handleOnClickExchangeIcon}
                />
              </div>

              <div className="col col-12 col-md-6 col-lg-2">
                <FormGroup>
                  <Label for="destination">To</Label>
                  <Select
                    className="city"
                    // options={this.state.cities}
                    name="destination"
                    // value={this.state.destination}
                    // onChange={(e) => this.handleDestinationChange(e)}
                    placeholder="Select Destination City"
                    // isLoading={this.state.isLoading}
                    isClearable
                    classNamePrefix="city"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary25: "#FF9190",
                        primary: "#6C63FF",
                      },
                    })}
                  />
                </FormGroup>
              </div>

              <div className="col col-12 col-md-6 col-lg-2">
                <FormGroup>
                  <Label for="departureDate">Departure Date</Label>
                  <div className="d-flex">
                    <Input
                      id="busDepartureDate"
                      name="busDepartureDate"
                      type="date"
                      className="busDepartureDate"
                      //   value={this.state.busDepartureDate}
                      //   onChange={this.handleChange}
                    />

                    {/* {this.state.busDepartureDate === "" ? null : ( */}
                    <FontAwesomeIcon
                      // onClick={this.handleResetDepartureDate}
                      icon={faTimes}
                      style={{ position: "relative", right: "60", top: "12" }}
                    />
                    {/* )} */}
                  </div>
                </FormGroup>
              </div>
            </Form>
          </div>
        </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}
export default BookingDetails;
