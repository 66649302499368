import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { getRolesFromStorage } from "../../../utils/Helper";
import Pagination from "react-js-pagination";

const CanceledTickets = () => {
  const [canceledTickets, setCanceledTickets] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [paginatedValue, setPaginatedValue] = useState(10);

  const getAllcanceledTickets = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
      cancelled: true,
    }
    axiosPost(URL.viewAllTickets, param, (response) => {
      if (response) {
        setCanceledTickets(
          // response.data.data.items.filter((d) => d.cancellation_status)
          response.data.data.items
        );
        setTotalItemsCount(response.data.data.totalData)
        setCurrentPage(pageNumber)
      }
    });
  }

  useEffect(() => {
    getAllcanceledTickets();
  }, []);

  const handleClickTicket = () => { };

  const roles = getRolesFromStorage();

  const handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    setPaginatedValue(valuePage);
    getAllcanceledTickets(1, valuePage);
  };



  return (
    <div className="h-100">
      <div className="dashboardHeader">
        <strong>Canceled Tickets</strong>
      </div>
      <div className="container-fluid h-100 d-flex flex-column justify-content-between">
        <div className="row mt-2">
          <div className="col">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Bus Info</th>
                    <th>Boarding Points</th>

                    <th>Booked Seats</th>
                    <th>Total Fare</th>
                    <th>Customer Detail</th>
                    <th>Ticket Number</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {canceledTickets && canceledTickets?.length > 0 ? (
                    canceledTickets?.map((ticket, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="text-center">{idx + 1}</td>
                          <td>
                            <strong>{ticket.bus?.name}</strong>
                            <br />
                            {roles[0] === "Network Admin" && (
                              <span className="badge rounded-pill bg-info text-dark text-white">
                                {ticket.travel_agency.name}
                              </span>
                            )}
                            <br />
                            <strong>No. Plate: </strong>
                            {ticket.bus?.number_plate}
                            <br />
                            <strong>Route: </strong>{" "}
                            {/* {ticket?.origin[0]?.country_code} -{" "}
                            {ticket?.destination[0]?.country_code} */}
                          </td>
                          <td>
                            <strong> Pickup point:</strong>
                            <br />
                            {ticket?.pick_up_point?.name != null
                              ? ticket?.pick_up_point?.name
                              : "-"}
                            <br />
                            <strong>Dropping point:</strong>
                            <br />
                            {ticket?.dropping_point?.name != null
                              ? ticket?.dropping_point?.name
                              : "-"}
                          </td>
                          <td>
                            {ticket.bus_seat_booking.map((datum, idx) => {
                              return (
                                <div key={idx}>
                                  {datum.seatName}
                                  {(datum.seat_booked_by || datum.remark) &&
                                    (datum.seat_booked_by || datum.remark)}
                                </div>
                              );
                            })}
                            {ticket.remark && (
                              <div className="mt-2 border p-2">
                                <b>Remarks</b>
                                <p>{ticket.remark}</p>
                              </div>
                            )}
                          </td>
                          <td>{ticket?.fare}</td>
                          <td>
                            <strong>Name: </strong>
                            {ticket.bus_booking_customer?.full_name}
                            <br />
                            <strong>Email: </strong>
                            {ticket.bus_booking_customer?.email}
                            <br />
                            <strong>Contact: </strong>
                            {ticket.bus_booking_customer?.phone_number}
                            <br />
                          </td>
                          <td>{ticket?.Ticket_number}</td>
                          <td>
                            <button
                              className="btn btn-info"
                              onClick={() => handleClickTicket(ticket.media)}
                            >
                              View Receipt
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={9}>
                        {spinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "You have not booked any tickets yet"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

          </div>
        </div>
        {canceledTickets?.length <= 0 ? (
          <></>
        ) : (
          <div className="paginationContainer">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={paginatedValue}
              totalItemsCount={totalItemsCount}
              onChange={(pageNumber) => getAllcanceledTickets(pageNumber, paginatedValue)}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
            <div className="paginatoion_paginated_value ml-3">
              <select
                className="form-control"
                value={paginatedValue}
                onChange={(e) => handlePaginatedValueChange(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
            </ div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CanceledTickets;
