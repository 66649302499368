import { useState, useEffect } from "react";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import logo from "../../../assets/images/logo.jpg";
import { Link } from "react-router-dom";

function FooterUI({ topRoutes }) {
  // const navigate = useNavigate();
  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    setRoutes(topRoutes);
  }, [topRoutes]);
  // console.log(routes);
  return (
    <div>
      <footer className="main-footer">
        <Container className="pb-4">
          <Row className="g-3">
            <Col className="" sm="12" md="5">
              {/* <img src={logo}/> */}
              <h1>Easy Ticket Bookings</h1>

              <p>
                We pride ourselves in our fleet and continue our quest to
                provide you with clean, modern, up-to-date coach buses. Buy bus
                tickets for your next trip to experience the luxury and
                amenities. Our drivers follow strict Department of
                Transportation (DOT) guidelines so you always have superior
                quality service and an amazing experience. Tripper Bus drivers
                are second to none.
              </p>

              <div className="social">
                <a href="/">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>

                <a href="/" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>

                <a href="/" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
            </Col>

            <Col className="" xs="4" md="2">
              <h5>Quick Links</h5>

              <ul>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    Sitemap
                  </a>
                </li>
                {/* Future Implement */}
                {/* <li>
                  <a href="/" target="_blank">
                    Offers
                  </a>
                </li> */}
                {/* <li>
                  <a href="/" target="_blank">
                    Careers
                  </a>
                </li> */}
                <li>
                  <a href="/" target="_blank">
                    Values
                  </a>
                </li>
              </ul>
            </Col>

            <Col className="" xs="4" md="2">
              <h5>Info</h5>

              <ul>
                <li>
                  <a href="/terms-and-conditions">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/faq">FAQ</a>
                </li>
                <li>
                  <a href="/blogs">Blog</a>
                </li>
                {/* <li>
                  <a href="/" target="_blank">
                    User Agreement
                  </a>
                </li> */}
              </ul>
            </Col>

            <Col className="" xs="4" md="3">
              <h5>Popular Routes</h5>
              <ul>
                {topRoutes &&
                  topRoutes.length > 0 &&
                  topRoutes?.map((tRout, id) => (
                    <li key={id}>
                      {/* <Link
                        to={{
                          pathname: "/RoutesList",
                          state: {{ route:tRout }}
                        }}
                      > */}
                      <Link to="/RoutesList" state={{ route: tRout }}>
                        {`${tRout.origin.name}` +
                          " - " +
                          `${tRout.destination.name}`}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="copy-footer">
          © Copyright 2021 - Techart Trekkies Pvt. Ltd. All rights reserved
        </div>
      </footer>
    </div>
  );
}

export default FooterUI;
