import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL, moduleName } from "../../../utils/Constants";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import swal from "sweetalert";
import { fileUrl } from "../../../utils/Constants";

import {
    displayConfirmDeleteAlert,
    displayErrorAlert,
    DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";

import { checkRolePermissions } from "../../../utils/Helper";

import sampleImg from "../../../assets/images/logo.jpg";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import Pagination from "react-js-pagination";
import CreateAmenity from "./CreateAmenity";

class Amenities extends Component {
    state = {
        loader: false,
        cities: [],
        createModal: false,
        dataSpinner: false,
        deleteRoutePermission: false,
        routes: [],
        routePointModal: false,
        selectedRouteId: "",
        updateReqData: "",
        amenities: [],
        current_page: 1,
        totalItemsCount: 0,
        paginated_value: 10,
    };

    componentDidMount() {
        this.permissions();
        this.viewAmenities();
    }

    handleCreateModal = () => {
        this.setState({
            createModal: !this.state.createModal,
            edit: false,
            updateReqData: "",
        });
    };

    handleDeleteBtnClick = (e, id) => {
        let params = { id: id };
        displayConfirmDeleteAlert(params, this.handleDelete);
    };

    handleDelete = (params) => {
        this.setState({ loader: true }, () => {
            axiosGet(
                URL.deleteAmenitiesList + "/" + params.id,
                (response) => {
                    if (response.status === 200) {
                        swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
                        this.setState({ spinner: false, loader: false });
                        this.viewAmenities();
                    }
                },
                (err) => {
                    displayErrorAlert(err);
                    this.setState({ spinner: false, loader: false });
                }
            );
        });
    };

    permissions = () => {
        this.setState({
            deleteRoutePermission: checkRolePermissions("delete", moduleName.amenities),
        });
    };

    handleUpdateModal = (item) => {
        this.setState({
            createModal: !this.state.createModal,
            edit: true,
            updateReqData: item,
        });
    };

    handleUpdateBtnClick = (route) => {
        this.setState({
            editMode: true,
            routePointModal: true,
            updateReqData: route,
            selectedRouteId: route.id,
        });
    };

    viewAmenities = (pageNumber, paginated_value) => {
        let param = {
            page: pageNumber ? pageNumber : 1,
            paginated_value: paginated_value ? paginated_value : 10,
        }
        this.setState({ dataSpinner: true, routes: [] }, function () {
            axiosPost(
                URL.getAmenitiesList, param,
                (response) => {
                    if (response.status === 200) {
                        this.setState({
                            amenities: response.data.data.items,
                            totalItemsCount: response.data.data.totalData,
                            current_page: pageNumber,
                            dataSpinner: false,
                        });
                    }
                },
                (err) => {
                    displayErrorAlert(err);
                    this.setState({ dataSpinner: false });
                }
            );
        });
    }

    handlePaginatedValueChange = (value) => {
        let valuePage = parseInt(value);
        this.setState({ paginated_value: valuePage }, () => {
            this.viewAmenities(1, valuePage);
        });
    };


    render() {

        const { current_page, paginated_value, totalItemsCount } = this.state;

        return (
            <>
                {this.state.loader ? (
                    <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
                ) : null}
                <div className="dashboardHeader">
                    <strong>AMENITIES LIST</strong>
                </div>
                <div className="container-fluid h-100">
                    <div className="row mt-2">
                        <div className="col-md-2">
                            {checkRolePermissions("create", moduleName.amenities) ? (
                                <button
                                    className="btn btn-success"
                                    onClick={this.handleCreateModal}
                                >
                                    Add Amenities
                                </button>
                            ) : null}
                        </div>
                        <div className="col-md-10 d-flex align-items-center justify-content-end mr-2" >
                            <p className="text-danger">
                                Note: Items must be 4 or more to be displayed dynamically in Homepage.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-2 h-100">
                        <div className="col d-flex align-items-center justify-content-between flex-column">
                            <div className="table-responsive w-100">
                                <table className="table table-striped  text-center">
                                    <thead>
                                        <tr>
                                            <th>S.N</th>
                                            <th width="300px">Image</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            {this.state.deleteRoutePermission ? (
                                                <th width="300px">Options</th>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.amenities?.length > 0 ? (
                                            this.state.amenities?.map((amenity, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>
                                                            <img
                                                                style={{ width: "50%" }}
                                                                src={
                                                                    amenity.media?.length > 0
                                                                        ? fileUrl +
                                                                        "/" +
                                                                        amenity.media[0]?.id +
                                                                        "/" +
                                                                        amenity.media[0]?.file_name
                                                                        : sampleImg
                                                                }
                                                                alt="No preview"
                                                            />
                                                        </td>

                                                        <td>
                                                            {amenity.title}
                                                        </td>
                                                        <td>
                                                            {amenity.description}
                                                        </td>
                                                        <td>
                                                            {amenity.status == 1 ? "Active" : "Inactive"}
                                                        </td>

                                                        {this.state.deleteRoutePermission ? (
                                                            <td>
                                                                <button
                                                                    className="btn btn-info m-1"
                                                                    onClick={() => this.handleUpdateModal(amenity)}
                                                                >
                                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger m-1"
                                                                    onClick={(e) =>
                                                                        this.handleDeleteBtnClick(e, amenity.id)
                                                                    }
                                                                >
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        ) : null}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={this.state.deleteRoutePermission ? 6 : 5}
                                                    className="text-center p-3"
                                                >
                                                    {this.state.dataSpinner ? (
                                                        <>
                                                            <Spinner color="primary"></Spinner>
                                                            <br />
                                                            Loading Data...
                                                        </>
                                                    ) : (
                                                        "No Data found"
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {this.state.amenities.total <= 0 ? (
                                <></>
                            ) : (
                                <>
                                    <div className="paginationContainer">
                                        <Pagination
                                            activePage={current_page}
                                            itemsCountPerPage={paginated_value}
                                            totalItemsCount={totalItemsCount}
                                            onChange={(pageNumber) => this.viewAmenities(pageNumber, paginated_value)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            firstPageText="First"
                                            lastPageText="Last"
                                        />
                                        <div className="paginatoion_paginated_value ml-3">
                                            <select
                                                className="form-control"
                                                value={this.state.paginated_value}
                                                onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                                            >
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                                <option value={40}>40</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </ div>
                                    </div>

                                </>
                            )}
                        </div>
                    </div>

                    <CustomModal
                        modal={this.state.createModal}
                        modalBody={
                            <CreateAmenity
                                edit={this.state.edit}
                                updateReqData={this.state.updateReqData}
                                handleCreateModal={this.handleCreateModal}
                                amenity={this.state.amenities}
                                getAmenitiesList={this.viewAmenities}
                            ></CreateAmenity>

                        }
                        modalHeader={this.state.edit ? "Edit Amenities" : "Add Amenities"}
                        size="lg"
                        toggleModal={this.handleCreateModal}
                    ></CustomModal>
                </div>
            </>
        );
    }
}

export default Amenities;
