import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { fileUrl, moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { checkRolePermissions } from "../../../../utils/Helper";
import AddPaymentGateway from "./AddPaymentGateway";
import Pagination from "react-js-pagination";

class CmsPaymentGateway extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    edit: false,
    paymentGateways: [],
    selectedData: {},
    spinner: false,
    updatePermission: false,
    globalPaymentGateway: [],
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  componentDidMount() {
    this.permissions();
    this.getGlobalPaymentGateways();
  }

  deleteData = (params) => {
    axiosGet(
      URL.deletePaymentGateway + "/" + params.name,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getPaymentGateways();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getPaymentGateways = (pageNumber, paginated_value) => {
    this.setState({ spinner: true }, function () {
      let param = {
        page: pageNumber ? pageNumber : 1,
        paginated_value: paginated_value ? paginated_value : 10
      }
      axiosPost(
        URL.paymentGateway + "/get-payment-keys", param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            let gatewaydata = response.data?.data?.items;

            let newGateway = gatewaydata?.filter(
              (data) => data?.paymentKeys?.length > 0
            );

            this.setState({current_page:pageNumber, paymentGateways: gatewaydata, totalItemsCount: response.data.data.totalData });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  getGlobalPaymentGateways = (pageNumber, paginated_value) => {
    let param = {

    }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.getGlobalPaymentGateway, param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            let paymentGatewayData = response.data.data.items;
            let filteredGateways = paymentGatewayData.filter(
              (pg) => pg.is_active == 1
            );

            // let newData = filteredGateways.map((fg) => {
            //   return fg.customFields.filter((cf) => cf.is_active  == 1);
            // });

            this.setState({ globalPaymentGateway: filteredGateways });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
    });
  };

  handleDelete = (name) => {
    let params = { name: name };
    displayConfirmDeleteAlert(params, this.deleteData);
  };

  handleEdit = (data) => {
    this.setState({
      // selectedData: data,
      selectedData: cloneDeep(data),
      edit: true,
      createModal: !this.state.createModal,
    });
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions(
          "delete",
          moduleName.paymentGateway
        ),
        updatePermission: checkRolePermissions(
          "update",
          moduleName.paymentGateway
        ),
      },
      function () {
        this.getPaymentGateways();
      }
    );
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;
    return (
      <>
        <div className="dashboardHeader">
          <strong>PAYMENT GATEWAYS</strong>
        </div>
        <div className="container h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.paymentGateway) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Payment Gateway
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Payment Gateway</th>
                      <th>Image</th>
                      <th>Status</th>

                      <th>Keys</th>
                      {this.state.deletePermission ||
                        this.state.updatePermission ? (
                        <th>Actions</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.paymentGateways.length > 0 ? (
                      this.state.paymentGateways.map((gateway, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{gateway.payment_gateway}</td>
                            <td>
                              {" "}
                              <div className="blogThumbnail">
                                <img
                                  src={
                                    fileUrl +
                                    "/" +
                                    gateway?.media[0]?.id +
                                    "/" +
                                    gateway?.media[0]?.file_name
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {gateway.paymentKeys &&
                                gateway.paymentKeys.length > 0 &&
                                gateway.paymentKeys[0]?.is_active == 1
                                ? "Active"
                                : "Not Active"}
                            </td>
                            <td>
                              {gateway?.paymentKeys?.length > 0
                                ? gateway.paymentKeys.map((field, fIdx) => {
                                  return (
                                    <div key={fIdx}>
                                      <strong>{field.display_name}</strong>
                                      <div>{field.value}</div>
                                      {/* <br />
                                      {field.value}
                                      <br /> */}
                                    </div>
                                  );
                                })
                                : "No keys added"}
                            </td>
                            {this.state.deletePermission ||
                              this.state.updatePermission ? (
                              <td>
                                {this.state.updatePermission ? (
                                  <button
                                    className="btn btn-primary m-2 as"
                                    onClick={() => this.handleEdit(gateway)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      this.handleDelete(gateway.payment_gateway)
                                    }
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="text-center p-3"
                          colSpan={
                            this.state.updatePermission ||
                              this.state.deletePermission
                              ? 6
                              : 5
                          }
                        >
                          {this.state.spinner ? (
                            <>
                              <Spinner></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Payment Gateways added yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.totalItemsCount <= 0 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getFAQs(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <AddPaymentGateway
              globalPaymentGateway={this.state.globalPaymentGateway}
              edit={this.state.edit}
              selectedData={this.state.selectedData}
              getPaymentGateways={this.getPaymentGateways}
              handleCreateModal={this.handleCreateModal}
            />
          }
          modalHeader={
            this.state.edit ? "Update Payment Gateway" : "Add Payment Gateway"
          }
          size="lg"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default CmsPaymentGateway;
