import React, { Component } from "react";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div class="d-flex align-items-center justify-content-center vh-100">
          <div class="text-center">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-3">
              {" "}
              <span class="text-danger">Opps!</span> Page not found.
            </p>
            <p class="lead">The page you’re looking for doesn’t exist.</p>
            <a href="/" class="btn btn-primary text-white text-uppercase">
              Go Home
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
