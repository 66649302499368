import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosGet, axiosGetWeb, axiosPost } from "../../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { validateImage } from "../../../../utils/Helper";

export class AboutBanner extends Component {
  state = {
    title: "",
    subtitle: "",
    featuredImageFile: "",
    featuredImage: "",
    imagePreview: "",
    spinner: false,
    editMode: false,
    removeBanner:false
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (validateImage(e.target.files[0])) {
      this.setState(
        {
          featuredImage: e.target.value,
          featuredImageFile: e.target.files[0],
        },
        function () {
          let image = window.URL.createObjectURL(this.state.featuredImageFile);
          this.setState({ imagePreview: image });
        }
      );
    } else {
      e.target.value = null;
    }
  };

  removeImage = () => {
    this.setState({
      featuredImage: "",
      imagePreview: "",
      featuredImageFile: "",
      removeBanner:true
    });
  };

  setUpdateConfig = () => {
    let blog = this.props.selectedData;
    if (blog) {
      if (blog.link !== "") {
        this.setState({ hasLink: true });
      }
      this.setState({
        title: blog.title,
        subtitle: blog.subtitle,
        link: blog.link,
        description: blog.description,
        imagePreview:
          blog.media.length > 0
            ? fileUrl + "/" + blog.media[0].id + "/" + blog.media[0].file_name
            : null,
      });
    }
  };

  handleSave = () => {
    let param = {
      title: this.state.title,
      description: this.state.subtitle,
      removeMedia:this.state.removeBanner
    };
    let formData = new FormData();
    formData.append("aboutUs", JSON.stringify(param));
    formData.append("file", this.state.featuredImageFile);

    if (this.state.title === "" || this.state.featuredImageFile === "") {
      swal("Warning", "Title and image cannot be empty", "warning");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.storeAboutUs,
          formData,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };
  handleUpdate = () => {
    let param = {
      title: this.state.title,
      description: this.state.subtitle,
      removeMedia:this.state.removeBanner
    };
    let formData = new FormData();
    formData.append("aboutUs", JSON.stringify(param));
    if (this.state.featuredImageFile !== "") {
      formData.append("file", this.state.featuredImageFile);
    }

    if (this.state.title === "") {
      swal("Warning", "Title cannot be empty", "warning");
    } else {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.updateAboutUs,
          formData,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.createSuccess, "success");
              this.getAboutUsData();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  getAboutUsData = () => {
    axiosGet(URL.getAuthAboutUs, (response) => {
      let resData = response.data?.data?.item;
      if (response.status === 200) {
        this.setState({
          editMode: resData?.id ? true : false,
          title: resData?.title,
          subtitle: resData?.description,
          imagePreview: resData?.media[0]?.id
            ? fileUrl +
              "/" +
              resData?.media[0].id +
              "/" +
              resData?.media[0].file_name
            : "",
        });
      }
    });
  };

  componentDidMount = () => {
    this.getAboutUsData();
  };

  render() {
    return (
      <div>
        <div className="dashboardHeader">
          <strong>About Banner</strong>
        </div>
        <div className="container cms-blog mt-4">
          {this.state.spinner ? (
            <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
          ) : null}
          <strong>Title</strong>
          <input
            className="form-control"
            name="title"
            onChange={this.handleChange}
            placeholder="Enter your title here"
            type="text"
            value={this.state.title}
          />
          <div className="mt-2">
            <strong>Description</strong>
            <input
              className="form-control"
              name="subtitle"
              onChange={this.handleChange}
              placeholder="Enter your subtitle here"
              type="text"
              value={this.state.subtitle}
            />
          </div>

          <div className="mt-2">
            <strong>Featured Image</strong>
            <div className="row">
              <div className="col">
                {this.state.edit &&
                this.state.imagePreview &&
                this.state.featuredImage === "" ? (
                  <input
                    className="form-control"
                    type="text"
                    value={this.state.incommingData.media[0].name}
                    disabled
                  />
                ) : (
                  <input
                    accept="image/*"
                    className="form-control"
                    name="feaeturedImage"
                    onChange={this.handleImageChange}
                    type="file"
                    value={this.state.featuredImage}
                  />
                )}
              </div>
              {this.state.imagePreview !== "" ? (
                <div className="col-md-auto">
                  <button
                    className="btn btn-danger ml-3"
                    onClick={this.removeImage}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {this.state.imagePreview !== "" ? (
            <div className="row mt-2 mb-2">
              <div className="col text-center">
                <div
                  className="imageHolder"
                  style={{ border: "1px solid #ccc" }}
                >
                  <img
                    src={this.state.imagePreview}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="row mt-3">
            <div className="col text-end">
              {this.state.editMode ? (
                <button className="btn btn-primary" onClick={this.handleUpdate}>
                  Update
                </button>
              ) : (
                <button className="btn btn-primary" onClick={this.handleSave}>
                  Create
                </button>
              )}

              {/* <button
                className="btn btn-danger ms-2"
                onClick={this.handleCancel}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutBanner;
