import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import { fileUrl } from "../../utils/Constants";

function BusImagesModal(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    manageData();
  }, []);

  const manageData = () => {
    let data = props.mediaData,
      newData = [];
    data.forEach((el) => {
      let src = fileUrl + "/" + el.id + "/" + el.file_name;
      newData.push(src);
    });
    setNewData(newData);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <div className="busFullImageHolder d-flex align-items-center justify-content-center">
        {newData.map((src, index) => (
          <div key={index}>
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              width="300"
              style={{ margin: "2px" }}
              alt="ind"
            />
          </div>
        ))}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={newData}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
}

export default BusImagesModal;
