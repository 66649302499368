import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import {
  checkRolePermissions,
  getRolesFromStorage,
} from "../../../../utils/Helper";
import BusRouteFilter from "./BusRouteFilter";
import CreateBusRoute from "./CreateBusRoute";
import Pagination from "react-js-pagination";
import moment from "moment";

class BusRoutes extends Component {
  state = {
    buses: [],
    busRoutes: [],
    createModal: false,
    editMode: false,
    spinner: false,
    routes: [],
    userRoutes: [],
    updateReqData: [],
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  componentDidMount() {
    this.getBuses();

    const roles = getRolesFromStorage();

    if (roles[0] === "Network Admin") {
      this.getNetworkRoutes();
    } else {
      this.getTravelAgencyRoutes();
    }

    this.getBusRoutes();
    this.getBusRouteAuthUser();
  }

  getBuses = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10
    }
    axiosPost(
      URL.buses + "/get-buses", param,
      (response) => {
        if (response.status === 200) {
          this.setState({
            buses: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getBusRoutes = () => {
    this.setState({ spinner: true, busRoutes: [] }, function () {
      axiosGet(
        URL.busRoutePoints,
        (response) => {
          if (response.status === 200) {
            this.setState({
              busRoutes: response.data.data.items,
              spinner: false,
            });
          }
        },
        (err) => {
          this.setState({ spinner: false });
        }
      );
    });
  };

  getBusRouteAuthUser = (pageNumber, paginated_value) => {
    let param = {
      pageNumber: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10
    }
    this.setState({ spinner: true, userRoutes: [] }, function () {
      axiosPost(
        URL.getBusRouteAuthUser, param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              current_page: pageNumber,
              userRoutes: response.data.data.items
              // .filter(
              //   (ur) => ur.routes.length > 0
              // )
              ,

              totalItemsCount: response.data.data.totalData,
              spinner: false,
            });
          }
        },
        (err) => {
          this.setState({ spinner: false });
        }
      );
    });
  };

  getTravelAgencyRoutes = () => {
    axiosPost(
      URL.travelAgencyRoutes, {},
      (response) => {
        if (response.status === 200) {
          this.setState({
            routes: response.data.data.items,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getNetworkRoutes = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
    }
    axiosPost(
      URL.routes + "/" + "get-routes", param,
      (response) => {
        if (response.status === 200) {
          this.setState({
            routes: response.data.data.items,
            current_page: pageNumber,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      editMode: false,
      updateReqData: "",
    });
  };

  handleUpdateBtnClick = (routePoint, bus_id, bus_name) => {
    let updateData = this.state.routes.find(
      (item) => item.id === routePoint.id
    );

    routePoint = {
      ...routePoint,
      updateData,
      bus_id: bus_id,
      bus_name: bus_name,
      arrival_time: new Date(routePoint.arrival_time),
      departure_time: new Date(routePoint.departure_time),
    };

    this.setState({
      editMode: true,
      createModal: true,
      updateReqData: cloneDeep(routePoint),
    });
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosPost(
        URL.deleteBusRoute,
        params,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
            this.getBusRouteAuthUser();

            this.setState({
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ dataSpinner: false });
          swal("Error", err.response.data.message, "error");
        }
      );
    });
  };

  handleDeleteBtnClick = (e, route_id, bus_id) => {
    let params = { route_id: route_id, bus_id: bus_id };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  calculateBusNameSpan = (idx) => {
    let routes = [...this.state.userRoutes];
    let data = 0;

    routes[idx].routes.forEach((item) => {
      let routePointLength = item.route_point?.length;
      if (routePointLength == 0) {
        routePointLength = 1;
      }
      data = data + routePointLength;
    });
    if (data == 0) {
      data = 1;
    }
    return data;
  };

  calculateRouteNameSpan = (idx, rIdx) => {
    let userRoutes = [...this.state.userRoutes];
    let data = userRoutes[idx].routes[rIdx]?.route_point?.length;
    if (data == 0) {
      data = 1;
    }

    return data;
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.getBusRouteAuthUser(1, valuePage);
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;
    const roles = getRolesFromStorage();

    return (
      <>
        <div className="dashboardHeader">
          <strong>BUS ROUTES</strong>
        </div>
        <div className="container-fluid h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.busRoute) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Bus Route
                </button>
              ) : null}
            </div>
          </div>

          {/* <BusRouteFilter /> */}
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th width="50px">S.N</th>
                      <th>Bus Name</th>
                      <th>Route</th>
                      <th>Total Fare</th>
                      <th width="200px">Route-points</th>
                      <th width="120px">Time</th>
                      <th width="100px">Fare</th>
                      <th>Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.userRoutes &&
                      this.state.userRoutes?.length > 0 ? (
                      this.state.userRoutes?.map((route, idx) => {
                        return route?.routes?.length > 0 ? (
                          <>
                            <tr key={idx}>
                              <td rowSpan={this.calculateBusNameSpan(idx)}>
                                {idx + 1}
                              </td>
                              <td rowSpan={this.calculateBusNameSpan(idx)}>
                                {route.name}
                                <br />
                                {roles[0] === "Network Admin" && (
                                  <span className="badge rounded-pill bg-info text-dark text-white">
                                    {route.travel_agency.name}
                                  </span>
                                )}
                              </td>
                              {route.routes.length > 0 ? (
                                route.routes.map((item, rIdx) => {
                                  if (rIdx == 0) {
                                    return (
                                      <>
                                        <td
                                          rowSpan={this.calculateRouteNameSpan(
                                            idx,
                                            rIdx
                                          )}
                                        >
                                          {item.origin_name} -{" "}
                                          {item.destination_name}
                                        </td>

                                        <td
                                          rowSpan={this.calculateRouteNameSpan(
                                            idx,
                                            rIdx
                                          )}
                                        >
                                          {item.fare}
                                        </td>
                                        {item.route_point.length > 0 ? (
                                          <>
                                            <td rowSpan={1}>
                                              {item.route_point[0].name}
                                              <br />
                                              {item.route_point[0]
                                                .is_boarding_point ? (
                                                <span className="badge rounded-pill bg-primary text-white badge-sm m-1">
                                                  Boarding
                                                </span>
                                              ) : (
                                                ""
                                              )}

                                              {item.route_point[0]
                                                .is_dropping_point ? (
                                                <span className="badge rounded-pill bg-warning text-white badge-sm m-1">
                                                  Dropping
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td rowSpan={1}>
                                              {moment(
                                                item.route_point[0].time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                            </td>
                                            <td rowSpan={1}>
                                              {item.route_point[0].fare}
                                            </td>
                                          </>
                                        ) : (
                                          <td colSpan={3}></td>
                                        )}

                                        <td
                                          rowSpan={this.calculateRouteNameSpan(
                                            idx,
                                            rIdx
                                          )}
                                        >
                                          <button
                                            className="btn btn-danger m-2"
                                            onClick={(e) =>
                                              this.handleDeleteBtnClick(
                                                e,
                                                item.id,
                                                route.id
                                              )
                                            }
                                          >
                                            <i class="fa-solid fa-trash"></i>
                                          </button>
                                          <button
                                            className="btn btn-info"
                                            onClick={() =>
                                              this.handleUpdateBtnClick(
                                                item,
                                                route.id,
                                                route.name
                                              )
                                            }
                                          >
                                            <i class="fa-solid fa-pen-to-square"></i>
                                          </button>
                                        </td>
                                      </>
                                    );
                                  }
                                })
                              ) : (
                                <td colSpan={5}>
                                  No route and route points set
                                </td>
                              )}
                            </tr>

                            {route.routes.length > 0
                              ? route.routes.map((route, rIdx) => {
                                if (rIdx == 0) {
                                  return route.route_point.map(
                                    (item, rpIdx) => {
                                      if (rpIdx > 0) {
                                        return (
                                          <tr>
                                            <td rowSpan={1}>
                                              {item.name}
                                              <br />
                                              {item.is_boarding_point ? (
                                                <span className="badge  rounded-pill bg-primary text-white badge-sm m-1">
                                                  Boarding
                                                </span>
                                              ) : (
                                                ""
                                              )}

                                              {item.is_dropping_point ? (
                                                <span className="badge  rounded-pill bg-warning text-white badge-sm m-1">
                                                  Dropping
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td rowSpan={1}>
                                              {moment(
                                                item.time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                            </td>
                                            <td rowSpan={1}>{item.fare}</td>
                                          </tr>
                                        );
                                      }
                                    }
                                  );
                                }
                              })
                              : null}

                            {route.routes.length > 0
                              ? route.routes.map((item, rIdx) => {
                                if (rIdx > 0) {
                                  return (
                                    <>
                                      <tr>
                                        <td
                                          rowSpan={this.calculateRouteNameSpan(
                                            idx,
                                            rIdx
                                          )}
                                        >
                                          {item.origin_name} -{" "}
                                          {item.destination_name}
                                        </td>

                                        <td
                                          rowSpan={this.calculateRouteNameSpan(
                                            idx,
                                            rIdx
                                          )}
                                        >
                                          {item.fare}
                                        </td>
                                        {item.route_point.length > 0 ? (
                                          <>
                                            <td rowSpan={1}>
                                              {item.route_point[0].name}
                                              <br />
                                              {item.route_point[0]
                                                .is_boarding_point ? (
                                                <span className="badge rounded-pill bg-primary text-white badge-sm m-1">
                                                  Boarding
                                                </span>
                                              ) : (
                                                ""
                                              )}

                                              {item.route_point[0]
                                                .is_dropping_point ? (
                                                <span className="badge rounded-pill bg-warning text-white badge-sm m-1">
                                                  Dropping
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td rowSpan={1}>
                                              {moment(
                                                item.route_point[0].time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                            </td>
                                            <td rowSpan={1}>
                                              {item.route_point[0].fare}
                                            </td>
                                          </>
                                        ) : (
                                          <td colSpan={1}>No route Point</td>
                                        )}
                                        <td
                                          rowSpan={this.calculateRouteNameSpan(
                                            idx,
                                            rIdx
                                          )}
                                        >
                                          <button
                                            className="btn btn-danger m-2"
                                            onClick={(e) =>
                                              this.handleDeleteBtnClick(
                                                e,
                                                item.id,
                                                route.id
                                              )
                                            }
                                          >
                                            <i class="fa-solid fa-trash"></i>
                                          </button>
                                          <button
                                            className="btn btn-info"
                                            onClick={() =>
                                              this.handleUpdateBtnClick(
                                                item,
                                                route.id,
                                                route.name
                                              )
                                            }
                                          >
                                            <i class="fa-solid fa-pen-to-square"></i>
                                          </button>
                                        </td>
                                      </tr>
                                      {item.route_point.length > 1
                                        ? item.route_point.map(
                                          (rp, rpIdx) => {
                                            if (rpIdx > 0) {
                                              return (
                                                <tr>
                                                  <td rowSpan={1}>
                                                    {rp.name}
                                                    <br />
                                                    {rp.is_boarding_point ? (
                                                      <span className="badge rounded-pill bg-primary text-white badge-sm m-1">
                                                        Boarding
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {rp.is_dropping_point ? (
                                                      <span className="badge rounded-pill bg-warning text-white badge-sm m-1">
                                                        Dropping
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td rowSpan={1}>
                                                    {moment(
                                                      rp.time,
                                                      "HH:mm:ss"
                                                    ).format("hh:mm A")}
                                                  </td>
                                                  <td rowSpan={1}>
                                                    {rp.fare}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          }
                                        )
                                        : null}
                                    </>
                                  );
                                }
                              })
                              : null}
                          </>
                        ) : null;
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={7}>
                          {this.state.spinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br></br>
                              Loading Data...
                            </>
                          ) : (
                            "No bus Routes Available"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.userRoutes.total <= 0 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) =>
                      this.getBusRouteAuthUser(pageNumber, paginated_value)
                    }
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              )}
            </div>
          </div>
        </div>

        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateBusRoute
              editMode={this.state.editMode}
              buses={this.state.buses}
              handleCreateModal={this.handleCreateModal}
              routes={this.state.routes}
              getBuses={this.getBuses}
              getBusRoutes={this.getBusRoutes}
              getAuthRoute={this.getBusRouteAuthUser}
              updateReqData={this.state.updateReqData}
            />
          }
          modalHeader={this.state.editMode ? "Edit Bus Route" : "Add Bus Route"}
          size="lg"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default BusRoutes;
