import React from "react";
import ReusableComponent from "./ReusableComponent";

const ConfirmDetails = ({
  formData,
  handleStepChangeFromCD,
  selectedRoute,
  selectedSeats,
  chosenBoardingPointId,
  chosenDroppingPointId,
  handleChangeManualDetails,
  handleBusBooking,
}) => {
  return (
    <>
      <ReusableComponent
        header="Confirm Detail"
        flag={true}
        formData1={formData}
        handleStepChangeFromCD={handleStepChangeFromCD}
        selectedRoute={selectedRoute}
        selectedSeats={selectedSeats}
        chosenDroppingPointId={chosenDroppingPointId}
        chosenBoardingPointId={chosenBoardingPointId}
        handleChangeManualDetails={(e) => handleChangeManualDetails(e)}
        handleBusBooking={handleBusBooking}
      />
    </>
  );
};

export default ConfirmDetails;
