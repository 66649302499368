import moment from "moment";
import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { getRolesFromStorage } from "../../../../utils/Helper";

class CreateBusRoute extends Component {
  state = {
    busId: "",
    loader: false,
    routeId: "",
    routePoints: [],
    sameFareToggle: false,
    arrivalTime: "",
    departureTime: "",
    fare: "",
    duration: "",
  };

  addRoutePointField = () => {
    const newRoutePoints = [...this.state.routePoints];
    newRoutePoints.push({
      name: "",
      time: "",
      fare: 0,
      is_boarding_point: false,
      is_dropping_point: false,
    });

    if (this.state.sameFareToggle) {
      newRoutePoints.forEach((rp) => {
        rp.fare = this.state.fare;
      });
    }
    this.setState({ routePoints: newRoutePoints });
  };

  removeRoutePointField = (idx) => {
    const newRoutePoints = [...this.state.routePoints];
    newRoutePoints.splice(idx, 1);
    this.setState({ routePoints: newRoutePoints });
  };

  handleCancel = () => {
    this.setState({
      busId: "",
      routeId: "",
      routePoints: [],
      arrivalTime: "",
      departureTime: "",
      fare: "",
      sameFareToggle: false,
    });
    this.props.handleCreateModal();
  };

  handleChange = (e) => {
    let routePoints = [...this.state.routePoints];

    let { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name === "fare") {
        if (this.state.sameFareToggle) {
          routePoints.forEach((r) => {
            r.fare = value;
          });
          this.setState({ routePoints });
        }
      }

      if (name === "busId") {
        this.setState({
          routePoints: [],
          routeId: "",
        });
      }

      if (name === "routeId") {
        routePoints = [];

        let tAId = this.props.buses.find((b) => b.id == this.state.busId);

        let params = {
          travelAgencyId: tAId.travel_agency_id,
          routeId: parseInt(value),
        };

        axiosPost(URL.getRoutePointByTravelAgency, params, (response) => {
          if (response.status === 200) {
            let res = response.data.data.items;

            res.length > 0 &&
              res.forEach((rp) => {
                rp.time = "";
                rp.fare = 0;
              });

            this.setState({
              routePoints: res,
            });
          }
        });

        // routes.forEach((el) => {
        //   if (el.id === parseInt(value)) {
        //     el.route_points.forEach((rp) => {
        //       rp.time = "";
        //       rp.fare = 0;
        //     });
        //     routePoints = el.route_points;
        //   }
        // });
        // this.setState({ routePoints });
      }
    });
  };

  handleRoutePointDataChange = (e, idx) => {
    let routePoints = [...this.state.routePoints];
    const { name, value, checked } = e.target;
    if (name === "is_dropping_point" || e.target.name === "is_boarding_point") {
      routePoints[idx][e.target.name] = checked;
    } else if (name === "fare") {
      if (this.state.sameFareToggle) {
        routePoints.map((r) => {
          r.fare = value;
        });
      } else {
        routePoints[idx].fare = value;
      }
    } else {
      routePoints[idx][name] = value;
    }

    this.setState({
      routePoints,
    });
  };

  handleSave = () => {
    let rp = [];
    let errChk = 0;

    if (this.state.routePoints.length > 0) {
      this.state.routePoints.forEach((el) => {
        rp.push({
          route_route_point_id: el.id,
          name: el.name,
          time: el.time,
          fare: el.fare,
          is_boarding_point: el.is_boarding_point ? 1 : 0,
          is_dropping_point: el.is_dropping_point ? 1 : 0,
        });

        if (el.name === "" || el.time === "" || el.fare === "") {
          errChk++;
        }
      });
    }

    if (
      this.state.busId === "" ||
      this.state.routeId === "" ||
      this.state.fare === "" ||
      this.state.departureTime === "" ||
      this.state.arrivalTime === "" ||
      errChk > 0
    ) {
      swal("Error", "Fill all the fields", "error");
      return false;
    }
    if (this.state.routePoints.length == 0) {
      swal("Error", "No Route Points are available", "error");
      return false;
    }

    let param = {
      bus_id: this.state.busId,
      route_id: this.state.routeId,
      route_points: rp,
      departure_time: moment(this.state.departureTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      arrival_time: moment(this.state.arrivalTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      fare: this.state.fare,
      duration: this.state.duration,
    };

    this.setState({ loader: true }, function () {
      axiosPost(
        URL.busRoutePoints,
        param,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
            this.props.getAuthRoute();
            this.props.getBusRoutes();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleUpdate = () => {
    let rp = [];

    let errChk = 0;

    if (this.state.routePoints.length > 0) {
      this.state.routePoints.forEach((el) => {
        rp.push({
          route_route_point_id: el.id,
          time: el.time,
          fare: el.fare,
          is_boarding_point: el.is_boarding_point ? 1 : 0,
          is_dropping_point: el.is_dropping_point ? 1 : 0,
        });

        if (
          el.route_route_point_id === "" ||
          el.time === "" ||
          el.fare === ""
        ) {
          errChk++;
        }
      });
    }
    if (
      this.state.busId === "" ||
      this.state.routeId === "" ||
      this.state.fare === "" ||
      this.state.departureTime === "" ||
      this.state.arrivalTime === "" ||
      errChk > 0
    ) {
      swal("Error", "Fill all the fields", "error");
      return false;
    }
    if (this.state.routePoints.length == 0) {
      swal("Error", "No Route Points are available", "error");
      return false;
    }

    let param = {
      bus_id: this.state.busId,
      route_id: this.state.routeId,
      route_points: rp,
      fare: this.state.fare,
      departure_time: moment(this.state.departureTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      arrival_time: moment(this.state.arrivalTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      duration: this.state.duration,
    };
    this.setState({ loader: true }, function () {
      axiosPost(
        URL.updateBusRoutePoints,
        param,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.props.getAuthRoute();
            this.props.getBusRoutes();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleSameFare = (e) => {
    this.setState({
      sameFareToggle: e.target.checked,
    });
    let routePoints = [...this.state.routePoints];

    routePoints.forEach((r) => {
      r.fare = this.state.fare;
    });
    this.setState({ routePoints });
  };

  handleDateChange = (fieldName, date) => {
    if (fieldName === "departureTime") {
      this.setState(
        {
          departureTime: date,
        },
        function () {
          this.durationDifference();
        }
      );
    } else if (fieldName === "arrivalTime") {
      this.setState(
        {
          arrivalTime: date,
        },
        function () {
          this.durationDifference();
        }
      );
    }
  };

  durationDifference = () => {
    if (this.state.departureTime !== "" && this.state.arrivalTime !== "") {
      var departure = moment(this.state.departureTime, "DD-MM-YYYY");
      var arrival = moment(this.state.arrivalTime, "DD-MM-YYYY");
      let time = arrival.diff(departure, "hours");

      this.setState({
        duration: time,
      });
    }
  };

  updateConfig = () => {
    this.setState({
      busId: this.props.updateReqData.bus_id,

      routeId: this.props.updateReqData.id,

      routePoints: this.props.updateReqData.route_point,

      arrivalTime: this.props.updateReqData.arrival_time,
      departureTime: this.props.updateReqData.departure_time,
      fare: this.props.updateReqData.fare,
      duration: this.props.updateReqData.duration,
    });
  };

  componentDidMount() {
    if (this.props.updateReqData) {
      this.updateConfig();
    }
  }

  render() {
    const busesData = this.props.buses;
    const groupedBuses = busesData.reduce((acc, bus) => {
      const key = bus.travel_agency_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(bus);
      return acc;
    }, {});

    const roles = getRolesFromStorage();

    return (
      <div className="container-fluid">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row mb-4">
          {/* <div className="col-md-2 text-end" style={{ marginTop: "6px" }}>
          <strong>Select Bus</strong>
          </div> */}
          <div className="col-md-4">
            {this.props.editMode ? (
              <strong>Bus</strong>
            ) : (
              <strong>Select Bus
                <span className="text-danger"> *</span>
              </strong>
            )}
            <select
              name="busId"
              value={this.state.busId}
              className="form-select"
              onChange={this.handleChange}
              disabled={this.props.editMode}
            >
              <option value="" disabled selected>
                Choose Bus
              </option>
              {this.props.editMode ? (
                <option>{this.props.updateReqData.bus_name}</option>
              ) : this.props.buses?.length > 0 ? (
                roles[0] === "Network Admin" ? (
                  Object.entries(groupedBuses).map(([key, buses]) => (
                    <optgroup label={buses[0].travel_agency.name}>
                      {buses.map((bus, idx) => (
                        <option value={bus.id} key={idx}>
                          {bus.name}
                        </option>
                      ))}
                    </optgroup>
                  ))
                ) : (
                  this.props.buses.map((bus, idx) => (
                    <option value={bus.id} key={idx}>
                      {bus.name}
                    </option>
                  ))
                )
              ) : null}
            </select>
          </div>
          {/* <div className="col-md-2 text-end" style={{ marginTop: "6px" }}>
          <strong>Select Route</strong>
          </div> */}

          <div className="col-md-4">
            {this.props.editMode ? (
              <strong> Route</strong>
            ) : (
              <strong>Select Route
                <span className="text-danger"> *</span>
              </strong>
            )}
            <select
              name="routeId"
              value={this.state.routeId}
              className="form-select"
              onChange={this.handleChange}
              disabled={this.props.editMode || this.state.busId === ""}
            >
              <option value="" disabled selected>
                Choose Route
              </option>
              {this.props.routes.length > 0
                ? this.props.routes.map((route, idx) =>
                  this.props.editMode ? (
                    <option>
                      {this.props.updateReqData.origin_name} -{" "}
                      {this.props.updateReqData.destination_name}
                    </option>
                  ) : (
                    // route.route_points.length > 0 && (
                    <option value={route.id} key={idx}>
                      {route.origin_city.name} - {route.destination_city.name}
                    </option>
                    // )
                  )
                )
                : null}
            </select>
          </div>

          <div className="col-md-3">
            {this.props.editMode ? (
              <strong>Fare
                <span className="text-danger"> *</span>
              </strong>
            ) : (
              <strong>Set Fare
                <span className="text-danger"> *</span>
              </strong>
            )}
            <input
              type="number"
              className="form-control"
              min={0}
              name="fare"
              value={this.state.fare}
              onChange={this.handleChange}
            />
            <input
              className="m-2"
              data-toggle="tooltip"
              data-placement="top"
              title="The checked checkbox will set same fare value to all the route points"
              type="checkbox"
              checked={this.state.sameFareToggle}
              onClick={this.handleSameFare}
            />
            <span style={{ fontSize: 14 }}>Same fare </span>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mb-4  ">
          <div className="col-md-4">
            {this.props.editMode ? (
              <strong>Departure Time
                <span className="text-danger"> *</span>
              </strong>
            ) : (
              <strong>Set Departure time
                <span className="text-danger"> *</span>
              </strong>
            )}
            <ReactDatePicker
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              // todayButton="Today"
              minDate={new Date()}
              className="datepick form-control"
              name="departureTime"
              isClearable
              showMonthDropdown
              showYearDropdown
              shouldCloseOnSelect={false}
              placeholderText="Set departure time"
              selected={this.state.departureTime}
              onChange={(date) => this.handleDateChange("departureTime", date)}
            />
          </div>

          <div className="col-md-4">
            {this.props.editMode ? (
              <strong>Arrival Time
                <span className="text-danger"> *</span>
              </strong>
            ) : (
              <strong>Set Arrival time
                <span className="text-danger"> *</span>
              </strong>
            )}
            <ReactDatePicker
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              // todayButton="Today"
              minDate={
                this.state.departureTime ? this.state.departureTime : new Date()
              }
              className="datepick form-control"
              name="arrivalTime"
              isClearable
              showMonthDropdown
              showYearDropdown
              shouldCloseOnSelect={false}
              placeholderText="Set arrival time"
              selected={this.state.arrivalTime}
              onChange={(date) => this.handleDateChange("arrivalTime", date)}
            />
          </div>
        </div>
        {this.state.routeId !== "" ? (
          <div className="row mt-2">
            <div className="col">
              <div className="routePointHeader">
                <strong>Route Points</strong>
              </div>
              <div className="routePointHolder">
                <table className="table table-striped table-bordered mb-0 text-center">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Route Point
                        <span className="text-danger"> *</span>
                      </th>

                      <th>Time
                        <span className="text-danger"> *</span>
                      </th>

                      <th>Fare
                        <span className="text-danger"> *</span>
                      </th>

                      {!this.props.editMode && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.routePoints.length > 0 ? (
                      this.state.routePoints.map((rp, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={rp.name}
                                disabled={this.props.editMode}
                                onChange={(e) =>
                                  this.handleRoutePointDataChange(e, idx)
                                }
                              />
                              <div className="d-flex justify-content-around mt-2">
                                <div>
                                  <input
                                    name="is_boarding_point"
                                    type="checkbox"
                                    checked={
                                      rp.is_boarding_point ? true : false
                                    }
                                    onChange={(e) =>
                                      this.handleRoutePointDataChange(e, idx)
                                    }
                                  />
                                  <br />
                                  <span style={{ fontSize: "11px" }}>
                                    Boarding point
                                  </span>
                                </div>

                                <div>
                                  <input
                                    name="is_dropping_point"
                                    type="checkbox"
                                    checked={
                                      rp.is_dropping_point ? true : false
                                    }
                                    onChange={(e) =>
                                      this.handleRoutePointDataChange(e, idx)
                                    }
                                  />
                                  <br />
                                  <span style={{ fontSize: "11px" }}>
                                    {" "}
                                    Dropping point
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input
                                type="time"
                                name="time"
                                className="form-control"
                                value={rp.time}
                                onChange={(e) =>
                                  this.handleRoutePointDataChange(e, idx)
                                }
                              />
                            </td>
                            <td>
                              <input
                                disabled={this.state.sameFareToggle}
                                type="number"
                                className="form-control"
                                min={0}
                                name="fare"
                                value={rp.fare}
                                onChange={(e) =>
                                  this.handleRoutePointDataChange(e, idx)
                                }
                              />
                            </td>
                            {!this.props.editMode && (
                              <td>
                                <button
                                  className="btn btn-primary m-1"
                                  onClick={this.addRoutePointField}
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                                {idx !== 0 && (
                                  <button
                                    className="btn btn-danger m-1"
                                    onClick={() =>
                                      this.removeRoutePointField(idx)
                                    }
                                  >
                                    <i class="fa-solid fa-minus"></i>
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          Route points not available for this route and bus{" "}
                          <button
                            className="btn btn-primary m-1"
                            onClick={this.addRoutePointField}
                          >
                            Add route points
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row mt-3">
          <div className="col text-end">
            {this.props.editMode ? (
              <button
                className="btn btn-primary me-3"
                onClick={this.handleUpdate}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary me-3"
                onClick={this.handleSave}
              >
                Save
              </button>
            )}
            <button className="btn btn-danger" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBusRoute;
