import React, { useState } from "react";
import swal from "sweetalert";
import { axiosPost, axiosPostWeb } from "../../../utils/AxiosApi";
import {
  cryptoSecretKey,
  loginUrl,
  registerURL,
} from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import history from "../../../utils/History";
import { useNavigate } from "react-router-dom";
import SignupUI from "./SignupUI";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import { emailValidate } from "../../../utils/validation";

function Signup(props) {
  const navigate = useNavigate();

  // const { location } = this.props;
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mname, setMname] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const handleRegister = () => {
    if (
      email !== "" &&
      fname !== "" &&
      lname !== "" &&
      address !== "" &&
      phone !== ""
    ) {
      props.registerProcessStart();
      let params = {
        first_name: fname,
        middle_name: mname,
        last_name: lname,
        address: address,
        email: email,
        phone_number: phone,
      };
      if (emailValidate(email)) {
        axiosPostWeb(
          registerURL,
          params,
          (response) => {
            if (response.status === 200) {
              swal(
                "Success",
                "Registration Successful. Proceed to login.",
                "success"
              );
              props.registerProcessComplete();

              // navigate(`${process.env.PUBLIC_URL}/login`);

              props.goToLogin();
            }
          },
          (err) => {
            displayErrorAlert(err);
            props.registerProcessComplete();
          }
        );
      }
    } else {
      swal("Error", "Please fill all the fields", "error");
      return;
    }
  };

  const resetState = () => {
    setEmail("");
    setAddress("");
    setFname("");
    setMname("");
    setLname("");
    setPhone("");
  };
  const handleCancel = () => {
    resetState();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleFNameChange = (e) => {
    setFname(e.target.value);
  };
  const handleMNameChange = (e) => {
    setMname(e.target.value);
  };
  const handleLNameChange = (e) => {
    setLname(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <>
      <SignupUI
        email={email}
        fname={fname}
        mname={mname}
        lname={lname}
        phone={phone}
        address={address}
        handleCancel={handleCancel}
        handleEmailChange={handleEmailChange}
        handleAddressChange={handleAddressChange}
        handleFNameChange={handleFNameChange}
        handleMNameChange={handleMNameChange}
        handleLNameChange={handleLNameChange}
        handlePhoneChange={handlePhoneChange}
        handleRegister={handleRegister}
        goToHomePage={props.goToHomePage}
        // goToLogin={props.goToLogin}
      />
    </>
  );
}

export default Signup;
