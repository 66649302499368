import React from "react";
import booked from "../../assets/images/booked.svg";
import empty from "../../assets/images/empty.svg";
import driver from "../../assets/images/driver.svg";
import available from "../../assets/images/available.svg";
import selected from "../../assets/images/selected.svg";
import hover from "../../assets/images/hover.svg";

function BusSeatUI(props) {
  return (
    <div className="row">
      <div className="col text-center">
        {props.config && !props.view ? (
          <>
            <h2>PREVIEW</h2>
            <hr></hr>
          </>
        ) : null}
        <div className="vechile-container2">
          <div id="main_layer">
            <div id="bus">
              <div id="bus_body" class="cls-2">
                <div className="seat-container">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      {props.seats && props.seats.length > 0
                        ? props.seats.map((seat) => {
                            return (
                              <tr>
                                {seat.columns.map((column, idx) => {
                                  return (
                                    <td className="p-0" key={idx}>
                                      {column.is_driver ? (
                                        <div className="seat-item">
                                          <img
                                            src={driver}
                                            className="driver"
                                          />
                                        </div>
                                      ) : column.is_empty ? (
                                        <div className="seat-item">
                                          <img src={empty} className="empty" />
                                        </div>
                                      ) : column.is_open_for_booking ? (
                                        <div
                                          className="seat-item"
                                          onClick={
                                            props.config
                                              ? null
                                              : this.handleOnClickcolumn
                                          }
                                        >
                                          <img
                                            src={available}
                                            className="available"
                                          />
                                          <span className="seat-id">
                                            {/* {seat.row} */}
                                            {column.columnName}
                                          </span>
                                        </div>
                                      ) : !column.is_open_for_booking ? (
                                        <div className="seat-item">
                                          <img
                                            src={booked}
                                            className="booked"
                                          />
                                          <span className="seat-id">
                                            {/* {seat.row} */}
                                            {column.columnName}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="seat-item">
                                          <img src={empty} className="empty" />
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div id="mirrors">
              <div id="mirror" class="cls-1" />
              <div id="mirror-2" class="cls-1" />
            </div>
            <div id="deck">
              <div id="deck-2" class="cls-3" />
              <div id="driverdeck" class="cls-4" />
              <div id="steer-box" class="cls-5">
                <div id="steer">
                  <div id="steer_b">
                    <div id="steer_c">
                      <div id="steer_d"></div>
                      <div id="beep"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="horn"></div>
            </div>
            <div id="wheel-box">
              <div id="wheel" class="cls-1" />
              <div id="wheel-2" class="cls-1" />
              <div id="wheel-3" class="cls-1" />
              <div id="wheel-4" class="cls-1" />
            </div>
          </div>
        </div>
        {props.seats.length > 0 ? (
          <div className="busLabels">
            <div>
              <img src={booked} width="50px" />
              {props.config ? (
                <strong>Unavailable</strong>
              ) : (
                <strong>Booked</strong>
              )}
            </div>
            <div>
              <img src={available} width="50px" />
              <strong>Available</strong>
            </div>
            <div>
              <img src={driver} width="50px" />
              <strong>Driver</strong>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BusSeatUI;
