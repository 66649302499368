import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL, moduleName } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import CreateNetworkAgency from "./CreateNetworkAgency";
import { checkRolePermissions } from "../../../utils/Helper";
import Pagination from "react-js-pagination";

class NetworkAgency extends Component {
  state = {
    createModal: false,
    dataSpinner: false,
    deletePermission: false,
    edit: false,
    editPermission: false,
    selectedData: "",
    networkAgencies: [],
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  componentDidMount() {
    this.getNetworkAgencies();
    this.permissions();
  }

  getNetworkAgencies = (pageNumber, paginated_value) => {
    let param = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
    }
    this.setState({ dataSpinner: true, networkAgencies: [] }, function () {
      axiosPost(
        URL.networkAgency + "/" + "get-network-agencies", param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              networkAgencies: response.data.data.items,
              totalItemsCount: response.data.data.totalData,
              current_page: pageNumber,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleDelete = (id) => {
    axiosGet(URL.deleteNetworkAgency + "/" + id, (response) => {
      if (response.status === 200) {
        swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
        this.getNetworkAgencies();
      }
    });
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, edit: true, createModal: true });
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.networkAgency),
      deletePermission: checkRolePermissions(
        "delete",
        moduleName.networkAgency
      ),
    });
  };

  handlePaginatedValueChange = (value) => {
    this.setState({ paginated_value: parseInt(value) }, () => {
      this.getNetworkAgencies(1, value);
    });
  };

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <>
        <div className="dashboardHeader">
          <strong>NETWORK AGENCIES</strong>
        </div>
        <div className="container-fluid h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions("create", moduleName.networkAgency) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Network Agency
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Contact Details</th>
                      <th>Email</th>
                      {this.state.editPermission ||
                        this.state.deletePermission ? (
                        <th>Options</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.networkAgencies?.length > 0 ? (
                      this.state.networkAgencies?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.address}</td>
                            <td>{item.contact_details}</td>
                            <td>{item.email}</td>
                            {this.state.editPermission ||
                              this.state.deletePermission ? (
                              <td>
                                {this.state.editPermission ? (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.handleEdit(item)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger ms-3"
                                    onClick={() => this.handleDelete(item.id)}
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            this.state.editPermission ||
                              this.state.deletePermission
                              ? 7
                              : 6
                          }
                          className="text-center p-3"
                        >
                          {this.state.dataSpinner ? (
                            <>
                              <Spinner color="primary"></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Network Agency found. Create one first"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.networkAgencies.total <= 10 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) => this.getNetworkAgencies(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                  <div className="paginatoion_paginated_value ml-3">
                    <select
                      className="form-control"
                      value={this.state.paginated_value}
                      onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </ div>
                </div>
              )}
            </div>
          </div>
          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateNetworkAgency
                getNetworkAgencies={this.getNetworkAgencies}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
                edit={this.state.edit}
              />
            }
            modalHeader={
              this.state.edit ? "Edit Network Agency" : "Add Network Agency"
            }
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default NetworkAgency;
