import React, { Component } from "react";
import swal from "sweetalert";
import CustomFullSpinner from "../../../../components/WebsiteComponents/CustomFullSpinner";
import { axiosPost } from "../../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { validateImage } from "../../../../utils/Helper";
import { linkValidate } from "../../../../utils/validation";

export class CreateAdds extends Component {
  state = {
    imagePreview: "",
    title: "",
    description: "",
    link: "",
    displayLocation: "",
    image: "",
    imageFile: "",
    loader: false,
    display: "",
  };

  componentDidMount() {
    if (this.props.editMode) {
      let editData = this.props.editData;
      this.setState({
        imagePreview:
          fileUrl +
          "/" +
          editData.media[0].id +
          "/" +
          editData.media[0].file_name,
        title: editData.name,
        description: editData.description,
        link: editData.link,
        displayLocation: editData.type,
        display: editData.display,
      });
    } else {
      this.setState({
        imagePreview: "",
        title: "",
        description: "",
        link: "",
        displayLocation: "",
      });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (validateImage(e.target.files[0])) {
      this.setState(
        {
          image: e.target.value,
          imageFile: e.target.files[0],
        },
        function () {
          let image = window.URL.createObjectURL(this.state.imageFile);
          this.setState({ imagePreview: image });
        }
      );
    } else {
      e.target.value = null;
    }
  };

  handleSave = () => {
    let param = {
      name: this.state.title,
      description: this.state.description,
      link: this.state.link,
      type: this.state.displayLocation,
    };
    let formData = new FormData();
    formData.append("jsonData", JSON.stringify(param));
    formData.append("file", this.state.imageFile);

    if (
      this.state.title === "" ||
      this.state.description === "" ||
      this.state.displayLocation === "" ||
      this.state.image === ""
    ) {
      swal("Error", "Fill all the fields", "error");
    } else {
      let error = false;
      if (this.state.link !== "" && !linkValidate(this.state.link)) {
        error = true;
      }

      if (!error) {
        this.setState({ loader: true }, function () {
          axiosPost(
            URL.insertAdds,
            formData,
            (response) => {
              if (response.status === 200) {
                this.setState({ loader: false });
                swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                this.props.getAllAdds();
                this.props.toggleModal();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({ loader: false });
            }
          );
        });
      }
    }
  };

  handleUpdate = () => {
    let param = {
      name: this.state.title,
      description: this.state.description,
      link: this.state.link,
      type: this.state.displayLocation,
      display: this.state.display,
    };
    let formData = new FormData();
    formData.append("jsonData", JSON.stringify(param));
    if (this.state.imageFile !== "") {
      formData.append("file", this.state.imageFile);
    }

    if (
      this.state.title === "" ||
      this.state.description === "" ||
      this.state.displayLocation === ""
    ) {
      swal("Error", "Fill all the fields", "error");
    } else {
      let error = false;
      if (this.state.link !== "" && !linkValidate(this.state.link)) {
        error = true;
      }

      if (!error) {
        this.setState({ loader: true }, function () {
          axiosPost(
            URL.updateAdd + this.props.editData.id,
            formData,
            (response) => {
              if (response.status === 200) {
                this.setState({ loader: false });
                swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                this.props.getAllAdds();
                this.props.toggleModal();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({ loader: false });
            }
          );
        });
      }
    }
  };

  removeImage = () => {
    document.getElementById("imageFileHolder").value = null;

    this.setState({ image: "", imagePreview: "", imageFile: "" });
  };

  render() {
    return (
      <div>
        <div className="container">
          {this.state.loader ? (
            <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
          ) : null}
          {this.state.imagePreview && this.state.imagePreview !== "" ? (
            <div className="row mt-2 mb-2">
              <div className="col text-center">
                <div className="imageHolder">
                  <img src={this.state.imagePreview} />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row mt-2">
            <div className="col-md-3 mt-1">
              <strong>Image
                <span className="text-danger"> *</span>
              </strong>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col">
                  {this.props.edit &&
                    this.state.imagePreview !== "" &&
                    this.state.image === "" ? (
                    <input
                      className="form-control"
                      type="text"
                      value={this.props.selectedData.media[0].name}
                      disabled
                    />
                  ) : (
                    <input
                      accept="image/png, image/jpeg"
                      className="form-control"
                      name="image"
                      onChange={this.handleImageChange}
                      type="file"
                      id="imageFileHolder"
                    />
                  )}
                </div>
                {this.state.imagePreview &&
                  this.state.imagePreview !== "" &&
                  !this.props.editMode ? (
                  <div className="col-md-auto">
                    <button
                      className="btn btn-danger ml-3"
                      onClick={this.removeImage}
                    >
                      Remove
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3 mt-1">
              <strong>Title
                <span className="text-danger"> *</span>
              </strong>
            </div>
            <div className="col-md-9">
              <input
                className="form-control"
                name="title"
                onChange={this.handleChange}
                type="text"
                value={this.state.title}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3 mt-1">
              <strong>Description
                <span className="text-danger"> *</span>
              </strong>
            </div>
            <div className="col-md-9">
              <textarea
                className="form-control"
                name="description"
                onChange={this.handleChange}
                type="text"
                value={this.state.description}
              ></textarea>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 mt-1">
              <strong>Link</strong>
            </div>
            <div className="col-md-9">
              <input
                className="form-control"
                name="link"
                onChange={this.handleChange}
                type="link"
                value={this.state.link}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 mt-1">
              <strong>Display Location
                <span className="text-danger"> *</span>
              </strong>
            </div>
            <div className="col-md-9">
              <select
                name="displayLocation"
                value={this.state.displayLocation}
                className="form-control"
                onChange={this.handleChange}
                disabled={this.props.editMode}
              >
                <option value="" selected disabled>
                  Choose Display Location
                </option>
                <option value="home-page-top">Home Page Top</option>
                <option value="home-page-bottom">Home Page Bottom</option>
              </select>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col text-end">
              {this.props.editMode ? (
                <button className="btn btn-primary" onClick={this.handleUpdate}>
                  Update
                </button>
              ) : (
                <button className="btn btn-primary" onClick={this.handleSave}>
                  Save
                </button>
              )}

              <button
                className="btn btn-danger"
                style={{ marginLeft: "10px" }}
                onClick={() => this.props.toggleModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAdds;
