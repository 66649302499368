import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/WebsiteComponents/Footer/Footer";
import Header from "../../components/WebsiteComponents/Header/Header";
import { axiosGet, axiosGetWeb } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { cryptoSecretKey, URL } from "../../utils/Constants";
import { WithRouter } from "../../utils/WithRouter";
import WhatsAppWidget from "react-whatsapp-widget";

var CryptoJS = require("crypto-js");
class Main extends Component {
  state = {
    isOperator: false,
    isCustomer: false,
    contact: {},
  };

  componentDidMount() {
    this.getRole();
    this.getContact();
  }

  getRole = () => {
    let roleName = localStorage.getItem("roles");

    if (roleName) {
      var bytes = CryptoJS.AES.decrypt(roleName, cryptoSecretKey);
      var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      if (originalText[0] === "Operator") {
        this.setState({
          isOperator: true,
        });
      } else if (originalText[0] === "Registered Customer") {
        this.setState({
          isCustomer: true,
        });
      }
    }
  };

  getContact = () => {
    axiosGetWeb(URL.getWebsiteTravelAgency, (response) => {
      if (response.status === 200) {
        let data = response.data.data.items;
        this.setState({ contact: data })
      }
    });
  }

  render() {
    let { Component } = this.props;
    let token = localStorage.getItem("token");
    let contactInfo = this.state.contact;

    return (
      <React.Fragment>
        <div
          className="row"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          {this.state.isOperator || this.state.isCustomer ? null : (
            <>
              <div className="col">
                <div className={
                  token ? `backToDashboard d-flex align-center justify-content-between` : `backToDashboard d-flex align-center justify-content-end`
                }
                >
                  {token ? (
                    <Link
                      to="/admin/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      <>
                        <FontAwesomeIcon icon={faArrowCircleLeft} />{" "}
                        <span style={{ marginLeft: "5px" }}>Go To Dashboard</span>
                      </>
                    </Link>
                  ) : (<></>)}
                  {
                    contactInfo?.emergency_contact || contactInfo?.contact_details ?
                      (
                        <>
                          <span>Emergency Contact No: {contactInfo.emergency_contact ? contactInfo.emergency_contact : contactInfo.contact_details}</span>
                        </>)
                      : null
                  }

                </div>
              </div>
            </>
          )}
        </div>
        <Header></Header>
        {/* Component is name of Page passed from Routes.js file */}
        {Component}
        <Footer></Footer>
        <WhatsAppWidget phoneNumber="9779851167106" />
      </React.Fragment>
    );
  }
}

export default WithRouter(Main);
