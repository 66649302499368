import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

const CountdownTimer = (props) => {
    const countDownStart = props.countDownStart;
    const getTimerValue = props.getTimerValue;

    const [timeLeft, setTimeLeft] = useState(600);
    const [isRunning, setIsRunning] = useState(countDownStart);

    useEffect(() => {
        let interval = null;

        if (isRunning) {
            interval = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    if (prevTimeLeft <= 0) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prevTimeLeft - 1;
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    useEffect(() => {
        if (countDownStart) {
            setIsRunning(true);
            setTimeLeft(600);
        } else {
            setIsRunning(false);
            setTimeLeft(600);
        }
    }, [countDownStart]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    getTimerValue(minutes, seconds)

    return (
        <div className='w-100 d-flex align-items-center justify-content-end'>
            <h5 className='px-1'>
                {/* Countdown Timer: */}
                <FontAwesomeIcon
                    icon={faStopwatch}
                    style={{ cursor: "pointer" }}
                />
            </h5>
            <h6 className=
                {
                    countDownStart ? `text-danger` : ``
                }
            >
                {minutes.toString().padStart(2, '0')}:
                {seconds.toString().padStart(2, '0')}

            </h6>
        </div>
    );
};

export default CountdownTimer;
