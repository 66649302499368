import React, { Component } from "react";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";

export class ContactData extends Component {
  state = {
    socialIcons: [{ icon: "", link: "" }],
    maps: "",
  };

  componentDidMount() {
    this.getAllSocialIcons();
  }

  getAllSocialIcons = () => {
    axiosGet(URL.getAllSocialIcons, (response) => {
      if (response.status === 200) {
        let resData = response.data.data.items;

        if (resData.length > 0) {
          this.setState({
            socialIcons: resData,
            editMode: true,
          });
        }
      }
    });
  };

  handleAddSocialLink = () => {
    this.setState({
      socialIcons: [
        ...this.state.socialIcons,
        {
          icon: "",
          link: "",
        },
      ],
    });
  };

  handleDeleteSocialLink = (idx) => {
    let newFormValues = [...this.state.socialIcons];
    newFormValues.splice(idx, 1);
    this.setState({
      socialIcons: newFormValues,
    });
  };

  handleChange = (e, idx) => {
    let { name, value } = e.target;

    if (name === "maps") {
      this.setState({
        [name]: value,
      });
    } else {
      const socialIconsCopy = [...this.state.socialIcons];
      socialIconsCopy[idx] = { ...socialIconsCopy[idx], [name]: value };
      this.setState({ socialIcons: socialIconsCopy });
    }
  };

  handleSubmit = () => {
    let error = this.state.socialIcons.some(
      (si) => si.icon === "" || si.link === ""
    );

    if (error) {
      swal("Error", "Social icons data cannot be empty");
    } else {
      axiosPost(URL.insertSocialIcons, this.state.socialIcons, (response) => {
        if (response.status === 200) {
          swal("Success", "Social Icons data inserted successfully");

          this.getAllSocialIcons();
        }
      });
    }
  };

  handleUpdate = () => {
    let error = this.state.socialIcons.some(
      (si) => si.icon === "" || si.link === ""
    );

    if (error) {
      swal("Error", "Social icons data cannot be empty");
    } else {
      axiosPost(URL.updateSocialIcons, this.state.socialIcons, (response) => {
        if (response.status === 200) {
          swal("Success", "Social Icons data updated successfully");
          this.getAllSocialIcons();
        }
      });
    }
  };

  render() {
    return (
      <div className="contact-dashboard">
        <div className="dashboardHeader">
          <strong>Social Icons and Map</strong>
        </div>
        <div className="container py-4">
          <label htmlFor="socialIcons">Social Icons</label>

          {this.state.socialIcons.map((si, idx) => (
            <div className="row py-2 socialIconsData">
              <div className="col-md-4">
                <label htmlFor="faIcon">Icon
                  <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="icon"
                  className="form-control"
                  placeholder="Input fontawesome icon"
                  onChange={(e) => this.handleChange(e, idx)}
                  value={si.icon}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="link">Link
                  <span className="text-danger"> *</span>
                </label>

                <input
                  type="text"
                  name="link"
                  className="form-control"
                  placeholder="Input link"
                  onChange={(e) => this.handleChange(e, idx)}
                  value={si.link}
                />
              </div>
              <div className="col-md-4 mt-2">
                <button
                  className="mt-3 m-2 btn btn-info"
                  onClick={this.handleAddSocialLink}
                >
                  Add
                </button>
                {/* {idx !== 0 && ( */}
                <button
                  className="mt-3 m-2 btn btn-danger"
                  onClick={() => this.handleDeleteSocialLink(idx)}
                >
                  Remove
                </button>
                {/* )} */}
              </div>
            </div>
          ))}

          {/* <div className="row">
            <label htmlFor="socialIcons">Maps</label>

            <div className="col-md-6">
              <input
                type="text"
                name="maps"
                className="form-control"
                placeholder="Input map iframe"
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div> */}

          <div>
            <button
              className="mt-4 btn btn-primary"
              onClick={
                this.state.editMode ? this.handleUpdate : this.handleSubmit
              }
            >
              {this.state.editMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactData;
