import React, { Component } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import BusAgency from "../Pages/Dashboard/BusAgency/BusAgency";
import Bus from "../Pages/Dashboard/BusConfiguration/Bus/Bus";
import BusTypes from "../Pages/Dashboard/BusConfiguration/BusTypes/BusTypes";
import Dashboard from "../Pages/Dashboard/Dashboard";
import NetworkAgency from "../Pages/Dashboard/NetworkAgency/NetworkAgency";
import TravelAgency from "../Pages/Dashboard/TravelAgency/TravelAgency";
import TravelAgencyRoutes from "../Pages/Dashboard/TravelAgency/TravelAgencyRoutes";
import RouteView from "../Pages/Dashboard/Route/RouteView";
import BusRoutes from "../Pages/Dashboard/BusConfiguration/BusRoutes/BusRoutes";
import ScheduleSetting from "../Pages/Dashboard/BusScheduling/ScheduleSetting/ScheduleSetting";
import DailySchedule from "../Pages/Dashboard/BusScheduling/DailySchedule/DailySchedule";
import RolesAndPermissions from "../Pages/Dashboard/RolesAndPermissions/RolesAndPermissions";
import TravelChildAgency from "../Pages/Dashboard/TravelChildAgency/TravelChildAgency";
import CmsFaq from "../Pages/Dashboard/CMS/CmsFaq/CmsFaq";
import CmsSlider from "../Pages/Dashboard/CMS/CmsSlider/CmsSlider";
import CmsBlog from "../Pages/Dashboard/CMS/CmsBlog/CmsBlog";
import CmsTandC from "../Pages/Dashboard/CMS/CmsTandC/CmsTandC";
import Documents from "../Pages/Dashboard/Documents/Documents";
import CmsPaymentGateway from "../Pages/Dashboard/CMS/CmsPaymentGateway/CmsPaymentGateway";
import PaymentGatewayConfig from "../Pages/Dashboard/CMS/CmsPaymentGatewayConfig/PaymentGatewayConfig";
import ListCustomer from "../Pages/Dashboard/Customer/ListCustomer";
import CanceledTickets from "../Pages/Dashboard/CanceledTickets/CanceledTickets";
import AboutBanner from "../Pages/Dashboard/CMS/AboutUs/AboutBanner";
import AboutCompany from "../Pages/Dashboard/CMS/AboutUs/AboutCompany";
import DashboardBookingData from "../Pages/Dashboard/BookingDetails/DashboardBookingData";
import Advertisements from "../Pages/Dashboard/CMS/Advertisements/Advertisements";
import ContactData from "../Pages/Dashboard/ContactData/ContactData";
import MapData from "../Pages/Dashboard/ContactData/MapData";
import ManualBooking from "../components/DashboardComponents/ManualBooking";
import { DashboardBookings } from "../components/DashboardComponents/DashboardBookings";
import Amenities from "../Pages/Dashboard/Amenities/Amenities";


class ContentContainer extends Component {
  render() {
    return (
      <div
        style={{
          flex: 1,
          height: "100vh",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Switch>
          <Route path="/" element={<Dashboard />} />
          <Route path="/routes" element={<RouteView />} />
          <Route path="/travelAgency" element={<TravelAgency />} />
          <Route path="/travelAgencyRoutes" element={<TravelAgencyRoutes />} />
          <Route path="/busAgency" element={<BusAgency />} />
          <Route path="/networkAgencies" element={<NetworkAgency />} />
          <Route path="/bus-types" element={<BusTypes />} />
          <Route path="/buses" element={<Bus />} />
          <Route path="/bus-routes" element={<BusRoutes />} />
          <Route path="/bus-schedule-settings" element={<ScheduleSetting />} />
          <Route path="/daily-schedule" element={<DailySchedule />} />
          {/* Travel Child Agency ROute (OPerator) */}
          <Route path="/travelChildAgency" element={<TravelChildAgency />} />
          <Route path="/customers" element={<ListCustomer />} />
          <Route path="/canceledTickets" element={<CanceledTickets />} />

          {/* CMS */}

          <Route path="/about-banner" element={<AboutBanner />} />
          <Route path="/about-company" element={<AboutCompany />} />
          {/* <Route path="/services" element={<Services />} /> */}

          <Route path="/cms-faq" element={<CmsFaq />} />
          <Route path="/cms-slider" element={<CmsSlider />} />
          <Route path="/cms-blog" element={<CmsBlog />} />
          <Route path="/cms-tandc" element={<CmsTandC />} />
          <Route
            path="/rolesandpermissions"
            element={<RolesAndPermissions />}
          />
          <Route path="/documents" element={<Documents />} />
          <Route path="/cms-payment-gateway" element={<CmsPaymentGateway />} />
          <Route
            path="/cms-payment-gateway-config"
            element={<PaymentGatewayConfig />}
          />

          <Route path="/booking-details" element={<DashboardBookingData />} />
          <Route path="/advertisements" element={<Advertisements />} />
          <Route path="/contact-data" element={<ContactData />} />
          <Route path="/map-data" element={<MapData />} />
          <Route path="/manual-booking" element={<ManualBooking />} />
          <Route path="/dashboard-bookings" element={<DashboardBookings />} />

          {/* for dynamic amenities */}
          <Route path="/amenities" element={<Amenities />} />

        </Switch>
      </div>
    );
  }
}

export default ContentContainer;
