import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../../../components/WebsiteComponents/CustomModal";
import { axiosGet, axiosPost } from "../../../../utils/AxiosApi";
import { fileUrl, moduleName, URL } from "../../../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";
import { checkRolePermissions } from "../../../../utils/Helper";
import AddPaymentGatewayConfig from "./AddPaymentGatewayConfig";
import Pagination from "react-js-pagination";

class PaymentGatewayConfig extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    edit: false,
    paymentGateways: [],
    selectedData: {},
    spinner: false,
    updatePermission: false,
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
  };

  getPaymentGateways = (pageNumber,paginated_value) => {
    let param = {
      page:pageNumber? pageNumber : 1,
      paginated_value:paginated_value? paginated_value : 10
    }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.getGlobalPaymentGateway,param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            this.setState({current_page:pageNumber, paymentGateways: response.data.data.items,totalItemsCount: response.data.data.totalData });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({
      createModal: !this.state.createModal,
      edit: false,
    });
  };

  handleDeleteBtnClick = (data) => {
    let params = {
      id: data.customFields[0].payment_key_global_configuration_id,
    };
    displayConfirmDeleteAlert(params, this.handleDelete);
  };

  handleDelete = (params) => {
    this.setState({ loader: true }, () => {
      axiosGet(URL.deleteGlobalPaymentGateway + "/" + params.id, (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.setState({
            loader: false,
          });
          this.getPaymentGateways();
        }
      });
    });
  };

  handleEdit = (data) => {
    this.setState({
      selectedData: cloneDeep(data),
      edit: true,
      createModal: !this.state.createModal,
    });
  };

  permissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions(
          "delete",
          moduleName.paymentGatewayConfig
        ),
        updatePermission: checkRolePermissions(
          "update",
          moduleName.paymentGatewayConfig
        ),
      },
      function () {
        this.getPaymentGateways();
      }
    );
  };

  componentDidMount() {
    this.permissions();
  }

  render() {
    const { current_page, paginated_value, totalItemsCount } = this.state;
    return (
      <>
        <div className="dashboardHeader">
          <strong>PAYMENT GATEWAY CONFIGURATIONS</strong>
        </div>
        <div className="container h-100">
          <div className="row mt-2">
            <div className="col">
              {checkRolePermissions(
                "create",
                moduleName.paymentGatewayConfig
              ) ? (
                <button
                  className="btn btn-success"
                  onClick={this.handleCreateModal}
                >
                  Add Payment Gateway
                </button>
              ) : null}
            </div>
          </div>
          <div className="row mt-2 h-100">
            <div className="col d-flex align-items-center justify-content-between flex-column">
              <div className="table-responsive w-100">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Payment Gateway</th>
                      <th>Image</th>
                      <th>Status</th>

                      <th>Custom Fields</th>
                      {this.state.deletePermission ||
                      this.state.updatePermission ? (
                        <th>Actions</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.paymentGateways.length > 0 ? (
                      this.state.paymentGateways.map((gateway, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{gateway.payment_gateway}</td>
                            <td>
                              {" "}
                              <div className="blogThumbnail">
                                <img
                                  src={
                                    fileUrl +
                                    "/" +
                                    gateway?.media[0]?.id +
                                    "/" +
                                    gateway?.media[0]?.file_name
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {gateway.is_active == 1 ? "Active" : "Not Active"}
                            </td>
                            <td>
                              <div>
                                {/* <strong>Key Name : </strong>
                                      <span>{field.labelName}</span> */}

                                <table className="table table-borderless">
                                  <thead>
                                    <tr>
                                      <th>Key</th>
                                      <th>Placeholder Info</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {gateway?.customFields?.length > 0
                                      ? gateway.customFields.map(
                                          (field, idx) => {
                                            return (
                                              <tr key={idx}>
                                                <td>{field.field_name}</td>
                                                <td>
                                                  {field.place_holder_info}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : "No custom fields"}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                            {this.state.deletePermission ||
                            this.state.updatePermission ? (
                              <td>
                                {this.state.updatePermission ? (
                                  <button
                                    className="btn btn-primary m-2"
                                    onClick={() => this.handleEdit(gateway)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </button>
                                ) : null}
                                {this.state.deletePermission ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      this.handleDeleteBtnClick(gateway)
                                    }
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="text-center p-3"
                          colSpan={
                            this.state.updatePermission ||
                            this.state.deletePermission
                              ? 6
                              : 5
                          }
                        >
                          {this.state.spinner ? (
                            <>
                              <Spinner></Spinner>
                              <br />
                              Loading Data...
                            </>
                          ) : (
                            "No Payment Gateways added yet"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.totalItemsCount <= 0 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={paginated_value}
                    totalItemsCount={totalItemsCount}
                    onChange={(pageNumber) =>
                      this.getBusRouteAuthUser(pageNumber)
                    }
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <AddPaymentGatewayConfig
              edit={this.state.edit}
              selectedData={this.state.selectedData}
              getPaymentGateways={this.getPaymentGateways}
              handleCreateModal={this.handleCreateModal}
            />
          }
          modalHeader={
            this.state.edit ? "Update Payment Gateway" : "Add Payment Gateway"
          }
          size="xl"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default PaymentGatewayConfig;
