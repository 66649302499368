import React, { Component } from "react";
import swal from "sweetalert";

import { fileUrl, URL as URLs } from "../../../utils/Constants";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import { axiosPost } from "../../../utils/AxiosApi";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
import "../../../assets/scss/dashboard.scss";
import { emailValidate, phoneValidate } from "../../../utils/validation";

class CreateNetworkAgency extends Component {
  state = {
    address: "",
    contactDetails: "",
    email: "",
    id: "",
    name: "",
    loader: false,
    urlAddress: "",
    mapIframe: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      address: selectedData.address,
      contactDetails: selectedData.contact_details,
      email: selectedData.email,
      id: selectedData.id,
      name: selectedData.name,
      urlAddress: selectedData.front_end_url,
      // imagePreview:
      //   selectedData.media.length > 0
      //     ? fileUrl +
      //       "/" +
      //       selectedData.media[0].id +
      //       "/" +
      //       selectedData.media[0].file_name
      //     : null,
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSave = () => {
    let param = {
      name: this.state.name,
      address: this.state.address,
      contact_details: this.state.contactDetails,
      email: this.state.email,
      front_end_url: this.state.urlAddress,
      mapLocation: this.state.mapIframe,
    };
    // let formData = new FormData();
    // formData.append("travel_agency", JSON.stringify(param));
    // formData.append("file", this.state.logoFile);

    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.contactDetails === "" ||
      this.state.email === ""
      // this.state.urlAddress === ""
    ) {
      swal("Warning", "Filll all the fields.", "warning");
    } else {
      if (emailValidate(this.state.email)) {
        if (phoneValidate(this.state.contactDetails)) {
          this.setState({ loader: true }, function () {
            axiosPost(
              URLs.networkAgency,
              param,
              (response) => {
                if (response.status === 200) {
                  this.setState({ loader: false });
                  swal("Success", DISPLAYMESSAGE.createSuccess, "success");
                  this.props.getNetworkAgencies();
                  this.props.handleCreateModal();
                }
              },
              (err) => {
                displayErrorAlert(err);
                this.setState({ loader: false });
              }
            );
          });
        }
      }
    }
  };

  handleUpdate = () => {
    let param = {
      id: this.state.id,
      name: this.state.name,
      address: this.state.address,
      contactDetails: this.state.contactDetails,
      email: this.state.email,
      front_end_url: this.state.urlAddress,
    };
    // let formData = new FormData();
    // formData.append("travel_agency", JSON.stringify(param));
    // formData.append("file", this.state.logoFile);

    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.contactDetails === "" ||
      this.state.email === "" ||
      this.state.urlAddress === ""
    ) {
      swal("Warning", "Fill all the fields.", "warning");
    } else {
      if (emailValidate(this.state.email)) {
        if (phoneValidate(this.state.contactDetails)) {
          this.setState({ loader: true }, function () {
            axiosPost(
              URLs.updateNetworkAgency,
              param,
              (response) => {
                if (response.status === 200) {
                  this.setState({ loader: false });

                  swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
                  this.props.getNetworkAgencies();
                  this.props.handleCreateModal();
                }
              },
              (err) => {
                displayErrorAlert(err);
                this.setState({ loader: false });
              }
            );
          });
        }
      }
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Name
              <span className="text-danger"> *</span>
            </strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="name"
              onChange={this.handleChange}
              type="text"
              value={this.state.name}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Address
              <span className="text-danger"> *</span>
            </strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Phone / Mobile
              <span className="text-danger"> *</span>
            </strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="contactDetails"
              onChange={this.handleChange}
              type="text"
              value={this.state.contactDetails}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Email
              <span className="text-danger"> *</span>
            </strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="email"
              onChange={this.handleChange}
              type="email"
              value={this.state.email}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Website URL
            </strong>
          </div>
          <div className="col-md-9">
            <input
              className="form-control"
              name="urlAddress"
              onChange={this.handleChange}
              type="text"
              value={this.state.urlAddress}
            />
          </div>
        </div>
        {!this.props.edit ?
          (
            <>
              <div className="row mt-2">
                <div className="col-md-3 mt-1">
                  <strong>Map Iframe:
                  </strong>
                </div>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    name="mapIframe"
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.mapIframe}
                  />
                </div>
              </div>
            </>) : (<></>)}

        <div className="row mt-2">
          <div className="col text-end">
            {this.props.edit ? (
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSave}>
                Save
              </button>
            )}

            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={this.props.handleCreateModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateNetworkAgency;
