import React, { useEffect, useState } from "react";
import faqImg from "../../../assets/images/faqs.png";
import { axiosGet, axiosGetWeb } from "../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import parse from "html-react-parser";
import Pagination from "react-js-pagination";

import "./blog.scss";
import moment from "moment";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
const Blog = () => {
  const [spinner, setSpinner] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = (pageNumber = 1) => {
    setSpinner(true);
    axiosGetWeb(
      URL.getBlogs,
      (response) => {
        if (response.status === 200) {
          setSpinner(false);
          setBlogs(response.data.data);
        }
      },
      (err) => {
        setSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  // const { current_page, per_page, total } = blogs;

  return (
    <div>
      <div className="my-tickets-title-box text-center">
        <div className="banner-text">
          <h1>Blogs</h1>
        </div>
      </div>

      <div className="blog-container">
        <div class="blog-head col-12">
          <h1>
            {" "}
            <span>Recent</span> Blogs
          </h1>
        </div>
        <div className="row g-2">
          {spinner ? (
            <div className="text-center p-5">
              {" "}
              <Spinner />
            </div>
          ) : blogs?.data && blogs.data.length > 0 ? (
            blogs?.data?.map((blog) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 bsm">
                {blog.description ? (
                  <Link
                    to="/single-blog"
                    state={{ blogData: blog, recentBlogs: blogs }}
                    className="news-page-card"
                  >
                    <div className="img-news">
                      <img
                        className="img-fluid"
                        alt="No preview"
                        src={
                          fileUrl +
                          "/" +
                          blog.media[0].id +
                          "/" +
                          blog.media[0].file_name
                        }
                      />
                    </div>
                    <div className="news-content">
                      <h3 className="news-title">{blog.title}</h3>
                      <h5 className="news-subtitle">{blog.subtitle}</h5>
                      {/* <p className="news-desc">
                        {parse(`${blog.description}`)}
                      </p> */}
                      <span>{moment(blog.created_at).format("LL")}</span>
                    </div>
                    <div className="news-link">
                      <span>Read More</span>
                    </div>
                  </Link>
                ) : (
                  <a
                    href={blog.link}
                    className="news-page-card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="img-news">
                      <img
                        className="img-fluid"
                        alt="No preview"
                        src={
                          fileUrl +
                          "/" +
                          blog.media[0].id +
                          "/" +
                          blog.media[0].file_name
                        }
                      />
                    </div>
                    <div className="news-content">
                      <h3 className="news-title">{blog.title}</h3>
                      <h5 className="news-subtitle">{blog.subtitle}</h5>
                      {/* <p className="news-desc">
                        {parse(`${blog.description}`)}
                      </p> */}
                      <span>{moment(blog.created_at).format("LL")}</span>
                    </div>
                    <div className="news-link">
                      <span>Read More</span>
                    </div>
                  </a>
                )}
              </div>
            ))
          ) : (
            <>
              <div style={{ height: "320px" }} className="col-12 col-sm-6 col-md-4 col-lg-3 bsm my-4 w-100 d-flex align-items-center justify-content-center">
                <p>There are no blog posts available at the moment!</p>
              </div>
            </>
          )}
        </div>
        {/* {blogs.total <= 10 ? (
          <></>
        ) : (
          <div className="paginationContainer">
            <Pagination
              activePage={current_page}
              itemsCountPerPage={per_page}
              totalItemsCount={total}
              onChange={(pageNumber) => getBlogs(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Blog;
