import axios from "axios";
import React, { Component } from "react";

import { pdfjs } from "react-pdf";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Document, Page } from "react-pdf";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { fileUrl, URL } from "../../../utils/Constants";
class TicketReceipt extends Component {
  state = {
    numPages: 1,
  };

  downloadFile = () => {
    let data = { data: this.props.receiptPdf };
    // alert(URL.downloadTicket + "/" + data.data.id + "/" + data.data.file_name);
    // axiosGet(
    //   URL.downloadTicket + "/" + data.data.id + "/" + data.data.file_name,

    //   (res) => {}
    // );
    axios({
      url: URL.downloadTicket + "/" + data.data.id + "/" + data.data.file_name,
      method: "GET",
      responseType: "blob", // important
      // headers: headers,
      // data: data,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `ticket-receipt-${new Date().toISOString()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        // setDownloading(false);
      })
      .catch((err) => {
        // setDownloading(false);
      });
  };

  render() {
    return (
      <div>
        <div className="receipt-pdf-view">
          {/* <button
            onClick={() => this.downloadFile()}
            className="btn btn-info m-1"
          >
            Download
          </button> */}

          {/* <a
            href={this.props.receiptPdf}
            download
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-info m-1">Click to download</button>
          </a> */}

          {/* <PDFViewer
        document={{
          url: this.state.receiptPdf,
        }}
      /> */}
          {/* <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri:
                  fileUrl +
                  "/" +
                  this.props.receiptPdf.id +
                  "/" +
                  this.props.receiptPdf.file_name,
              },
            ]}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false,
              },
              pdfZoom: {
                defaultZoom: 0.5, // 1 as default,
                zoomJump: 0.2, // 0.1 as default,
              },
            }}
          /> */}
           <iframe view="fit" style={{"width":"100%","height":"80vh"}} src={fileUrl +
                  "/" +
                  this.props.receiptPdf.id +
                  "/" +
                  this.props.receiptPdf.file_name}></iframe>
          {/* <Document
            onLoadError={(error) => console.log("Inside Error", error)}
            file={{
              url:
                fileUrl +
                "/" +
                this.props.receiptPdf.id +
                "/" +
                this.props.receiptPdf.file_name,
            }}
            options={{
              workerSrc:
                (pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`),
              cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
          >
            {Array.from(new Array(this.state.numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document> */}
        </div>
      </div>
    );
  }
}

export default TicketReceipt;
