import React, { Component } from "react";
import Select from "react-select";
import CryptoJS from "crypto-js";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Container,
  UncontrolledCollapse,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import {
  axiosGet,
  axiosGetWeb,
  axiosPost,
  axiosPostWeb,
} from "../../../utils/AxiosApi";
import {
  displayErrorAlert,
  DISPLAYMESSAGE,
} from "../../../utils/DisplayMessage";
import {
  faArrowLeft,
  faCross,
  faSync,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fileUrl,
  headers,
  keyHeaders,
  URL,
  cryptoSecretKey,
} from "../../../utils/Constants";
import bus from "../../../assets/images/bus.svg";
import booked from "../../../assets/images/booked.svg";
import empty from "../../../assets/images/empty.svg";
import driver from "../../../assets/images/driver.svg";
import available from "../../../assets/images/available.svg";
import selected from "../../../assets/images/selected.svg";
import hover from "../../../assets/images/hover.svg";
import { WithRouter } from "../../../utils/WithRouter";
import BookSeat from "./BookSeat";
import PassengerDetails from "../MultiStepComponents/PassengerDetails";
import ConfirmDetails from "../MultiStepComponents/ConfirmDetails";
import PaymentMethod from "../MultiStepComponents/PaymentMethod";
import Finished from "../MultiStepComponents/Finished";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import CustomModal from "../../../components/WebsiteComponents/CustomModal";
import moment from "moment";
import CustomFullSpinner from "../../../components/WebsiteComponents/CustomFullSpinner";
// import KhaltiCheckout from "khalti-checkout-web";
import { encode as base64_encode } from "base-64";
import axios from "axios";
import Pagination from "react-js-pagination";
import CountdownTimer from "../../../components/WebsiteComponents/CountdownTimer/CountDownTimer";

let form = null;

class RoutesList extends Component {
  state = {
    bookingDetails: "",
    step: 0,
    cities: [],
    cityName: "",
    origin: "",
    destination: "",
    // busDepartureDate: new Date().toISOString().substr(0, 10),
    busDepartureDate: "",
    busTiming: "",
    isLoading: false,
    travelAgencyBusType: null,
    travelAgency: null,
    busAgency: null,
    openModal: false,
    multiPageFormHeader: [
      { name: "BookSeat", isActive: true },
      { name: "Passenger Details", isActive: false },
      { name: "Confirm Detail", isActive: false },
      { name: "Payment Method", isActive: false },
      { name: "Finished", isActive: false },
    ],
    busBookingId: null,

    availableBuses: [],
    userLoggedInChk: false,
    selectedBusType: "",
    selectedScheduleId: "",
    chosenBoardingPoint: "",
    chosenDroppingPoint: "",
    loader: false,
    //static state later shall be dynamic
    taDetails: {
      id: "",
    },
    filters: [
      {
        filter_title: "Bus Type",
        type: "radio",
        filter_options: [
          {
            id: "1",
            lable: "AC",
            disabled: false,
          },
          {
            id: "2",
            lable: "Non AC",
            disabled: false,
          },
          {
            id: "3",
            lable: "Delux",
            disabled: false,
          },
          {
            id: "4",
            lable: "Micro",
            disabled: false,
          },
          {
            id: "5",
            lable: "4-Wheeler",
            disabled: false,
          },
        ],
      },
      {
        filter_title: "Travel Agency",
        type: "checkbox",
        filter_options: [
          {
            id: "1",
            lable: "Taj Travel Agency",
            disabled: false,
          },
          {
            id: "2",
            lable: "Mahal Travel Agency",
            disabled: false,
          },
        ],
        //Pass the bus type id and filter_option(agency) id to filter the BUs Type accordingly
        filter_options_Bus_Type: [
          {
            BusId: "1", //passing id as value to the BusId we can find the bus type available in Travel agency
            lable: "Taj Travel Agency",
            filter_options_Id: "1", //passing id -we can find the which travel agency it is
          },
          {
            BusId: "2", //passing id as value to the BusId we can find the bus type available in Travel agency
            lable: "Mahal Travel Agency",
            filter_options_Id: "2", //passing id -we can find the which travel agency it is
          },
        ],
      },
      {
        filter_title: "Bus Agency",
        type: "checkbox",
        filter_options: [
          {
            id: "1",
            lable: "Taj Bus Agency",
            disabled: false,
          },
          {
            id: "2",
            lable: "Mahal Bus Agency",
            disabled: false,
          },
        ],
        filter_options_Bus_Type: [
          {
            BusId: "3", //passing id as value to the BusId we can find the bus type available in Travel agency
            lable: "Taj Bus Agency",
            filter_options_Id: "1", //passing id -we can find the which travel agency it is
          },
          {
            BusId: "4", //passing id as value to the BusId we can find the bus type available in Travel agency
            lable: "Mahal Bus Agency",
            filter_options_Id: "2", //passing id -we can find the which travel agency it is
          },
          {
            BusId: "5", //passing id as value to the BusId we can find the bus type available in Travel agency
            lable: "Mahal Bus Agency",
            filter_options_Id: "2", //passing id -we can find the which travel agency it is
          },
        ],
      },
    ],

    busRows: [
      {
        row: "A",
        seats: [
          {
            id: "1",
            status: "available",
          },
          {
            id: "2",
            status: "available",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "empty",
          },
          {
            id: "5",
            status: "driver",
          },
        ],
      },
      {
        row: "B",
        seats: [
          {
            id: "1",
            status: "available",
          },
          {
            id: "2",
            status: "available",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "empty",
          },
          {
            id: "5",
            status: "empty",
          },
        ],
      },
      {
        row: "C",
        seats: [
          {
            id: "1",
            status: "empty",
          },
          {
            id: "2",
            status: "empty",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "booked",
          },
          {
            id: "5",
            status: "booked",
          },
        ],
      },
      {
        row: "D",
        seats: [
          {
            id: "1",
            status: "booked",
          },
          {
            id: "2",
            status: "booked",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "booked",
          },
          {
            id: "5",
            status: "booked",
          },
        ],
      },
      {
        row: "E",
        seats: [
          {
            id: "1",
            status: "booked",
          },
          {
            id: "2",
            status: "booked",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "booked",
          },
          {
            id: "5",
            status: "booked",
          },
        ],
      },
      {
        row: "F",
        seats: [
          {
            id: "1",
            status: "available",
          },
          {
            id: "2",
            status: "available",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "available",
          },
          {
            id: "5",
            status: "available",
          },
        ],
      },
      {
        row: "G",
        seats: [
          {
            id: "1",
            status: "available",
          },
          {
            id: "2",
            status: "available",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "available",
          },
          {
            id: "5",
            status: "available",
          },
        ],
      },
      {
        row: "H",
        seats: [
          {
            id: "1",
            status: "available",
          },
          {
            id: "2",
            status: "available",
          },
          {
            id: "3",
            status: "empty",
          },
          {
            id: "4",
            status: "available",
          },
          {
            id: "5",
            status: "available",
          },
        ],
      },
      {
        row: "I",
        seats: [
          {
            id: "1",
            status: "available",
          },
          {
            id: "2",
            status: "available",
          },
          {
            id: "3",
            status: "booked",
          },
          {
            id: "4",
            status: "available",
          },
          {
            id: "5",
            status: "available",
          },
        ],
      },
    ],

    seat_configuration: "",
    selectedSeats: [],
    paymentSuccess: false,
    current_page: 1,
    totalItemsCount: 0,
    paginated_value: 10,
    contact: "",
    timer: 540,
    countDownStart: false,
    lastClickedIdx: null,
    lastClickedIndex: null,
    lastClickedBusRowId: null,
    lastClickedBusSeatId: null,
    seatsArray: [],
    trueCount: 0,
    falseCount: 0,
    hideTimer: false,

    //Declaring React state as a obj having propertys and  functions
    // testObject: {
    //   isUser: null,
    //   loginUser() {
    //     this.isUser = true;
    //     console.log(this.isUser);
    //   },
    //   logoutUser() {
    //     this.isUser = false;
    //     console.log(this.isUser);
    //   }
    // }
  };

  RoutesList = () => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCities();
    // this.checkandTest(this.state.testObject);

    this.getTravelAgencyBusType();
    this.getContact();
    this.resetCounts();

    // this.getTravelAgency();
    // this.getBusAgency();

    //func to filter bus type accordingly
    this.getAgencyBusType();
    this.handleSearchBuses();
    let defaultRoute = this.props.location?.state?.route;

    if (defaultRoute) {
      this.setState(
        {
          origin: defaultRoute.origin,
          destination: defaultRoute.destination,
          busDepartureDate: defaultRoute.busDepartureDate
            ? defaultRoute.busDepartureDate
            : "",
          busTiming: defaultRoute.busTiming
            ? defaultRoute.busTiming
            : this.state.busTiming,
        },
        () => {
          this.handleSearchBuses();
        }
      );
    } else {
      this.setState({
        origin: "",
        destination: "",
        busDepartureDate: this.state.busDepartureDate,
      });
    }
    this.deleteIfBusSeatSessionExist();

    const script = document.createElement("script");

    script.src =
      "https://khalti.s3.ap-south-1.amazonaws.com/KPG/dist/2020.12.17.0.0.0/khalti-checkout.iffe.js";
    script.async = true;

    document.body.appendChild(script);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.state) {
      if (prevProps.location.state.route !== this.props.location.state.route) {
        let defaultRoute = this.props.location?.state?.route;

        if (defaultRoute !== null) {
          this.setState({
            origin: defaultRoute.origin,
            destination: defaultRoute.destination,
            busDepartureDate: defaultRoute.busDepartureDate
              ? defaultRoute.busDepartureDate
              : new Date().toISOString().substr(0, 10),
          });
        }
        window.scrollTo(0, 0);
      }
    }
  }
  componentWillUnmount() {
    this.resetCounts();
  }

  getContact = () => {
    axiosGetWeb(URL.getWebsiteTravelAgency, (response) => {
      if (response.status === 200) {
        let data = response.data.data.items;
        this.setState({ contact: data })
      }
    });
  }

  handleMultiPartForm = (e, headerArg) => {
    //change the isActive flag in the state   and className in jsx
    const ShallowCopyOfMultiPageForm = [...this.state.multiPageFormHeader];
    const pageIndex = ShallowCopyOfMultiPageForm.findIndex(
      (h, id) => h.name === headerArg.name
    );
    //compare step and pageIndex

    // if (this.state.step >= 2 && pageIndex  == 0) {
    //   if (!this.state.formData) {
    //   } else {
    //   }
    // }

    if (this.state.step === 2 && pageIndex === 3) {
      if (this.state.customerDetails.proccedToPayment === true) {
        this.setState({ step: pageIndex });
      } else {
        swal("Error", "Ticketing Process cannot be skipped", "error");
        return;
      }
    }

    if (this.state.step == 0 && pageIndex == 1) {
      if (
        JSON.parse(localStorage.getItem("selectedSeat")) !== null &&
        JSON.parse(localStorage.getItem("selectedSeat")).length > 0
      ) {
        this.setState({ step: pageIndex });
      } else {
        swal("Warning", "Please select seats to book.", "warning");
        return;
      }
    }

    if (this.state.step === 4 && pageIndex < 4) {
      swal("Warning", "Ticket booking is finished, cannot go back.", "warning");
      return;
    }

    if (
      pageIndex === 4 &&
      (this.state.bookingDetails === "" || !this.state.paymentSuccess)
    ) {
      swal("Error", "Ticket booking process cannot be skipped", "error");
      return;
    }

    if (pageIndex < 2 && this.state.step == 0) {
      if (
        pageIndex === this.state.step + 1 ||
        pageIndex === this.state.step - 1
      ) {
        ShallowCopyOfMultiPageForm.forEach((el, id) => {
          id === pageIndex ? (el.isActive = true) : (el.isActive = false);
        });
        this.setState({ step: pageIndex });
      } else {
        swal(
          "Error",
          "Unfortunately !...Ticket Processing order breaks ",
          "error"
        );
        return;
      }
    } else {
      if (!this.state.formData && pageIndex > 0) {
        swal("Error", "Ticketing Process cannot be skipped", "error");
        return;
      }
      ShallowCopyOfMultiPageForm.forEach((el, id) => {
        id === pageIndex ? (el.isActive = true) : (el.isActive = false);
      });
      this.setState({ step: pageIndex });

      if (this.state.step >= 2 && pageIndex == 0) {
      }
    }

    this.setState({ multiPageFormHeader: ShallowCopyOfMultiPageForm });
  };

  //change the step value by 1 Book Seat -> Passenger Details
  handleStepChangeByOne = (formdata) => {
    this.setState({ step: 1 });
    const ShallowCopyOfMultiPageForm = [...this.state.multiPageFormHeader];
    ShallowCopyOfMultiPageForm.map((h, index) => {
      index == 1 ? (h.isActive = true) : (h.isActive = false);
      return h;
    });
  };

  handlePaymentReceipt = () => {
    this.setState({
      loader: true,
    });
    axiosPostWeb(URL.paymentReceipt, this.state.bookingDetails, (response) => {
      if (response.status === 200) {
        this.setState({
          loader: false,
          bookingDetailsPdf: response.data.data.item.media[0],

          step: 4,
        });
      }
    });
  };

  handleBusBooking = () => {
    this.setState({
      loader: true,
    });
    let params = JSON.parse(localStorage.getItem("bookingDetails"));
    let token = localStorage.getItem("token");
    axiosPostWeb(
      token ? URL.busBooking : URL.guestBusBooking,
      params,
      (response) => {
        if (response.status === 200) {
          // swal("Success", "Your seats are successfully booked.", "success");

          // const ShallowCopyOfMultiPageForm = [
          //   ...this.state.multiPageFormHeader,
          // ];
          // ShallowCopyOfMultiPageForm.forEach((h, index) => {
          //   index === 4 ? (h.isActive = true) : (h.isActive = false);
          //   // return h;
          // });
          this.setState(
            {
              bookingDetails: response.data.data,
              loader: false,
            },
            () => {
              return true;
            }
          );

          // this.handleStepChangeFromCD();
          // localStorage.removeItem("selectedSeat");
        } else {
          this.setState({
            loader: false,
          });
          swal("Error", "Booking failed", "error");
          return false;
        }
      }
    );
  };

  handlePaymentSuccess = () => {
    this.setState({
      loader: true,
    });

    // swal("Success", "Your seats are successfully booked.", "success");

    const ShallowCopyOfMultiPageForm = [...this.state.multiPageFormHeader];
    // alert(ShallowCopyOfMultiPageForm)
    // console.log([...this.state.multiPageFormHeader], ShallowCopyOfMultiPageForm)
    ShallowCopyOfMultiPageForm.forEach((h, index) => {
      index === 4 ? (h.isActive = true) : (h.isActive = false);
      // return h;
    });

    this.resetCounts();
    this.handlePaymentReceipt();

    localStorage.removeItem("selectedSeat");
  };

  // handlePaymentSuccess = () => {
  //   this.setState({
  //     loader: true,
  //   });
  //   let params = JSON.parse(localStorage.getItem("bookingDetails"));
  //   let token = localStorage.getItem("token");

  //   axiosPost(
  //     token ? URL.busBooking : URL.guestBusBooking,
  //     params,
  //     (response) => {
  //       if (response.status === 200) {
  //         // swal("Success", "Your seats are successfully booked.", "success");

  //         const ShallowCopyOfMultiPageForm = [
  //           ...this.state.multiPageFormHeader,
  //         ];
  //         ShallowCopyOfMultiPageForm.forEach((h, index) => {
  //           index === 4 ? (h.isActive = true) : (h.isActive = false);
  //           // return h;
  //         });
  //         this.setState(
  //           {
  //             bookingDetails: response.data.data,
  //           },
  //           () => {
  //             this.handlePaymentReceipt();
  //           }
  //         );
  //         localStorage.removeItem("selectedSeat");

  //         // setTimeout(() => {
  //         //   this.setState({
  //         //     openModal: false,
  //         //   });
  //         //   this.resetStateData();
  //         // }, 12000);
  //       } else {
  //         swal("Error", "Booking failed", "error");
  //       }
  //     }
  //   );
  // };

  //change the step value by 1 PAssenger Details -> Confirm Deatails  Details
  handleStepChangeFromPD = (formData) => {
    if (!formData) {
      swal("Error", "Passenger's details must be filled", "error");
      return;
    }
    this.setState({ step: 2 });
    this.setState({ formData: formData });
    const ShallowCopyOfMultiPageForm = [...this.state.multiPageFormHeader];
    ShallowCopyOfMultiPageForm.map((h, index) => {
      index === 2 ? (h.isActive = true) : (h.isActive = false);
      return h;
    });
  };

  handleStepChangeFromCD = () => {
    if (this.state.customerDetails?.proccedToPayment === true) {
      this.setState({ step: 3 });
    } else {
      swal(
        "Warning",
        "Ticket booking process cannot be skipped without filling all the required fields data.",
        "warning"
      );
    }

    const ShallowCopyOfMultiPageForm = [...this.state.multiPageFormHeader];
    ShallowCopyOfMultiPageForm.map((h, index) => {
      index === 3 ? (h.isActive = true) : (h.isActive = false);
      return h;
    });
  };

  handleChangeManualDetails = (e) => {
    const { name, value, type, checked } = e.target;

    if (this.state.customerDetails !== "") {
      this.setState({
        customerDetails: {
          ...this.state.customerDetails,
          [name]: value,
          [name]: type === "checkbox" ? checked : value,
        },
      });
    } else {
      this.setState({
        manualData: {
          ...this.state.manualData,
          [name]: value,
          [name]: type === "checkbox" ? checked : value,
        },
      });
    }
  };

  handleManualBooking = (remarks) => {
    let bookingDetails = JSON.parse(localStorage.getItem("selectedSeat"));

    let seatConfig = bookingDetails.map((bd) => {
      return {
        bus_row_id: bd.row_id,
        bus_column_id: bd.column_id,
        remark: bd.remarks || "",
      };
    });
    let params = {
      daily_schedule_id: this.state.selectedScheduleId,
      is_manual: true,
      remark: remarks,
      bus_seat_configuration: seatConfig,
      // travel_agency_id: this.state.taDetails.id,
    };

    if (remarks !== "") {
      this.setState(
        {
          loader: true,
        },
        () => {
          axiosPostWeb(URL.busBooking, params, (response) => {
            if (response.status === 200) {
              swal("Success", "Your seats are successfully booked.");

              this.setState({
                loader: false,

                openModal: false,
              });
              this.handleModalClose();
              this.resetStateData();
              this.handleSearchBuses();
            } else {
              this.setState({
                loader: false,
              });
              swal("Error", "Booking failed", "error");
              return false;
            }
          });
        }
      );
    } else {
      swal("Warning", "Please add remarks to book seats.");
    }
  };

  //change the content of modal body as step changes in Multi step ticket booking system
  handleStepChange = (id, header) => {
    const { step } = this.state;
    // console.log(header);
    switch (step) {
      case 0:
        return (
          <BookSeat
            handleManualBooking={this.handleManualBooking}
            manualBooking={this.props.manualBooking}
            selectedSeats={this.state.selectedSeats}
            isChanged={this.state.seatConfigurationChange}
            handleOnClickBusSeatSelect={this.handleOnClickBusSeatSelect}
            seatConfig={this.state.seat_configuration}
            // newSeatConfig={this.state.seat_configuration}
            step={this.state.step}
            header={header}
            handleStepChangeByOne={() => this.handleStepChangeByOne()}
            selectedScheduleId={this.state.selectedScheduleId}
            selectedRoute={this.state.selectedRoute}
            boardingPoints={this.state.boardingPoints}
            droppingPoints={this.state.droppingPoints}
            handleBoardingPointChange={(e) => this.handleBoardingPointChange(e)}
            handleDroppingPointChange={(e) => this.handleDroppingPointChange(e)}
            refreshSeat={this.refreshSeat}
            chosenBoardingPoint={this.state.chosenBoardingPoint}
            chosenDroppingPoint={this.state.chosenDroppingPoint}
            chosenBoardingPointId={this.state.chosenBoardingPointId}
            chosenDroppingPointId={this.state.chosenDroppingPointId}
            seat_configuration
          />
        );
      case 1:
        return (
          <PassengerDetails
            // customerDetails={this.state.customerDetails}
            header={header}
            handleStepChangeByOne={this.handleStepChangeFromPD}
            step={step}
            formData={this.state?.customerDetails || this.state.manualData}
            selectedRoute={this.state.selectedRoute}
            selectedSeats={this.state.selectedSeats}
            handleChangeManualDetails={(e) => this.handleChangeManualDetails(e)}
          />
        );
      case 2:
        return (
          <ConfirmDetails
            selectedSeats={this.state.selectedSeats}
            selectedRoute={this.state.selectedRoute}
            formData={this.state?.customerDetails || this.state.manualData}
            handleStepChangeFromCD={this.handleStepChangeFromCD}
            chosenBoardingPointId={this.state.chosenBoardingPointId}
            chosenDroppingPointId={this.state.chosenDroppingPointId}
            handleChangeManualDetails={(e) => this.handleChangeManualDetails(e)}
            handleBusBooking={this.handleBusBooking}
          />
        );
      case 3:
        return (
          <PaymentMethod
            handlePaymentSuccess={this.handlePaymentSuccess}
            handleBusBooking={this.handleBusBooking}
            bookingDetails={this.state.bookingDetails}
            setBookingDetails={this.setBookingDetails}
            handlePayment={this.handlePayment}
          />
        );
      case 4:
        return (
          <Finished
            bookingDetails={this.state.bookingDetails}
            bookingDetailsPdf={this.state.bookingDetailsPdf}
          />
        );

      default:
        // do nothing
        return null;
    }
  };

  getBusAgency = async () => {
    try {
      const id = 1; //Id will be dynamic

      const type = "NA"; //type will be dynamic
      const url = `${URL.getBusAgency}/${type}/${id}`;
      await axiosGet(url, (response) => {
        if (response.status === 200) {
          const busgency = response.data.data.items;
          this.setState({ busAgency: busgency });
        }
      });
    } catch (error) {
      displayErrorAlert(error);
    }
  };

  // getTravelAgency = async () => {
  //   try {
  //     const id = 1; //Id will be dynamic
  //     const url = `${URL.getTravelAgency}/${id}`;
  //     await axiosGet(url, (response) => {
  //       if (response.status === 200) {
  //         const travelagency = response.data.data.items;
  //         this.setState({ travelAgency: travelagency });
  //       }
  //     });
  //   } catch (error) {
  //     displayErrorAlert(error);
  //   }
  // };

  getTravelAgencyBusType = async () => {
    // const type = `${this.state.taDetails.isTA ? "TA" : "NA"}`;
    // const id = parseInt(` ${this.state.taDetails.id} `);
    // const url = `${URL.busTypes}/${type}/${id}`;
    const url = `${URL.busesTypes}`;

    try {
      await axiosGetWeb(url, (response) => {
        if (response.status === 200) {
          const busType = response.data.data.items;
          this.setState({ travelAgencyBusType: busType });
        }
      });
    } catch (error) {
      displayErrorAlert(error);
    }
  };

  //(static) filtering bus type as per user role-agency
  getAgencyBusType = () => {
    const shallowCopyOfFilterArr = [...this.state.filters];
    let avialableBusType = [];

    for (let i = 0; i < 1; i++) {
      const busDetails = shallowCopyOfFilterArr[i].filter_options;
      avialableBusType.push(busDetails);
    }

    // compare busAgency busType id provided with the availableBusTypeId
    // const availableAgency = ["Bus Agency", "Travel Agency"];
    let agencyWithBusType = [];

    let agencyDetails = [];
    for (let i = 1; i <= 2; i++) {
      const details = shallowCopyOfFilterArr[i].filter_options_Bus_Type;
      agencyDetails.push(details);
    }

    avialableBusType.flat().forEach((bus) => {
      agencyDetails.flat().forEach((agency) => {
        if (agency.BusId === bus.id) {
          const value = {
            agencyName: agency.lable,
            bus,
          };
          agencyWithBusType.push(value);
        }
      });
    });
    var output = [];

    agencyWithBusType.forEach(function (item) {
      var existing = output.filter(function (v, i) {
        return v.agencyName === item.agencyName;
      });
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex].bus = output[existingIndex].bus.concat(item.bus);
      } else {
        if (typeof item.bus == "object") item.bus = [item.bus];
        output.push(item);
      }
    });

    // console.dir(output);
    this.setState({ agencyWithBusType: output });
  };

  getCities = () => {
    this.setState({ cities: [], isLoading: true }, function () {
      axiosPost(
        URL.getCities,
        { name: "" },
        (response) => {
          if (response.status === 200) {
            let items = response.data.data.items;
            items.sort(function (a, b) {
              return a.name.localeCompare(b.name); //using String.prototype.localCompare()
            });
            this.setState({
              cities: items,
              isLoading: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleOriginChange = (e) => {
    this.setState({ origin: e });
  };

  handleDestinationChange = (e) => {
    this.setState({ destination: e });
  };

  handleOnClickExchangeIcon = () => {
    document.getElementById("exchangeIcon").style.animation =
      "rotate 1s infinite";
    setTimeout(
      () => (document.getElementById("exchangeIcon").style.animation = "none"),
      1000
    );
    // let a = this.state.origin;
    // let b = this.state.destination;
    // [a, b] = [b, a];
    this.setState({
      origin: this.state.destination,
      destination: this.state.origin,
    });
  };

  Select = (e, busRowIdx, busSeatIndex) => {
    // e.target.src = selected;

    let seat_configuration_copy = { ...this.state.seat_configuration };

    seat_configuration_copy.row[busRowIdx].columns[busSeatIndex].is_selected =
      !seat_configuration_copy.row[busRowIdx].columns[busSeatIndex].is_selected;
    this.setState({ seat_configuration: seat_configuration_copy });
  };
  handleCheckbox = (e, value) => {
    const shallowCopyOfFilterArr = [...this.state.filters];
    const shallowCopyOfAgencyWithBusType = [...this.state.agencyWithBusType];
    if (e.target.type === "checkbox") {
      const isAvailable = shallowCopyOfAgencyWithBusType.find(
        (aB) => aB.agencyName === value.lable
      );

      if (isAvailable) {
        for (let i = 0; i < 1; i++) {
          shallowCopyOfFilterArr[i].filter_options.forEach((fO) => {
            const isBusAvailable = isAvailable.bus.find((bus) => bus === fO);
            if (isBusAvailable) {
              fO.disabled = false;
            } else {
              fO.disabled = true;
            }
          });
        }
      }
      this.setState({ filters: shallowCopyOfFilterArr });
    }
  };

  refreshSeat = () => {
    let data = this.state.selectedRouteData;
    if (data.busId !== "" && data.scheduleId !== "") {
      const value = localStorage.getItem("token");
      axiosGetWeb(
        `${URL.getBookingBusSeatConfiguration}/${data.busId}/${data.scheduleId}`,

        (response) => {
          if (response.status === 200) {
            this.setState({
              seat_configuration: response.data.data.items[0],
              loader: false,
            });
          } else {
            this.setState({
              seat_configuration: response.data.data.items[0],
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    }
  };

  handleRouteClick = (data) => {
    this.setState({
      selectedRouteData: {
        busId: data.bus_id,
        scheduleId: data.id,
        // taDetails: data.travel_agency,
      },
      loader: true,
    });
    const value = localStorage.getItem("token");

    // if (value) {
    axiosGetWeb(
      `${value
        ? URL.getAuthBookingBusSeatConfiguration
        : URL.getBookingBusSeatConfiguration
      }/${data.bus_id}/${data.id}`,
      (response) => {
        if (response.status === 200) {
          this.setState(
            {
              seat_configuration: response.data.data.items[0],
              selectedScheduleId: data.id,
              selectedRoute: data,
              loader: false,
            },
            () => {
              if (value) {
                axiosGet(URL.getCustomerDetails, (response) => {
                  if (response.status === 200) {
                    this.setState({
                      customerDetails: response.data.data?.items[0],
                      openModal: true,
                    });
                  }
                });
              } else {
                this.setState({
                  openModal: true,
                });
              }

              // let selectedSeatsArr = [];
              // this.state.seat_configuration.bus_rows.forEach((br) => {
              //   br.bus_columns.forEach((bc) => {
              //     if (bc.is_selected === true && bc.is_changeable === true) {
              //       selectedSeatsArr = [
              //         ...selectedSeatsArr,
              //         {
              //           rowName: br.name,
              //           columnName: bc.id,

              //           bus_row_id: br.id,

              //           bus_column_id: bc.id,
              //         },
              //       ];
              //     }
              //   });
              // });

              // this.setState({
              //   selectedSeats: selectedSeatsArr,
              // });
            }
          );
        }
      }
    );

    this.getBoardingPointAndDroppingPoint(data.route_id);
    // } else {
    //   this.setState({ userLoggedInChk: true });
    // }
    this.resetCounts()
  };

  handleModalClose = () => {
    if (!this.state.openModal) {
      this.deleteIfBusSeatSessionExist();
      // this.resetStateData();
    }
  };

  closeModalBook = () => {
    let self = this;
    swal({
      title: "Warning !!",
      text: "Are you sure? You want to clear your session.",
      icon: "warning",
      buttons: ["No, cancel it!", "Yes, I am sure!"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        self.resetCounts();
        self.setState({ openModal: false }, () => {
          self.handleModalClose();
          self.resetStateData();
        });
      }
    });
  };

  handleCompleteBookingCloseModal = () => {
    this.setState({
      openModal: false,
      hideTimer: false,
    }, () => {
      this.handleModalClose();
      this.resetStateData();
    });
  };

  // handleToggle = () => {
  //   this.setState({
  //     userLoggedInChk: !this.state.userLoggedInChk,
  //   });
  // };

  handleSearchBuses = (pageNumber, paginated_value) => {
    let params = {
      page: pageNumber ? pageNumber : 1,
      paginated_value: paginated_value ? paginated_value : 10,
      origin: this.state.origin ? this.state.origin.id : "",
      destination: this.state.destination ? this.state.destination.id : "",
      departure_date: this.state.busDepartureDate,
      bus_type_id: this.state.selectedBusType,
      shift: this.state.busTiming,
    };

    this.setState({ searchSpinner: true });

    axiosPostWeb(
      URL.searchAvailableBuses,
      params,
      (response) => {
        if (response.status === 200) {
          this.setState({
            availableBuses: response.data.data.data,
            totalItemsCount: response.data.data.totalData,
            current_page: pageNumber,
            searchSpinner: false,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
        this.setState({ searchSpinner: false });
      }
    );
  };

  handleCheckedBusType = (e, id) => {
    this.setState({ selectedBusType: id }, () => {
      this.handleSearchBuses();
    });
  };

  handleResetDepartureDate = () => {
    this.setState({
      busDepartureDate: "",
    });
  };

  handleOnClickBusSeatSelect = (
    e,
    busRowIdx,
    busSeatIndex,
    rowId,
    columnId,
    statusSeat,
    selectedSeatCount
  ) => {
    this.setState({ loader: true }, function () {
      const value = localStorage.getItem("token");
      let seat_configuration_copy = { ...this.state.seat_configuration };
      this.setState({ seatConfigurationChange: true });
      this.setState(prevState => ({
        seatsArray: [...prevState.seatsArray, selectedSeatCount]
      }), () => {
        this.setCounts();

      });
      this.setState(
        {
          seat_configuration: seat_configuration_copy,
        },
        async () => {
          let chkSeat = {
            daily_schedule_id: this.state.selectedScheduleId,
            bus_row_id: rowId,
            bus_column_id: columnId,
            seat_status: statusSeat,
          };

          await axiosPostWeb(
            value ? URL.tempAuthBusSeatBook : URL.tempBusSeatBook,
            chkSeat,
            (response) => {
              if (response.status === 200) {
                let selectedSeat = {
                  row_id: rowId,
                  column_id: columnId,
                  rowName: seat_configuration_copy.bus_rows[busRowIdx].name,
                  columnName:
                    seat_configuration_copy.bus_rows[busRowIdx].bus_columns[
                      busSeatIndex
                    ].name,
                  daily_schedule_id: this.state.selectedScheduleId,
                };
                let localStorageValue = JSON.parse(
                  localStorage.getItem("selectedSeat")
                );
                if (localStorageValue && localStorageValue.length > 0) {
                  // localStorage.setItem("selectedSeat", JSON.stringify([]));
                  let val = false;
                  localStorageValue.find((va) => {
                    if (
                      selectedSeat.row_id === va.row_id &&
                      selectedSeat.column_id === va.column_id
                    )
                      val = true;
                  });
                  if (val) {
                    const filtereddata = localStorageValue.filter(
                      (v) =>
                        selectedSeat.row_id !== v.row_id ||
                        selectedSeat.column_id !== v.column_id
                    );
                    localStorage.setItem(
                      "selectedSeat",
                      JSON.stringify(filtereddata)
                    );
                  } else {
                    localStorageValue.push(selectedSeat);
                    localStorage.setItem(
                      "selectedSeat",
                      JSON.stringify(localStorageValue)
                    );
                  }
                } else {
                  localStorage.setItem(
                    "selectedSeat",
                    JSON.stringify([selectedSeat])
                  );
                  setTimeout(() => {
                    localStorage.removeItem("selectedSeat");
                  }, 600000);
                }
                this.refreshSeat();
              }
            },
            (err) => {
              if (err.response?.data?.message === "exist") {
                //check if exist in localstorage
                let localStorageValue = JSON.parse(
                  localStorage.getItem("selectedSeat")
                );
                if (localStorageValue && localStorageValue.length > 0) {
                  let val = false;
                  localStorageValue.find((v) => {
                    if (rowId === v.row_id && columnId === v.column_id)
                      val = true;
                  });
                  if (val) {
                    let filtereddata = [];
                    localStorageValue.forEach((element) => {
                      if (
                        element.row_id !== rowId ||
                        element.column_id !== columnId
                      ) {
                        filtereddata.push(element);
                      }
                    });
                    let param = {
                      bus_row_id: rowId,
                      bus_column_id: columnId,
                      daily_schedule_id: chkSeat.daily_schedule_id,
                    };
                    axiosPostWeb(
                      URL.deleteTempBusSeatBook,
                      param,
                      (response) => {
                        if (response.status === 200) {
                          localStorage.setItem(
                            "selectedSeat",
                            JSON.stringify(filtereddata)
                          );
                          this.refreshSeat();
                        }
                      }
                    );
                  } else {
                    this.refreshSeat();
                    swal(
                      "Error",
                      "Sorry, this seat is currently being selected by other user",
                      "error"
                    );
                  }
                }
              } else {
                displayErrorAlert(err);
                this.refreshSeat();
              }
            }
          );
        }
      );


    });
    //
  };

  resetStateData = () => {
    this.setState({
      formData: "",
      bookingDetails: "",
      bookingDetailsPdf: "",
      selectedRoute: "",
      busDepartureDate: new Date().toISOString().substr(0, 10),
      busTiming: "",
      origin: "",
      destination: "",
      step: 0,
      multiPageFormHeader: [
        { name: "BookSeat", isActive: true },
        { name: "Passenger Details", isActive: false },
        { name: "Confirm Detail", isActive: false },
        { name: "Payment Method", isActive: false },
        { name: "Finished", isActive: false },
      ],
    });
  };

  deleteIfBusSeatSessionExist = () => {
    const name = localStorage.getItem("selectedSeat");
    if (name != null) {
      let localStorageValue = JSON.parse(localStorage.getItem("selectedSeat"));
      if (localStorageValue && localStorageValue.length > 0) {
        localStorageValue.forEach((element) => {
          let param = {
            bus_row_id: element.row_id,
            bus_column_id: element.column_id,
            daily_schedule_id: element.daily_schedule_id,
          };
          axiosPostWeb(URL.deleteTempBusSeatBook, param, (response) => {
            if (response.status === 200) {
              localStorage.removeItem("selectedSeat");
            }
          });
        });
      }
    } else {
      // console.log("selectedSeat key Doesn't exist in Local Storage.");
    }
  };

  getBoardingPointAndDroppingPoint = (id) => {
    const value = localStorage.getItem("token");

    axiosGetWeb(
      value
        ? URL.getBoardingPointAndDroppingPoint + "/" + id
        : URL.getGuestBoardingPointAndDroppingPoint + "/" + id,
      (response) => {
        if (response.status === 200) {
          this.setState({
            boardingPoints: response.data.data.boarding_points,
            droppingPoints: response.data.data.dropping_points,
          });
        }
      }
    );
  };

  handleBoardingPointChange = (e) => {
    if (!e) {
      this.setState({
        chosenBoardingPointId: "",
        chosenBoardingPoint: "",
      });
    } else {
      this.setState({
        chosenBoardingPointId: e.id,
        chosenBoardingPoint: e,
      });
    }
  };
  handleDroppingPointChange = (e) => {
    if (!e) {
      this.setState({
        chosenDroppingPointId: "",
        chosenDroppingPoint: "",
      });
    } else {
      this.setState({
        chosenDroppingPointId: e.id,
        chosenDroppingPoint: e,
      });
    }
  };

  // payment and booking

  post = (data) => {
    form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      // "https://epay.esewa.com.np/api/epay/main/v2/form"
      "https://rc-epay.esewa.com.np/api/epay/main/v2/form"
    );
    form.setAttribute("target", "_blank");
    form.style.display = "none";

    for (var key in data) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "text");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("id", key);
      hiddenField.setAttribute("value", data[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();

    this.getResponse("E-Sewa", data.transaction_uuid);
  };

  getResponse = (method, paymentID) => {
    let value = localStorage.getItem("token");

    let data = {
      pid: paymentID,
      paymentMethod: method,
      token: paymentID,
      bus_booking_id: this.state.bookingDetails.bus_booking_id,
    };
    const myInterval = setInterval(() => {
      axiosPostWeb(
        value ? URL.getTransactionDetail : URL.getGuestTransactionDetail,
        data,
        (response) => {
          if (response && response.status === 200) {
            if (response.data.data === null) {
              this.setState({
                loader: false,
                paymentSuccess: false,
              });
            } else if (response.data.data.item.alt_response === "Success") {
              this.setState({
                loader: false,
                transactionResponse: response.data?.data?.item?.alt_response,
                paymentSuccess: true,
                hideTimer: true,
              });

              this.handlePaymentSuccess();
              clearInterval(myInterval);
            } else if (
              response.data.data.item.alt_response === "failure"
            ) {
              this.setState({
                loader: false,
                paymentSuccess: false,
              });
              clearInterval(myInterval);
              this.handleCompleteBookingCloseModal();
              swal("Error", "Payment failure.");
              return;
            } else if (response.data.data.item.response === "Cancelled") {
              this.setState({
                loader: false,
                paymentSuccess: false,
              });
              clearInterval(myInterval);
              this.handleCompleteBookingCloseModal();
              swal("Error", "Payment cancelled.")
              return;
            } else {
              this.setState({
                loader: false,
                paymentSuccess: false,
              });
              clearInterval(myInterval);
              this.handleCompleteBookingCloseModal();
              swal("Error", `Payment Failed. Please contact ${this.state.contact ? this.state.contact[0]?.contact_details : 'support'} for any enquiries. Your booked seat will be reserved for 9 minutes.`, "error");
              return;
            }
          }
        },
        (err) => {
          // setLoader(false);
          // clearInterval(myInterval);
        }
      );
    }, 5000);
  };

  setBookingDetails = (data, param) => {
    this.setState(
      {
        bookingDetails: data,
        // loader: false,
      },

      () => {
        let busBookingId = data.bus_booking_id;

        this.setState({
          loader: true,
        });
        let self = this;

        if (param.payment_gateway === "Khalti") {
          let khaltiKey = param.paymentKeys.find(
            (data) => data.field_name === "publickey"
          );

          let value = localStorage.getItem("token");

          let config = {
            productIdentity: "TajTicket",
            productName: "BusTicket",
            bookingId: busBookingId,
          };

          axiosPostWeb(
            value ? URL.verifyKhaltiPayment : URL.verifyGuestKhaltiPayment,
            config,
            (response) => {
              if (response.status === 200) {
                window.open(response.data.payment_url);
              } else if (response.status === 400) {
                swal("Error", "Khalti verification failed.", "error");
                self.setState({ loader: false });
              }
            }
          );

          // let khaltiKey = param.paymentKeys.find(
          //   (data) => data.field_name === "publickey"
          // );

          // let value = localStorage.getItem("token");

          // let config = {
          //   publicKey: "7e5223cc7ab94b03a965d8c80e895cf8",
          //   productIdentity: "TajTicket",
          //   productName: "BusTicket",
          //   bookingId: busBookingId,
          //   productUrl: "http://gameofthrones.wikia.com/wiki/Dragons",
          //   eventHandler: {
          //     onSuccess(payload) {
          //       payload.bookingId = busBookingId;
          //       // setKhaltiToken(payload.token);
          //       axiosPostWeb(
          //         value
          //           ? URL.verifyKhaltiPayment
          //           : URL.verifyGuestKhaltiPayment,
          //         payload,
          //         (response) => {
          //           if (response.status === 200) {
          //             self.getResponse("Khalti", payload.token);
          //             this.setState({
          //               paymentSuccess: true,
          //             });
          //           } else if (response.status === 400) {
          //             swal("Error", "Payment failed", "error");
          //             self.setState({ loader: false });
          //           }
          //         }
          //       );
          //     },
          //     onError(error) {
          //       swal("Error", error, "error");
          //       self.setState({ loader: false });
          //     },
          //     onClose() {
          //       self.setState({ loader: false });
          //     },
          //   },
          //   paymentPreference: [
          //     "KHALTI",
          //     // , "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"
          //   ],
          // };
          // let checkout = new KhaltiCheckout(config);
          // let totalFare = JSON.parse(localStorage.getItem("bookingDetails"));
          // let totalFareInPaisa = totalFare.fare * 100;
          // checkout.show({ amount: totalFareInPaisa });
          // minimum transaction amount must be 10, i.e 1000 in paisa.
          // checkout.show({ amount: 1000 });
        }
        if (
          param.payment_gateway.trim() === "E-Sewa" ||
          param.payment_gateway.trim() === "E-sewa" ||
          param.payment_gateway.trim() === "ESewa" ||
          param.payment_gateway.trim() === "Esewa"
        ) {
          // this.setState({
          //   bookingDetails: data,
          // });

          let eSewaData = param.paymentKeys.find(
            (data) => data.field_name === "scd"
          );

          let totalFare = JSON.parse(localStorage.getItem("bookingDetails"));
          let prodId = data.invoice_number + '-' + Date.now();

          var signedFields =
            "amount=" +
            totalFare.fare +
            ",total_amount=" +
            totalFare.fare +
            ",transaction_uuid=" +
            prodId +
            ",product_code=" +
            eSewaData.value;

          var hash = CryptoJS.HmacSHA256(signedFields, "8gBm/:&EnhH.1/q");
          var signatureHash = CryptoJS.enc.Base64.stringify(hash);

          let secretKey = base64_encode(keyHeaders["secret-key"]);
          let clientKey = base64_encode(keyHeaders["client-key"]);

          let sendData = {
            amount: totalFare.fare,
            product_service_charge: 0,
            product_delivery_charge: 0,
            tax_amount: 0,
            total_amount: totalFare.fare,
            transaction_uuid: prodId,
            product_code: eSewaData.value,
            success_url:
              URL.esewaVerification +
              `${busBookingId}/` +
              secretKey +
              `/` +
              clientKey,
            failure_url:
              URL.cancelledEsewaVerification +
              `${prodId}/` +
              `${totalFare.fare}/` +
              `${busBookingId}`,
            signed_field_names:
              "amount,total_amount,transaction_uuid,product_code",
            signature: signatureHash,
          };
          this.post(sendData);
        } else {
          this.setState({
            paymentModule: true,
          });
        }
        this.setState({
          selectedPaymentMethod: param,
        });
      }
    );
  };

  handlePayment = (param) => {
    let params = JSON.parse(localStorage.getItem("bookingDetails"));
    let token = localStorage.getItem("token");
    if (!this.state.bookingDetails?.bus_booking_id) {
      axiosPostWeb(
        token ? URL.busBooking : URL.guestBusBooking,
        params,
        (response) => {
          if (response.status === 200) {
            this.setBookingDetails(response.data.data, param);
          } else {
            this.setState({
              loader: false,
            });
            swal("Error", "Booking failed", "error");
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    } else {
      this.setBookingDetails(this.state.bookingDetails, param);
      // swal("Error", "Some error occured");
    }
  };

  handlePaginatedValueChange = (value) => {
    let valuePage = parseInt(value);
    this.setState({ paginated_value: valuePage }, () => {
      this.handleSearchBuses(1, valuePage);
    });
  };

  resetCounts = () => {
    const counts = this.state.seatsArray.reduce((acc, seat) => {
      if (seat) {
        acc.trueCount = 0;
      } else {
        acc.falseCount = 0;
      }
      return acc;
    }, { trueCount: 0, falseCount: 0 });

    this.setState({
      trueCount: 0,
      falseCount: 0,
      countDownStart: false,
      seatsArray: []
    }, () => {
      // console.log(`True Count: ${counts.trueCount}, False Count: ${counts.falseCount}`);
    });

  };

  setCounts = () => {
    const counts = this.state.seatsArray.reduce((acc, seat) => {
      if (seat) {
        acc.trueCount++;
      } else {
        acc.falseCount++;
      }
      return acc;
    }, { trueCount: 0, falseCount: 0 });

    this.setState({
      trueCount: counts.trueCount,
      falseCount: counts.falseCount,
    }, () => {
      if (counts.trueCount > counts.falseCount) {
        this.setState({
          countDownStart: true
        });
      }
      else {
        this.setState({
          countDownStart: false
        })
      }
    });
  }

  getTimerValue = (min, sec) => {
    let timeRemain = {
      minValue: min,
      secValue: sec
    };
    localStorage.setItem("remainingTime", JSON.stringify(timeRemain));
    if (min === 0 && sec === 0) {
      this.closeModalBook();
      this.setState({ countDownStart: false });
      let self = this;

      swal({
        title: "Warning !!",
        text: "The timer for selected seats of 10 mins is expired. Please book the seats again!",
        icon: "warning",
        buttons: {
          confirm: {
            text: "Okay!",
            value: true,
            visible: true,
            closeModal: true
          }
        },
        dangerMode: true,
        closeOnClickOutside: false
      }).then((isConfirm) => {
        if (isConfirm) {
          self.resetCounts();
          self.setState({ openModal: false }, () => {
            self.handleModalClose();
            self.resetStateData();
          });
        }
      });
    }
  };


  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#fff" : "#000",
        backgroundColor: state.isSelected ? "#6C63FF" : "white",
      }),
      control: (provided) => ({
        ...provided,
        marginTop: "5%",
      }),
    };

    const { travelAgencyBusType, travelAgency, busAgency } = this.state;
    const { current_page, paginated_value, totalItemsCount } = this.state;

    return (
      <div>
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="gradient-block"></div>

        <Container fluid="lg" className="busTicketForm-vertical">
          <div className="busTicketForm-div">
            <Form className="busTicketForm row g-1">
              <div className="col col-12 col-md-6 col-lg-3 d-flex align-items-center justify-content-between">
                <FormGroup>
                  <Label for="origin">From</Label>
                  <Select
                    options={this.state.cities}
                    name="origin"
                    value={this.state.origin}
                    onChange={(e) => this.handleOriginChange(e)}
                    placeholder="Origin City"
                    isClearable
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary25: "#FF9190",
                        primary: "#6C63FF",
                      },
                    })}
                    isLoading={this.state.isLoading}
                    classNamePrefix="city"
                  />
                </FormGroup>
                <FontAwesomeIcon
                  icon={faSync}
                  id="exchangeIcon"
                  onClick={this.handleOnClickExchangeIcon}
                />
              </div>

              <div className="col col-12 col-md-6 col-lg-2">
                <FormGroup>
                  <Label for="destination">To</Label>
                  <Select
                    className="city"
                    options={this.state.cities}
                    name="destination"
                    value={this.state.destination}
                    onChange={(e) => this.handleDestinationChange(e)}
                    placeholder="Destination City"
                    isLoading={this.state.isLoading}
                    isClearable
                    classNamePrefix="city"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary25: "#FF9190",
                        primary: "#6C63FF",
                      },
                    })}
                  />
                </FormGroup>
              </div>

              <div className="col col-12 col-md-6 col-lg-2">
                <FormGroup>
                  <Label for="departureDate">Departure Date</Label>
                  <div className="d-flex">
                    <Input
                      id="busDepartureDate"
                      name="busDepartureDate"
                      type="date"
                      className="busDepartureDate"
                      value={this.state.busDepartureDate}
                      onChange={this.handleChange}
                    // min={moment(new Date()).format("YYYY-MM-DD")}
                    />

                    {this.state.busDepartureDate === "" ? null : (
                      <FontAwesomeIcon
                        onClick={this.handleResetDepartureDate}
                        icon={faTimes}
                        style={{ position: "relative", right: "60", top: "12" }}
                      />
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col col-12 col-md-6 col-lg-3">
                <div>
                  <Label for="departureDate">Shift Timing</Label>
                  <FormGroup tag="fieldset" className="radio-container">
                    <FormGroup check>
                      <Input
                        name="busTiming"
                        type="radio"
                        value="Day"
                        id="day"
                        onChange={this.handleChange}
                        checked={this.state.busTiming === "Day"}
                      />
                      <Label for="day" className="radioLabel">
                        Day
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        name="busTiming"
                        type="radio"
                        value="Night"
                        id="night"
                        onChange={this.handleChange}
                        checked={this.state.busTiming === "Night"}
                      />
                      <Label for="night" className="radioLabel">
                        Night
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        name="busTiming"
                        type="radio"
                        value="Both"
                        id="both"
                        onChange={this.handleChange}
                        checked={this.state.busTiming === "Both"}
                      />
                      <Label for="both" className="radioLabel">
                        Both
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </div>
              </div>
              <div className="col col-12 col-md-12 col-lg-2">
                <Button
                  className="busTicketForm-btn"
                  onClick={() => this.handleSearchBuses()}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
        </Container>
        <Container fluid="lg" className="routesFilter-container h-100"
        >
          <Row className="">
            <Col xs="12" md="3">
              <Row className="m-thead g-0">
                <Col xs="10" md="10" lg="10">
                  <h1>Filters By</h1>
                </Col>
              </Row>

              {/* /* Render Bus Type according to the response from the server */}

              <div className="filter-col">
                <div className="filter-item border">
                  <UncontrolledCollapse toggler="#filter" defaultOpen={true}>
                    {travelAgencyBusType && travelAgencyBusType.length > 0 ? (
                      <FormGroup check>
                        <Input
                          name="busType"
                          id="busTypeAny"
                          type="radio"
                          onChange={(e) => this.handleCheckedBusType(e, "")}
                        />
                        <Label for="busTypeAny" className="bus-types-radio">
                          Any
                        </Label>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {travelAgencyBusType && travelAgencyBusType.length > 0 ? (
                      travelAgencyBusType.map((filter, idx) => {
                        return (
                          <div className="filter-item">
                            <FormGroup check>
                              <Input
                                name="busType"
                                id={"radio" + idx}
                                type="radio"
                                onChange={(e) =>
                                  this.handleCheckedBusType(e, filter.id)
                                }
                              />
                              <Label
                                for={"radio" + idx}
                                className="bus-types-radio"
                              >
                                {filter.type}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <p className="p-2">No Bus Type Found</p>
                      </div>
                    )}
                  </UncontrolledCollapse>
                  <div className="filter-title" id={"filter"}>
                    Bus Types
                  </div>
                </div>
              </div>

              {/* <div className="filter-col">
                <div className="filter-item border">
                  <UncontrolledCollapse toggler="#filter1">
                    {travelAgency && travelAgency
                      ? travelAgency.map((filter, idx) => {
                          return (
                            <div className="filter-item">
                              <FormGroup check>
                                <Input
                                  name={idx}
                                  id={"radio" + idx}
                                  type="checkbox"
                                />
                                <Label for={"radio" + idx}>{filter.name}</Label>
                              </FormGroup>
                            </div>
                          );
                        })
                      : "NO Travel Agency Found"}
                  </UncontrolledCollapse>
                  <div className="filter-title" id={"filter1"}>
                    Travel Agency
                  </div>
                </div>
              </div> */}

              {/* <div className="filter-col">
                <div className="filter-item border">
                  <UncontrolledCollapse toggler="#filter2">
                    {busAgency && busAgency
                      ? busAgency.map((filter, idx) => {
                          return (
                            <div className="filter-item">
                              <FormGroup check>
                                <Input
                                  name={idx}
                                  id={"radio" + idx}
                                  type="checkbox"
                                />
                                <Label for={"radio" + idx}>{filter.name}</Label>
                              </FormGroup>
                            </div>
                          );
                        })
                      : "NO Bus Agenct at all"}
                  </UncontrolledCollapse>
                  <div className="filter-title" id={"filter2"}>
                    Bus Agency
                  </div>
                </div>
              </div> */}

              {/* <div className="filter-col">
                <div className="filter-item border">
                  <UncontrolledCollapse
                    id="0"
                    toggler={"filter"}
                    toggler={"filter"}
                    // defaultOpen={idx == 0 ? true : false}
                    className=""
                  >
                    {travelAgencyBusType &&
                      travelAgencyBusType.map((filter, idx) => {
                        return (
                          <div className="filter-item">
                            <FormGroup check>
                              <Input
                                name={idx}
                                id={"radio" + idx}
                                type="radio"
                              />
                              <Label for={"radio" + idx}>{filter.type}</Label>
                            </FormGroup>
                          </div>
                        );
                      })}
                  </UncontrolledCollapse>
                  <div className="filter-title" id={"filter"}>
                    Bus Type
                  </div>
                </div>
              </div> */}

              {/* <div className="filter-col">
                <UncontrolledCollapse
                  id={idx}
                  toggler={"filter" + idx}
                  // defaultOpen={idx == 0 ? true : false}
                  className=""
                >
                  {travelAgencyBusType &&
                    travelAgencyBusType.map((filter, idx) => {
                      return (
                        <div className="filter-item">
                          <FormGroup check>
                            <Input name={idx} id={"radio" + idx} type="radio" />
                            <Label for={"radio" + idx}>{filter.type}</Label>
                          </FormGroup>
                        </div>
                      );
                    })}
                </UncontrolledCollapse>
              </div> */}
            </Col>

            <Col xs="12" md="9">
              <div className="routes-col">
                <Row className="thead">
                  <Col xs="6" md="3" lg="3">
                    <div className="th-head">Bus Name</div>
                  </Col>
                  <Col xs="6" md="2" lg="2">
                    <div className="th-head">Bus Type</div>
                  </Col>
                  <Col xs="6" md="2" lg="2">
                    <div className="th-head">Departure Date</div>
                  </Col>
                  <Col xs="6" md="2" lg="2">
                    <div className="th-head">Location</div>
                  </Col>
                  <Col xs="6" md="2" lg="2">
                    <div className="th-head">Available Seats</div>
                  </Col>
                  <Col xs="6" md="1" lg="1">
                    <div className="th-head">Fare</div>
                  </Col>
                </Row>

                <Row className="m-thead g-0">
                  <Col xs="10" md="10" lg="10">
                    <h1>Available Buses</h1>
                  </Col>
                  <Col xs="2" md="2" lg="2">
                    <h2 className="text-center">{totalItemsCount}</h2>
                  </Col>
                </Row>

                <div className="route-lists" style={{ cursor: "pointer" }}>
                  {this.state.availableBuses &&
                    this.state.availableBuses?.length > 0 ? (
                    this.state.availableBuses?.map((ab) => (
                      <Row
                        className="tbody g-2"
                        onClick={() => this.handleRouteClick(ab)}
                      >
                        <Col xs="9" sm="7" md="3" lg="3">
                          <small>Bus Details</small>
                          <strong className="">{ab.bus_name}</strong>
                          <span className="">{ab.number_plate}</span>
                        </Col>

                        <Col xs="3" sm="3" md="2" lg="2">
                          <small>Type</small>
                          <strong className="">{ab.type}</strong>
                        </Col>

                        <Col xs="2" sm="2" md="2" lg="2">
                          <small>Departure</small>
                          <strong className="">
                            {moment(ab.departure_time).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </strong>
                        </Col>
                        <Col xs="2" sm="7" md="2" lg="2">
                          <small>Location</small>
                          <strong className="">
                            {ab.origin} - {ab.destination}
                          </strong>
                        </Col>
                        <Col xs="3" sm="3" md="2" lg="2">
                          <div className="seats">
                            <small>Available Seats</small>
                            <h4>{ab.available_seats}</h4> <div>Seats</div>
                          </div>
                        </Col>
                        <Col xs="3" sm="2" md="1" lg="1">
                          <small>Fare</small>
                          <strong className="">Nrs {ab.fare}</strong>
                        </Col>
                      </Row>
                    ))
                  ) : this.state.searchSpinner ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      No any bus available for the route.
                    </div>
                  )}
                </div>
              </div>
            </Col>

          </Row>
          <Row>
            <Col xs="12" md="12">
              {this.state.totalItemsCount <= 0 ? (
                <></>
              ) : (
                <>
                  <div className="paginationContainer">
                    <Pagination
                      activePage={current_page}
                      itemsCountPerPage={paginated_value}
                      totalItemsCount={totalItemsCount}
                      onChange={(pageNumber) =>
                        this.handleSearchBuses(pageNumber, paginated_value)
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                    <div className="paginatoion_paginated_value ml-3">
                      <select
                        className="form-control"
                        value={this.state.paginated_value}
                        onChange={(e) => this.handlePaginatedValueChange(e.target.value)}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                      </select>
                    </ div>
                  </div>

                </>
              )}
            </Col>
          </Row>
        </Container>

        <Modal
          toggle={this.handleModalClose()}
          // toggle={function noRefCheck() {}}
          size="lg"
          isOpen={this.state.openModal ? true : false}
          centered
        >
          <ModalBody>
            <div className="row m-0 ">
              <div className="col-12 d-flex align-items-center justify-content-center px-4">
                {
                  !this.state.hideTimer ?
                    (
                      <>
                        <CountdownTimer
                          countDownStart={this.state.countDownStart}
                          getTimerValue={this.getTimerValue}
                        />
                      </>) : null
                }
              </div>

              <div className="col-12 d-flex align-items-center">
                <div
                  onClick={() => {
                    this.state.bookingDetailsPdf
                      ? this.handleCompleteBookingCloseModal()
                      : this.closeModalBook();
                  }}
                >
                  <Button className="btn btn-light">
                    <FontAwesomeIcon icon={faTimes} id="exchangeIcon" />
                  </Button>
                </div>

                {!this.props.manualBooking && (
                  <div className="bookSeats-steps flex-grow-1">
                    <div className="steps">
                      {this.state.multiPageFormHeader &&
                        this.state.multiPageFormHeader.map((header, id) => {
                          return (
                            <div
                              className={
                                header.isActive
                                  ? "stepsBox completed"
                                  : "stepsBox"
                              }
                            >
                              <div
                                className="icon"
                                onClick={(e) =>
                                  this.handleMultiPartForm(e, header)
                                }
                              >
                                <h6>{header.name}</h6>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>

              {this.handleStepChange()}

            </div>

            {/* Separate Compoenent for Multi page form -one for each  */}
          </ModalBody>
        </Modal>

        {/* login register check modal */}

        <CustomModal
          modal={this.state.userLoggedInChk}
          modalBody={
            <div className="row m-0 ">
              <div className="col-12 d-flex align-items-center">
                <div>
                  <p>To proceed booking you must login/ register.</p>

                  <Link to="/login">
                    <button className="btn btn-success m-3">Login</button>
                  </Link>

                  <Link to="/signup">
                    <button className="btn btn-info m-3">Register</button>
                  </Link>
                </div>
              </div>
            </div>
          }
          modalHeader="Procced to Booking"
          size="md"
          toggleModal={() => this.handleToggle()}
        ></CustomModal>
      </div>
    );
  }
}

export default WithRouter(RoutesList);
